<template>
  <div class="searchPage">
    <header>
      <InnerContent>
        <h1 class="title">Search Results</h1>
      </InnerContent>
    </header>

    <section class="section">
      <InnerContent>
        <div class="results">
          <div>
            <SearchInput @onSubmit="onSearchSubmit" :hasDropDown="false" />
          </div>
          <ul class="searchList" v-if="receivedResults.length > 0">
            <li v-for="(search, index) in receivedResults" :key="index">
              <a :href="search.route">
                <div class="router">
                  <h2 class="title">{{ search.title }}</h2>
                  <div class="url">
                    https://www.mshcpeducation.com{{ search.link }}
                  </div>
                  <div
                    class="content"
                    v-html="findAndReplace(search.summary)"
                  ></div>
                </div>
              </a>
              <hr />
            </li>
          </ul>
          <div v-else class="no-results">No results found.</div>
        </div>
      </InnerContent>
    </section>
  </div>
</template>

<script>
import SearchInput from "../components/SearchInput.vue";
import dataset from "@/data/search-data.json";
export default {
  name: "searchedResults",
  props: ["searchData"],
  data() {
    return {
      searchText: "",
    };
  },
  computed: {
    receivedResults() {
      let searchedContent = dataset.filter((item) => {
        return String(item.summary)
          .toLocaleLowerCase()
          .includes(String(this.$store.searchedTerm).toLocaleLowerCase());
      });
      return searchedContent;
    },
  },
  methods: {
    onSearchSubmit() {
      this.$router.push("?temp");
      this.$router.push({ name: "searchresults" });
    },
    findAndReplace(data) {
      const _temp = data;
      const regexSearchText = new RegExp(`${this.$store.searchedTerm}`, "gim");
      const matchedSearchText = _temp.match(regexSearchText);

      for (let i = 0; i <= matchedSearchText.length; i++) {
        return _temp.replace(regexSearchText, `<strong>$&</strong>`);
      }
    },
  },
  components: {
    SearchInput: SearchInput,
  },
};
</script>

<style lang="scss">
.router {
  cursor: pointer;
}

.no-results {
  padding: 20px 0;
}

.searchPage {
  background-color: $grey-light;
  z-index: 1;
}
.searchList {
  margin: 0;
  padding: 0;
}

.searchList > li {
  list-style: none;
  margin: 10px auto 30px;

  a {
    text-decoration: none;
  }

  .url {
    text-decoration: underline;
  }
}

.title {
  /* margin: 5px; */
  color: #000;
  font-size: 2.25rem;
  line-height: 3rem;
  /* margin-top: 4.5rem;
  margin-bottom: 2.25rem; */
}

.url {
  padding: 0 0 0.625rem 0;
  font-size: 0.85em;
}

.content {
  /* margin: 5px; */
}
</style>
