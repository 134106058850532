<template>
  <div>
    <InnerContent>
      <header>
        <div class="back-button-row">
          <ButtonReturn :to="backNav" />
        </div>
        <div class="title-page-row">
          <div class="title-page">
            <!-- <h2 class="legal-note">US Medical Affairs</h2> -->
            <slot name="breadcrumb"></slot>
            <h1><slot name="header">Tactic title</slot></h1>
          </div>
          <p class="tactic-copy">
            <slot name="video-copy">Video copy</slot>
          </p>
        </div>
      </header>

      <div
        class="video-container"
        oncontextmenu="return false"
        onselectstart="return false"
        ondragstart="return false"
      >
        <slot name="video-content">
          <p>Video content.</p>
        </slot>
      </div>

      <div class="pdf-container">
        <slot name="pdf-content"></slot>
      </div>

      <footer>
        <p class="launch-text">
          <img
            src="@/assets/img/icon-browser-launch.svg"
            alt=""
            class="launch-icon"
          />
          &nbsp; The experience will open in a new browser tab.
        </p>
        <p class="acr">MS, multiple sclerosis.</p>
      </footer>
    </InnerContent>
  </div>
</template>

<script>
import ButtonReturn from "@/components/ButtonReturn.vue";

export default {
  name: "TacticPage",
  components: {
    ButtonReturn,
  },
  props: {
    customContent: {
      type: Boolean,
      default: false,
    },
    backNav: {
      type: String,
      default: "home",
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
header {
  margin: 60px 0 16px 0;
}

h1 {
  font-size: rem(40px);
  margin: 0 0 rem(20px) 0;
  line-height: 1;
}

.subheader {
  max-width: 783px;
  color: $white;
  margin: 0;
  margin-bottom: rem(45px);
  line-height: 1.3;
}

.title-page-row {
  width: 100%;
}

.back-button-row {
  width: 100%;
  display: inline-block;
}

:deep(.video-container) {
  video {
    width: 100%;
  }
}

:deep(.tactic-copy) {
  font-size: rem(21px);
  line-height: rem(26px);
}

:deep(.preview-img) {
  width: 100%;
  margin-bottom: rem(25px);

  img {
    width: 100%;
  }
}

.pdf-container {
  background: $blue-dark-card3;
  padding: rem(25px);
  margin-top: rem(10px);
  margin-bottom: rem(35px);
  width: 100%;
  display: flex;
  align-items: center;
}

:deep(.pdf-preview-container) {
  max-width: rem(200px);
  margin-right: rem(25px);

  img {
    width: 100%;
  }
}

:deep(.pdf-buttons-container) {
  width: 33%;
}

@media (min-width: 768px) {
  .breakpoint-landscape {
    display: block;
  }

  .breakpoint-portrait {
    display: none;
  }
}

@media (max-width: 767px) {
  .breakpoint-landscape {
    display: none;
  }

  .breakpoint-portrait {
    display: block;
  }

  .content-container {
    display: block;
  }

  :deep(.preview-img) {
    margin-bottom: rem(25px);
  }

  :deep(.pdf-container) {
    display: block;
  }

  :deep(.pdf-preview-container) {
    margin-bottom: rem(20px);
  }

  :deep(.pdf-buttons-container) {
    width: 75%;
  }

  h1 {
    font-size: rem(30px);
    margin: 0 0 rem(20px) 0;
    line-height: 1;
  }

  :deep(.tactic-copy) {
    font-size: rem(15px);
    line-height: rem(18px);
  }
}

@media (max-width: 500px) {
  :deep(.pdf-buttons-container) {
    width: 100%;
  }
}
</style>
