<template>
  <div id="moa-theater">
    <div class="inner-wrapper mesh-bg">
      <TacticPage :customContent="true">
        <template #back-button>
          <ButtonReturn to="home" colour="black" />
        </template>
        <template #header>{{ tactic.title }}</template>

        <template #custom-content>
          <p class="tactic-copy tactic-cmsc-copy" v-html="tactic.desc"></p>

          <div class="video-cards-container">
            <!-- TO-DO: Make this a component -->
            <div class="video-card" v-for="video of videos" :key="video.title">
              <div class="video-card-upper">
                <h3 class="video-title" v-html="video.title"></h3>
                <p class="video-desc" v-html="video.desc"></p>
              </div>
              <div class="preview-img" @click="logVideo(video.log)">
                <router-link :to="{ name: `${video.route}` }">
                  <img :src="require(`@/${video.image.src}`)" alt="" />
                </router-link>
              </div>
              <div class="video-card-lower" @click="logVideo(video.log)">
                <router-link
                  :to="{ name: `${video.route}` }"
                  tag="button"
                  class="tactic-btn"
                >
                  <span
                    class="tactic-btn-label"
                    v-html="video.button.label"
                  ></span>
                  <span class="tactic-btn-icon">
                    <img src="@/assets/img/ButtonArrow.svg" />
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </template>

        <template #portrait-content>
          <p class="tactic-copy tactic-cmsc-copy" v-html="tactic.desc"></p>

          <div class="video-cards-container">
            <!-- TO-DO: Make this a component -->
            <div class="video-card" v-for="video of videos" :key="video.title">
              <div class="video-card-upper">
                <h3 class="video-title" v-html="video.title"></h3>
                <p class="video-desc" v-html="video.desc"></p>
              </div>
              <div class="preview-img" @click="logVideo(video.log)">
                <router-link :to="{ name: `${video.route}` }">
                  <img :src="require(`@/${video.image.src}`)" alt="" />
                </router-link>
              </div>
              <div class="video-card-lower" @click="logVideo(video.log)">
                <router-link
                  :to="{ name: `${video.route}` }"
                  tag="button"
                  class="tactic-btn"
                >
                  <span
                    class="tactic-btn-label"
                    v-html="video.button.label"
                  ></span>
                  <span class="tactic-btn-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11.89"
                      height="20.433"
                      viewBox="0 0 11.89 20.433"
                    >
                      <path
                        id="Path_3642"
                        data-name="Path 3642"
                        d="M0,0,9.455,9.757,19,0"
                        transform="translate(0.699 19.715) rotate(-90)"
                        fill="none"
                        stroke="#ffffff"
                        stroke-width="2"
                      />
                    </svg>
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </template>

        <!-- <template #footer-content>
            <div class="footnote-container">
              <p class="footnotes">
                *Experts have been compensated for their time by Novartis Pharmaceuticals Corporation
              </p>
            </div>
          </template> -->
      </TacticPage>
    </div>
  </div>
</template>

<script>
import TacticPage from "@/components/TacticPage";

export default {
  name: "MoaTheater",
  components: {
    TacticPage,
  },
  data() {
    return {
      tactic: {
        title: "Multiple Sclerosis Mechanism of Action Theater",
        desc: "Explore the mechanism of action (MOA) in multiple sclerosis. These animations are designed to help educate health care professionals on the mechanism of action in MS to better understand treatment strategies for patient care.",
      },
      videos: [
        {
          title: "Ofatumumab MOA",
          desc: "In this animation, learn how ofatumumab causes CD20+ B cells to be vulnerable to B cell–killing mechanisms such as complement-dependent or antibody-dependent cytotoxicity.",
          image: {
            src: "assets/img/tactic-fpo.png",
            alt: "",
          },
          button: {
            label: "Play Video",
            ariaDescribedBy: "",
          },
          route: "moa-video1",
          log: "Ofatumumab MOA",
        },
        // {
        //   title: "Fingolimod MOA",
        //   desc: "In this animation, learn how this sphingosine 1&#8209;phosphate (S1P) modulator down regulates S1P receptor type 1 to retain autoaggressive lymphocytes which can promote neuroinflammation in MS.",
        //   image: {
        //     src: "assets/img/tactic-fpo.png",
        //     alt: ""
        //   },
        //   button: {
        //     label: "Play Video",
        //     ariaDescribedBy: ""
        //   },
        //   route: "moa-video2",
        //   log: 'Fingolimod MOA'
        // },
        {
          title: "Siponimod MOA",
          desc: "In this animation, learn how this S1P receptor modulator binds to S1P receptors, blocking the release of lymphocytes into the blood and T cell infiltration into the CNS.",
          image: {
            src: "assets/img/tactic-fpo.png",
            alt: "",
          },
          button: {
            label: "Play Video",
            ariaDescribedBy: "",
          },
          route: "moa-video3",
          log: "Siponimod MOA",
        },
      ],
    };
  },
  methods: {
    logVideo(video) {
      this.$gtag.event("click", {
        event_category: "launch_video",
        event_label: video,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#moa-theater {
  // color: $white;
  // background: rgb(59,103,159);
  // background: linear-gradient(160deg, rgba(59,103,159,1) 0%, rgba(61,67,104,1) 64%, rgba(116,57,72,1) 100%);
  // position: relative;
}

.mesh-bg {
  background: linear-gradient(180deg, $grey 0%, $white 25%);
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-image: url("../assets/img/bg-header-cropped.svg");
    background-size: contain;
    background-position: 100% 0;
    background-repeat: no-repeat;
    z-index: 0;
    pointer-events: none;
    opacity: 0.25;
    -moz-opacity: 0.25;
    -webkit-opacity: 0.25;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background-image: url("../assets/img/bg-footer-cropped.svg");
    background-size: contain;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    z-index: 0;
    pointer-events: none;
    opacity: 0.25;
    -moz-opacity: 0.25;
    -webkit-opacity: 0.25;
  }
}

.footnote-container {
  margin-top: rem(15px);
  margin-bottom: 5em;

  @media screen and (min-width: 800px) {
    margin-bottom: 10em;
  }

  @media screen and (min-width: 1300px) {
    margin-bottom: 13em;
  }
}

.footnotes {
  font-size: rem(12px);
}

::v-deep .video-container,
:deep(.video-cards-container) {
  margin-bottom: rem(0px);
}

.tactic-cmsc-copy {
  width: 70%;
  margin-bottom: rem(25px);
}

.tactic-btn {
  border: 1px solid $blue;
  background: $blue;
  color: $white;
  padding: rem(10px);
  text-align: left;
  position: relative;
  width: 100%;
  min-height: rem(60px);
}

.tactic-btn-label {
  float: left;
  font-size: rem(18px);
  font-weight: $font-weight-bold;
  width: calc(100% - 50px);
  position: absolute;
  top: 50%;
  left: rem(10px);
  transform: translate(0%, -50%);
}

.tactic-btn-icon {
  float: left;
  width: rem(20px);
  margin-left: rem(30px);
  position: absolute;
  top: 50%;
  right: rem(10px);
  transform: translate(0%, -50%);

  img {
    width: 100%;
  }
  &.launch-icon {
    width: rem(27px);
  }
}

.preview-img {
  position: relative;
  a {
    display: block;
  }
  .play-btn {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 50px;
    top: 50%;
    left: 50%;
  }
}

.video-cards-container {
  display: flex;
  justify-content: space-between;
}

.video-card {
  width: 50%;
  border: 1px solid $blue;
  margin-right: rem(14px);
  padding: rem(20px);
  background: $white;

  &.lifitegrast {
    border: 1px solid $orange;

    .tactic-btn {
      border: 1px solid $orange;
      background: $orange;
    }
  }

  .video-card-upper {
    padding-bottom: rem(0px);
    height: rem(145px); //temp
  }
  .video-card-lower {
    padding-top: rem(0px);
  }

  .video-title,
  .video-desc {
    margin-bottom: rem(15px);
  }

  .video-desc {
    font-size: rem(14px);
    line-height: rem(22px);
  }

  .video-title {
    width: 75%;
    line-height: 1.2;
    font-size: rem(18px);
    line-height: rem(20px);
  }
}

@media screen and (min-width: 1681px) {
  .video-card {
    .video-title {
      width: 55%;
    }
  }
}

@media (max-width: 767px) {
  .video-cards-container {
    display: block;
  }

  .video-card {
    width: 100%;
    margin-bottom: rem(50px);
    .video-desc {
      height: fit-content;
    }
  }
}

@media (max-width: 500px) {
  .tactic-btn-label {
    font-size: rem(14px);
  }
}
</style>
