<template>
  <div class="pdf-page">
    <div class="inner-wrapper">
      <InnerContent>
        <header>
          <div class="title-page-row">
            <div class="title-page">
              <h1></h1>
            </div>
          </div>
          <div class="back-button-row">
            <slot name="back-button">
              <ButtonReturn :to="backNav" colour="black" />
            </slot>
          </div>
        </header>
        <h1>Diagnostic Criteria</h1>
      </InnerContent>
    </div>
    <div class="iframe-container">
      <div class="inner-wrapper">
        <InnerContent>
          <iframe class="ipdf-container" src="/diagnostic-criteria/"></iframe>
        </InnerContent>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonReturn from "@/components/ButtonReturn.vue";

export default {
  name: "DiagnosingMs",
  inject: ["highlightTabSelected"],
  components: {
    ButtonReturn,
  },
  props: {
    backNav: {
      type: String,
      default: null,
    },
  },
  methods: {},
  mounted() {
    this.highlightTabSelected("tab_020");
  },
};
</script>

<style lang="scss" scoped>
header {
  margin: 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pdf-page {
  color: $black;
  background-color: $grey-light;
  position: relative;

  &:deep(.inner-content) {
    margin-bottom: 0;
  }
}

.download-button {
  width: 300px;
}
.download-btn {
  border: 1px solid $black;
  background: #fff url("~@/assets/img/icon-download-black.svg") no-repeat 95%
    50%;
  color: $black;
  padding: rem(10px);
  padding-right: 50px;
  text-align: left;
  font-weight: $font-weight-bold;
  position: relative;
  width: 300px;
  height: 68px;
  text-decoration: none;
  display: flex;
  align-items: center;

  @include breakpoint(xs) {
    min-height: 52px;
    height: auto;
    margin-bottom: 10px;
  }
}

.iframe-container {
  &:deep(.inner-content) {
    margin-bottom: rem(25px);

    .ipdf-container {
      width: 100%;
      height: 756px;
      border: 0;

      @include breakpoint(xs) {
        aspect-ratio: 4/3;
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
