<template>
  <div class="inner-content">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'InnerContent',
};
</script>

<style lang="scss" scoped>
  .inner-content {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    padding: rem(25px);
    position: relative;
    z-index: 1;

    // Commenting this out as honestly the screen just gets too massive.
    // @media (min-width: 1680px) {
    //   max-width: 1440px;
    // }
  }
</style>
