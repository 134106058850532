import Vue from "vue";
import VueRouter from "vue-router";
import { routes } from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  // console.log('from>>>', from)
  // console.log('to>>>', to)
  next();
  // if (to.name !== 'welcome' && router.app.$cookies.get('isHcp') !== 'true') {
  //   next({name: 'welcome'});
  // } else {
  //   next();
  // }
});

router.afterEach((to) => {
  switch (to.path) {
    //DIAGNOSING MS
    case "/diagnosis":
      updateMetaDescription(
        "Diagnosis and Symptoms of MS – Novartis",
        "Signs and symptoms of multiple sclerosis (MS): For better diagnosis of relapsing-remitting MS, clinically isolated syndrome, and secondary progressive MS."
      );
      break;
    //MECHANISM OF DISEASE
    case "/mechanism-of-disease":
      updateMetaDescription(
        "Mechanism of Disease of MS – Novartis ",
        "Effects on the brain in relapsing-remitting multiple sclerosis (RRMS): From early neuroinflammation to progressive neurodegeneration of multiple sclerosis (MS)."
      );
      break;
    //MANAGING MS
    case "/management":
      updateMetaDescription(
        "Management of MS – Novartis",
        "Management for multiple sclerosis (MS): Prognosis, current treatments, therapeutic targets, initiating medication, family planning, patient-centered care."
      );
      break;
    //DISEASE PROGRESSION
    case "/progression":
      updateMetaDescription(
        "MS Progression – Novartis ",
        "Stages of multiple sclerosis (MS) disease progression (clinically isolated syndrome, relapsing-remitting MS, secondary progressive MS) and the patient’s view."
      );
      break;
    //PATIENT-CENTERED CARE
    case "/patient-centered-care":
      updateMetaDescription(
        "MS Patient-Centered Care",
        "Lorem ipsum PATIENT-CENTERED CARE"
      );
      break;
    case "/resources":
      updateMetaDescription(
        "MS Resources – Novartis",
        "Videos, PDFs, applications, and audio resources about multiple sclerosis (MS) for optimal care and support of patients by health care professionals."
      );
      break;
    //GLOSSARY
    case "/glossary":
      updateMetaDescription("MS Disease Information Glossary of Terms - Novartis", "Find definitions for common terms used throughout our website.");
      break;
    //MANAGING MS
    case "/sitemap":
      updateMetaDescription("MS Disease Information Site Map - Novartis", "See an overview all of the pages of the website.");
      break;
    case "/mechanism-of-disease/video":
      updateMetaDescription(
        "Mechanism of Disease in MS - Novartis",
        "Effects on the brain in relapsing-remitting multiple sclerosis (RRMS): Explore how B cells and T cells trigger the onset of neuroinflammation, which may lead to irreversible neurodegeneration and disease progression. Mechanism, neuroinflammation, neurodegeneration"
      );
      break;
    case "/mechanism-of-disease/b-t-cell-pathophysiology/video":
      updateMetaDescription(
        "B- and T-Cell Pathophysiology in MS - Novartis",
        "Effects on the brain in relapsing-remitting multiple sclerosis (RRMS): Dr. Elisabeth Lucassen discuss the roles of T cells and B cells in MS pathophysiology and pathways leading to CNS damage. MS B cells, MS T cells, disease, mechanism"
      );
      break;
    case "/mechanism-of-disease/symptoms-and-pathogenic-immune-system/pdf":
      updateMetaDescription(
        "Subtle MS Symptoms and the Pathogenic Immune System - Novartis",
        "Effects on the brain in relapsing-remitting multiple sclerosis (RRMS): Explore signs and symptoms related to the pathogenic immune system in multiple sclerosis (MS). RRMS, sclerosis, MS T cells, MS B cells"
      );
      break;
    case "/mechanism-of-disease/ms-early-pathophysiology/pdf":
      updateMetaDescription(
        "Early MS Pathophysiology - Novartis",
        "Effects on the brain in relapsing-remitting multiple sclerosis (RRMS): Learn about the onset of disease, T- and B- cell activity, and inflammation and demyelination. Inflammation MS, multiple sclerosis, MS T cells, MS B cells"
      );
      break;
    case "/mechanism-of-disease/neuroinflammation-neurodegeneration/video":
      updateMetaDescription(
        "From Neuroinflammation to Neurodegeneration in MS - Novartis",
        "Effects on the brain in relapsing-remitting multiple sclerosis (RRMS): Learn about the inflammatory cascade of autoreactive B cells and T cells in MS. RRMS, neuroinflammation, neurodegeneration, MS B cells, MS T cells"
      );
      break;
    case "/mechanism-of-disease/neuroinflammation-neurodegeneration/pdf":
      updateMetaDescription(
        "Neuroinflammation and Neurodegeneration in MS - Novartis",
        "Effects on the brain in relapsing-remitting multiple sclerosis (RRMS): Learn about early disease activity (neuroinflammation), disease progression (neurodegeneration), and the clinical expression of neuropathology. RRMS, neuroinflammation, neurodegeneration"
      );
      break;
    case "/mechanism-of-disease/immunoglobulins-and-ms/video":
      updateMetaDescription(
        "Immunoglobulins and MS - Novartis",
        "Effects on the brain in relapsing-remitting multiple sclerosis (RRMS): See the function of immunoglobulins in healthy people and in patients with MS. Immunoglobulins MS, RRMS, patients"
      );
      break;
    case "/mechanism-of-disease/neuroimmunology-considerations-in-ms/video":
      updateMetaDescription(
        "Neuroimmunology Considerations in MS - Novartis",
        "Effects on the brain in relapsing-remitting multiple sclerosis (RRMS): Learn about B- and T-cell activity, the impact of immunoglobulins, and treatment considerations, strategies, and timing. MS T cells, MS B cells, RRMS, activity"
      );
      break;
    case "/mechanism-of-disease/role-of-Immunoglobulins/pdf":
      updateMetaDescription(
        "The Role of Immunoglobulins in MS - Novartis",
        "Effects on the brain in relapsing-remitting multiple sclerosis (RRMS): Learn about the role of immunoglobulins in the immune system, the impact of low immunoglobulin levels, immunoglobulin testing, and DMTs. Immunoglobulin MS, patients, RRMS"
      );
      break;
    case "/diagnosis/subtle-symptoms-and-disease-progression/pdf":
      updateMetaDescription(
        "MS Symptoms and Disease Progression - Novartis",
        "Diagnosis and symptoms of multiple sclerosis (MS): Learn about relapses and remissions, silent disease progression, clinical heterogeneity of symptoms, and how to predict disease progression. Disease progression, early silent progression, heterogeneity of symptoms"
      );
      break;
    case "/diagnosis/diagnostic-challenges/video":
      updateMetaDescription(
        "Challenges in MS Diagnosis - Novartis",
        "Diagnosis and symptoms of multiple sclerosis (MS): Learn about early signs and symptoms that may be connected to a diagnosis of MS. MS symptoms, early signs of MS, diagnosis"
      );
    break;
    case "/diagnosis/diagnostic-challenges/pdf":
      updateMetaDescription(
        "Diagnostic Challenges of MS - Novartis",
        "Diagnosis and symptoms of multiple sclerosis (MS): Learn about the potential impact of delaying MS diagnosis, symptoms associated with diagnostic delays, and indexes and criteria for MS diagnosis. Early diagnosis and treatment, early neurodegeneration, MS symptoms checklist"
      );
      break;
    case "/diagnosis/diagnostic-criteria":
      updateMetaDescription(
        "MS Diagnosis Algorithm - Novartis",
        "Diagnosis and symptoms of multiple sclerosis (MS): Step-by-step algorithm on diagnosing multiple sclerosis. Diagnosing MS, RRMS, PPMS"
      );
      break;
    case "/management/anti-cd20-monoclonal-antibodies/video":
      updateMetaDescription(
        "Management of MS: Anti-CD20 Monoclonal Antibodies in MS - Novartis",
        "Management of multiple sclerosis (MS): Explore how anti-CD20 monoclonal antibodies can target autoreactive B-cell activity in multiple sclerosis treatment. MS treatment, MS management, patient"
      );
      break;
    case "/management/evolving-therapeutic-targets/pdf":
      updateMetaDescription(
        "Management of MS: Evolving Therapeutic Targets - Novartis",
        "Management of multiple sclerosis (MS): Learn about evolving therapeutic targets including history, current treatment targets, emerging targets and  the future of potential MS therapy. MS treatment, MS management, patient"
      );
      break;
    case "/management/disease-modifying-treatments/pdf":
      updateMetaDescription(
        "Management of MS: Disease-modifying Treatment Strategies - Novartis",
        "Management of multiple sclerosis (MS): Learn about disease-modifying treatment strategies including initiation of therapy, treatment strategies in MS, escalation therapy, induction strategy, and switching DMTs. MS treatment, MS management, patient"
      );
      break;
    case "/managing-ms/podcast":
      updateMetaDescription(
        "The Evolving Landscape of Managing MS - Novartis",
        "Management of multiple sclerosis (MS): Dr Jason Freeman, neurologist (Medical Director, Novartis), Dr Gina Cox, neuroimmunologist (Senior Medical Director, Novartis), and Dr Brandon Brown, clinical pharmacist (Medical Director, Novartis), discuss phenotypic classifications of MS and different treatment strategies for patients across the spectrum"
      );
      break;
    case "/patient-centered-care/empowering-patients/video":
      updateMetaDescription(
        "Management of MS: Empowering Patients - Novartis",
        "Management of multiple sclerosis (MS): Learn about empowering patients living with multiple sclerosis. MS treatment, MS management, patient"
      );
      break;
    case "/patient-centered-care/holistic-treatment/pdf":
      updateMetaDescription(
        "Management of MS: Nonpharmacological Patient Care - Novartis",
        "Management of multiple sclerosis (MS): Learn about nonpharmacological patient care in multiple sclerosis including patient unmet needs, defining holistic patient care, nonpharmacological symptom management, and facilitating positive communication. MS treatment, MS management, patient"
      );
      break;
    case "/patient-centered-care/empowering-patients/pdf":
      updateMetaDescription(
        "Management of MS: Empowering Patients - Novartis",
        "Management of multiple sclerosis (MS): Learn about empowering patients with multiple sclerosis including patient unmet needs, stress in MS, patient engagement and clinical outcomes, and strategies for empowerment. MS treatment, MS management, patient"
      );
      break;
    case "/management/family-planning/pdf":
      updateMetaDescription(
        "Management of MS: Family Planning in MS - Novartis",
        "Management of multiple sclerosis (MS): Learn about family planning in multiple sclerosis including prenatal planning, impact of MS on pregnancy outcomes, understanding risk of relapse, and treatment planning. MS treatment, MS management, patient"
      );
      break;
    case "/management/pregnancy-management/pdf":
      updateMetaDescription(
        "Management of MS: Pregnancy - Novartis",
        "Management of multiple sclerosis (MS): Learn about pregnancy management in MS, including disease activity, perinatal considerations, and potential complications. MS treatment, MS management, patient"
      );
      break;
    case "/neurologists-perspective/podcast":
      updateMetaDescription(
        "Empowering Patients With MS - Novartis",
        "Management of multiple sclerosis (MS): Dr Jason Freeman, neurologist (Medical Director, Novartis), and Dr Yousef Ali (Senior Medical Director, Novartis) discuss the benefits of patient engagement and empowerment in the management of patients with MS. MS treatment, MS management, patient"
      );
      break;
    case "/maximizing-adherence/podcast":
      updateMetaDescription(
        "Maximizing Adherence to Treatment Plans in Patients With MS - Novartis",
        "Management of multiple sclerosis (MS): Dr Jason Freeman, neurologist (Medical Director, Novartis), and Dr Stephen Yeung, MS specialist (Medical Director, Novartis) discuss the potential barriers to treatment adherence and the benefits of shared decision-making. MS treatment, MS medication, progression"
      );
      break;
    case "/patient-centered-care/diversity-clinical-trials/video":
      updateMetaDescription(
        "Diversity in MS Clinical Trials - Novartis",
        "Management of multiple sclerosis (MS): Learn from MS patients and their loved ones about the importance of diversity in clinical trials and their hopes for the future of MS research. MS treatment, MS management, patient"
      );
      break;
    case "/silent-progression/podcast":
      updateMetaDescription(
        "Assessing Silent Progression in MS - Novartis",
        "Management of multiple sclerosis (MS): Dr Jason Freeman, neurologist (Medical Director, Novartis), and Dr Stephen Yeung, MS specialist (Medical Director, Novartis) discuss the clinical implications of assessing silent progression in MS. Progression, MS treatment, MS management"
      );
      break;
    case "/progression/cognitive-functioning/video":
      updateMetaDescription(
        "MS Progression and Cognitive Functioning - Novartis",
        "Learn how MS progression is associated with significant cognitive decline and ways to preserve neurologic function to delay this decline."
      );
      break;
    case "/progression/cognitive-impairment/pdf":
      updateMetaDescription(
        "MS Progression and Cognitive Impairment – Novartis",
        "Stages of multiple sclerosis (MS) disease progression: Learn about cognitive impairment in MS patients, including early cognitive impairment, prevalence of neurocognitive symptoms, impact on functioning and quality of life, and preserving cognitive function. "
      );
      break;
    case "/progression/patient-views-on-disease-progression/pdf":
      updateMetaDescription(
        "Patient Views on MS Progression – Novartis",
        "Stages of multiple sclerosis (MS) disease progression: Learn about patient views on disease progression, including patient perspectives on diagnosis, patient priorities in disease management, overcoming treatment barriers, and optimizing patient communication.  "
      );
      break;
    case "/additional-resources":
      updateMetaDescription(
        "MS Disease Information Resource Library - Novartis",
        "Multiple sclerosis (MS) resources: Resource Library encompasses all videos, PDFs, interactive applications, and audio recordings for health care professionals interested in learning more about MS, including a comprehensive Glossary of Terms."
      );
      break;
    case "/switching-dmts/podcast":
      updateMetaDescription(
        "Switching DMTs in MS - Novartis",
        "Management of multiple sclerosis (MS): Dr Jason Freeman, neurologist (Medical Director, Novartis), and Dr James Stankiewicz (Executive Medical Director, Novartis) discuss some reasons to consider switching DMTs and some of the risks and benefits associated with switching treatments. MS treatment, MS management, can MS be cured"
      );
      break;
    case "/pregnancy-management/podcast":
      updateMetaDescription(
        "Pregnancy Management in MS - Novartis",
        "Management of multiple sclerosis (MS): Dr Jason Freeman, neurologist (Medical Director, Novartis), and Dr Ali Chappell (Medical Science Liaison, Novartis) share valuable insights on how pregnancy can be best managed from the perspectives of both, patients living with MS as well as healthcare providers."
      );
      break;
    case "/patient-adherence/podcast":
      updateMetaDescription(
        "Choice of Therapy and Patient Adherence - Novartis",
        "Management of multiple sclerosis (MS): Dr Jason Freeman, neurologist (Medical Director, Novartis), along with Dr Brandon Brown (Senior Medical Director, Novartis) dig a little bit deeper into treatment-related factors that may impact patient adherence."
      );
      break;
    case "/disease-continuum/podcast":
      updateMetaDescription(
        "MS, A Disease Continuum - Novartis",
        "Management of multiple sclerosis (MS): Dr Jason Freeman, neurologist, and Dr Stephen Yeung, both Medical Directors at Novartis, delve here into why MS might be better described as a continuum of symptoms instead of classified into discrete subtypes."
      );
      break;
    default:
      updateMetaDescription(
        "Multiple Sclerosis (MS) Disease Information - Novartis",
        "Multiple sclerosis (MS) disease information: Mechanism of disease, diagnosis, management, and disease progression resources for health care professionals."
      );
  }

  // Explicitly call pageview tracking
  router.app.$gtag.pageview({
    page_path: `${to.path}`,
  });
});
function updateMetaDescription(title, metaContent) {
  document.title = title;
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", metaContent);
}
export default router;
