export const menuItems = Object.freeze({
  home: {
    main: {
      ariaDescribedBy: ' ',
      title: 'Home',
      urlDev: '/',
      urlProd: '/',
    },
    hcppatientconnections: {
      ariaDescribedBy: ' ',
      title: 'Management',
      urlDev: '/management',
      urlProd: '/management',
    },
    neuropathology: {
      ariaDescribedBy: ' ',
      title: 'Mechanism of Disease',
      urlDev: '/mechanism-of-disease',
      urlProd: '/mechanism-of-disease',
    },
    recognizing: {
      ariaDescribedBy: ' ',
      title: 'Diagnosis',
      urlDev: '/diagnosis',
      urlProd: '/diagnosis',
    },
    exploring: {
      ariaDescribedBy: ' ',
      title: 'MS Progression',
      urlDev: '/progression',
      urlProd: '/progression',
    },
    // progression: {
    //   ariaDescribedBy: ' ',
    //   title: 'Disease Progression',
    //   urlDev:
    //     '/#/?card=progression',
    //   urlProd: '/#/?card=progression',
    // },
    care: {
      ariaDescribedBy: ' ',
      title: 'Patient-Centered Care',
      urlDev: '/patient-centered-care',
      urlProd: '/patient-centered-care',
    },
    covidResources: {
      ariaDescribedBy: ' ',
      title: 'COVID-19 resources',
      urlDev: '/resources',
      urlProd: '/resources',
    },
    resources: {
      ariaDescribedBy: ' ',
      title: 'Resources',
      urlDev: '/resources',
      urlProd: '/resources',
    },
    glossary: {
      ariaDescribedBy: ' ',
      title: 'Glossary of Terms',
      urlDev: '/glossary',
      urlProd: '/glossary',
    },
    sitemap: {
      ariaDescribedBy: ' ',
      title: 'Site Map',
      urlDev: '/sitemap',
      urlProd: '/sitemap',
    },
  },
});
