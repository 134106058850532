<template>
  <div :id="`ms-mod-video--${id}`" class="ms-mod-video mesh">
    <div class="inner-wrapper">
      <VideoPage>
        <template #header>{{ video.title }}</template>
        <template #video-copy>{{ video.desc }}</template>
        <template #video-content>
          <VimeoVideoEmbed :videoData="video" />
          <!-- <KalturaVideo :videoData="video.kalturaData" /> -->
        </template>
        <template #related-content>
          <h2>You may also be interested in:</h2>
          <ul>
            <li>
              <button class="pdf-button" @click="showPdf(pdfs.neuro[0])">
                <img
                  class="thumbnail"
                  src="@/assets/img/thumbnail-pdf-neuro.png"
                  alt=""
                />
                <img src="@/assets/img/icon-pdf-black.svg" alt="PDF" />Insights Into MS:
                From Neuroinflammation to Neurodegeneration
              </button>
            </li>
            <li>
              <button class="pdf-button" @click="showPdf(pdfs.neuro[1])">
                <img
                  class="thumbnail"
                  src="@/assets/img/thumbnail-insights-into-ms.png"
                  alt=""
                />
                <img src="@/assets/img/icon-pdf-black.svg" alt="PDF" />Insights Into MS:
                B- and T-Cell Pathophysiology
              </button>
            </li>
            <li>
              <button class="pdf-button" @click="showPdf(pdfs.neuro[2])">
                <img
                  class="thumbnail"
                  src="@/assets/img/thumbnail-insights-into-ms.png"
                  alt=""
                />
                <img src="@/assets/img/icon-pdf-black.svg" alt="PDF" />Insights Into MS:
                Evolving Therapeutic Targets
              </button>
            </li>
          </ul>
        </template>
      </VideoPage>
    </div>
  </div>
</template>

<script>
// import KalturaVideo from "@/components/KalturaVideo";
import VideoPage from "@/components/VideoPage";
import VimeoVideoEmbed from "@/components/VimeoVideoEmbed";

export default {
  name: "MsPathoVideo",
  components: {
    // KalturaVideo,
    VideoPage,
    VimeoVideoEmbed,
  },
  data() {
    return {
      id: this.$router.currentRoute.params.id,
      video: {
        title: "B- and T-Cell Pathophysiology in MS",
        desc: "In this video, Dr Elisabeth Lucassen discusses the roles of T cells and B cells in MS pathophysiology and pathways leading to CNS damage.",
        vimeoId: "633209445",
        eventLabel: "The Pathophysiology of MS",
        kalturaData: {
          eventLabel: "The Pathophysiology of MS",
          id: 1603811077,
          uiConfId: "34239831",
          entryId: "1_dl4jho9c",
        },
      },
      pdfs: {
        neuro: [
          {
            src: "neuroinflammation.pdf",
            analyticsLabel:
              "Video: From Neuroinflammation to Neurodegeneration",
          },
          {
            src: "early-pathophysiology.pdf",
            analyticsLabel: "Video: Insights Into MS: Early Pathophysiology",
          },
          {
            src: "current-therapeutic-targets.pdf",
            analyticsLabel: "Video: Current research therapeutic targets",
          },
        ],
      },
    };
  },
  methods: {
    showApp: function (app) {
      this.$gtag.event("click", {
        event_category: "launch_page",
        event_label: app.analyticsLabel,
      });

      let url =
        process.env.NODE_ENV === "development" ? app.urlDev : app.urlProd;

      window.open(url, "_self");
    },
    showPdf: function (data) {
      this.$router.push({ name: data.src.split(".")[0] });

      this.$gtag.event("click", {
        event_category: "view_pdf",
        event_label: `${data.analyticsLabel}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ms-mod-video {
  color: $black;
  background: rgb(255, 255, 255);
  position: relative;
}
.mesh {
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    pointer-events: none;
    background-image: url("~@/assets/img/video-bg.png");
    top: 0;
    right: 0;
  }
}
.tactic-btn {
  border: 1px solid $black;
  background: none;
  color: $black;
  padding: rem(10px) rem(25px);
  text-align: left;
  position: relative;
  width: 100%;
  min-height: rem(60px);
  display: block;
  margin-bottom: rem(20px);

  &:last-child {
    margin-bottom: 0px;
  }
}

.tactic-btn-label {
  float: left;
  font-size: rem(18px);
  font-weight: $font-weight-bold;
  width: calc(100% - 50px);
  position: absolute;
  top: 50%;
  left: rem(20px);
  transform: translate(0%, -50%);
}

.tactic-btn-icon {
  float: left;
  width: rem(20px);
  margin-left: rem(30px);
  position: absolute;
  top: 50%;
  right: rem(20px);
  transform: translate(0%, -50%);

  img {
    width: 100%;
  }
  &.launch-icon {
    width: rem(27px);
  }
}

.link {
  border: none;
  background: none;
  color: $black;
  display: flex;
  align-items: center;
  text-align: left;
  padding-left: 0;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
</style>
