<template>
  <div :id="`${id}`" class="podcast-page mesh">
    <div class="inner-wrapper">
      <PodcastPage>
        <template #header>{{ audio.title }}</template>
        <template #podcast-asset><img src="~@/assets/img/switching-disease.png" alt="Sound waves behind a doctor holding a notepad" /></template>
        <template #podcast-copy>{{ audio.desc }}</template>
        <template #podcast-content>
          <AudioPlayer :data="audio" :key="audio.id" />
        </template>

        <template #transcript>          
          <p><strong>Jason Freeman</strong></p>
          <p>This podcast is sponsored by Novartis Pharmaceuticals Corporation. The content provided herein is for your background and educational purposes only. The material is for your sole use and may not be altered or further disseminated in any fashion for further use.</p>
          <p>Thanks for joining, everyone. My name is Dr Jason Freeman; I&rsquo;m a neurologist and Medical Director with Novartis Pharmaceuticals. And for today&rsquo;s topic, I&rsquo;m going to welcome our guest Dr James Stankiewicz, who is an Executive Medical Director at Novartis Pharmaceuticals.</p>
          <p>In today&rsquo;s episode, we&rsquo;ll be talking about switching disease-modifying therapies, or DMTs, in patients with multiple sclerosis, or MS. MS is a chronic inflammatory immune disorder that affects the central nervous system.<sup>1</sup> Currently, there are more than a dozen approved therapies for treating MS, approved for different subtypes based on relapse activity, which we have discussed in other podcasts.<sup>1</sup></p>
          <p>MS can&rsquo;t be cured. However, disease progression can be well managed in patients with appropriate treatment initiation and response.<sup>1</sup> A suboptimal treatment response is often a common concern with DMTs.<sup>1</sup> Achieving this objective isn&rsquo;t always straightforward, and many patients will require a change in therapy over the course of their disease.<sup>1</sup></p>
          <p>So today, we&rsquo;ll discuss some reasons physicians, and their patients might want to consider switching DMTs and some of the risks and benefits associated with switching treatments.</p>
          <p>Let&rsquo;s start off with some common reasons physicians may need to switch their patients&rsquo; DMTs.</p>
          <p><strong>James Stankiewicz</strong></p>
          <p>It&rsquo;s a pleasure to be here.</p>
          <p>Now, the ultimate goal of treatment is to maximize disease control and minimize risk, and the most common reason to switch DMTs is to prevent relapses and disability accumulation.<sup>1</sup> So any time a patient shows evidence of disease progression, a treatment switch may be considered.<sup>2</sup></p>
          <p>However, patient preference can also influence the decision to switch DMTs.</p>
          <p>In one study, about one-third of DMT switching was attributable to patient preference, with the most common reasons being treatment fatigue and the desire to change treatment.<sup>2</sup> Regarding patient desire to switch, there is a perception that patients are particularly opposed to selfinjectable treatments. However, the evidence suggests that treatment administration&mdash;either route or frequency&mdash;only contributes to switching in about 12% of cases.<sup>2</sup></p>
          <p>Adverse events and patient intolerance of medication side effects can also influence the decision to switch therapy. The risk&ndash;benefit ratio must always be carefully assessed to balance safety and efficacy of DMTs on a patient-by-patient basis.<sup>2,3</sup></p>
          <p><strong>Jason Freeman</strong></p>
          <p>Before we explore the dynamics of therapy switching, are there other factors that should prompt clinicians to initiate a discussion about treatment switching?</p>
          <p><strong>James Stankiewicz</strong></p>
          <p>Yes. Aside from factors directly related to treatment efficacy and safety profile, there are several other situations where treatment switching or even treatment discontinuation may be considered. Patients with certain comorbidities, for example gastrointestinal disorders, diabetes, cardiac disease, or cancer, should be carefully monitored.<sup>3</sup> Women of childbearing age should also be carefully monitored as there are no currently approved treatments for women during pregnancy or breastfeeding in the United States.<sup>3</sup> The desire to switch for some patients may also be driven by financial reasons. In these cases, opportunities for financial assistance and appeals against denials should be investigated before considering a switch if a patient is doing well on a therapy.<sup>3</sup> Finally, patient preference and psychosocial factors should be considered.<sup>3</sup> For example, if a patient is consistently nonadherent to medication, this may increase the risk for a return of disease activity and have unfavorable effects on disease outcome.<sup>3</sup></p>
          <p><strong>Jason Freeman</strong></p>
          <p>Yes, thanks for that information. We have podcast episodes dedicated to both pregnancy in MS and patient adherence, where we discuss taking and stopping disease-modifying therapies while pregnant, and factors affecting patient adherence.&nbsp;</p>
          <p>Let&rsquo;s switch lanes now and explore some approaches that physicians should consider when patients might want or need to switch DMTs.</p>
          <p><strong>James Stankiewicz</strong></p>
          <p>There are 2 common strategies known in the medical community for MS. It is important to note that the FDA does not differentiate between DMTs and deems all effective and safe. However, in the MS community, there is a notion of what doctors consider has high or low risk or efficacy. These strategies are also defined differently in literature. One strategy for treating MS is&nbsp;escalation therapy, starting with lower-risk options of moderate efficacy and switching to higher-efficacy options if there is evidence of breakthrough disease.<sup>4</sup></p>
          <p>As mentioned earlier, the goal of treatment is to reduce disease activity and disability.<sup>1</sup> The main reason for an escalation strategy switch is often driven by poor disease control rather than adverse events or patient preference.<sup>2,5</sup> Progression or stagnant disease activity is monitored via radiological activity, gadolinium-enhancing T1 lesions or new T2 lesions, or clinical disease progression assessed by persistent clinical symptoms or increases in Expanded Disability Status Scale score.<sup>2</sup> Although escalating to higher efficacy treatment often has positive effects on disease activity, such as relapse rates and disability progression,<sup>2,5</sup> improved outcomes can&rsquo;t be guaranteed.<sup>2</sup></p>
          <p>Another strategy is induction therapy or initiating a high-efficacy therapy that may be associated with better disease control but may have higher safety risks. <sup>1</sup> This treatment approach may be preferred for patients with aggressive disease activity, such as in the cases of at least 2 disabling relapse episodes in the past year, at least 2 to 3 gadolinium-enhancing lesions, or accumulation of 2 to 3 new T2 lesions.<sup>6</sup> While there is a possibility of increased risk of adverse events, induction therapy may be more beneficial for some patients with relapsing MS.<sup>5</sup></p>
          <p>When we talk about minimizing risk, we are referring to risk of disease progression but also risks of treatment-related adverse events. Clinicians must therefore carefully balance the risk of disease progression with the risk of adverse events.<sup>2</sup> Therefore, switching DMTs may also be considered as a result of treatment-related side effects, even in patients with no evidence of breakthrough disease activity.<sup>4</sup></p>
          <p><strong>Jason Freeman</strong></p>
          <p>Thanks for that summary, Dr Stankiewicz. Now, although escalation therapy is generally well accepted for the treatment of RRMS, there is no real consensus on how to define the threshold for breakthrough disease or when a patient is considered to have a more aggressive form of RRMS.<sup>5</sup> And beyond that, there are many factors&mdash;besides disease activity&mdash;that a physician must consider when deciding timing for DMT switching and which subsequent treatment to select. Would you like to elaborate on that?</p>
          <p><strong>James Stankiewicz</strong></p>
          <p>Sure. And I think one of the most important factors to consider is patient perspective on switching treatment. Research shows that patients with MS value being a part of their treatment decisions, and, therefore, it&rsquo;s really important to involve them in these&nbsp;conversations. <sup>7</sup> Some patients may also be fearful of new high-efficacy therapies that may have significant side effects, especially if they feel they are doing well on their current treatment.<sup>8</sup></p>
          <p>However, if a patient is not being adherent to their current medication, that will be a totally different conversation because, as we know, patient adherence to medication is a considerable problem that may impair clinical responses.<sup>3,6</sup> Therefore, switching therapies may be considered if a patient admits they are nonadherent, or if a clinician strongly suspects this is the case. Nonadherence may lead to suboptimal clinical and neuroradiological responses and also potentially reduce quality of life.<sup>6</sup> If a patient isn&rsquo;t adherent to medication, sometimes a lateral switch between DMTs might help sustain or possibly improve clinical efficacy.<sup>2,6</sup></p>
          <p><strong>Jason Freeman</strong></p>
          <p>For our listeners, I wanted to note that we have done other podcasts on adherence for those who are interested in exploring this topic further. For now, let&rsquo;s stay focused on switching therapies. We discussed some ways in which patients may benefit from treatment switching, particularly when there is evidence of suboptimal response. What about the risks associated with switching DMTs?</p>
          <p><strong>James Stankiewicz</strong></p>
          <p>Switching DMTs isn&rsquo;t straightforward and requires careful planning considering the drug&rsquo;s mechanism of action, lasting pharmacokinetics, pharmacodynamics, and any potential overlapping effects with the previous therapy.<sup>1,3</sup></p>
          <p>For instance, some therapies may require a washout period to prevent potential toxicity and an increased risk of infection from overlapping effects. However, this risk must be balanced with leaving patients vulnerable to an increased risk for rebound disease activity. <sup>4</sup> Additionally, the exposure to multiple lines of disease-modifying therapies can increase the likelihood of developing progressive multifocal leukoencephalopathy, or PML, after exposure to certain therapies.<sup>6</sup></p>
          <p>All these risks and benefits should be openly communicated with each patient to gauge their level of risk tolerance and preferences.</p>
          <p><strong>Jason Freeman</strong></p>
          <p>Of course, as with all treatments, it&rsquo;s important to manage expectations and to explain the potential advantages and disadvantages of treatment switching to patients.<sup>6</sup></p>
          <p>That was quite an insightful discussion around switching DMTs in patients with MS. Thanks for helping us deep dive into this topic today, Dr Stankiewicz.</p>
          <p><strong>James Stankiewicz</strong></p>
          <p>It has been my pleasure. Thank you for having me.</p>
          <p><strong>Jason Freeman</strong></p>
          <p>Now, to summarize today&rsquo;s episode: Reasons for switching therapies are variable and unique for each patient and may change over the course of their disease.<sup>2</sup> When selecting therapies, patient-related factors such as route and frequency of administration, likelihood of adherence, patient preference, and comfort with risk of adverse events should all be considered.<sup>1,6</sup> It&rsquo;s essential to monitor the effects of therapies on disease progression routinely.<sup>5</sup></p>
          <p>Lastly, when managing the disease course of MS with treatment switches, washout periods should also be taken into account with the aim of achieving a balance of reducing potential compounding toxicities between the overlapping drugs resulting in potential infections and minimizing the risk of the disease worsening while patients are left untreated.<sup>1,3,4</sup></p>
          <p>Thank you for listening to this podcast on switching disease-modifying therapies in patients with multiple sclerosis. I hope you will explore the other resources on our website, MSHCPeducation.com, for health care providers who manage patients with MS.</p>                    

          <p class="date-job-code">
            <span class="date">11/23</span> <span class="job-code">286195</span>
          </p>
        </template>
      </PodcastPage>

      <RefsModal v-if="$store.state.showModal === 'references'">
        <template #refs-list>
          <ol class="refs-list">
            <li>
              Grand-Maison F, Yeung M, Morrow SA, et al. Sequencing of high-efficacy disease-modifying
              therapies in multiple sclerosis: perspectives and approaches. <em>Curr Med Res Opin.</em>
              2018;34(8):1419-1430.
            </li>
            <li>
              Tourbah A, Papeix C, Tourniaire P, et al. Reasons for switching to fingolimod in patients
              relapsing-remitting multiple sclerosis in France: the ESGILE study. <em>Mult Scler Relat Disord.</em>
              2020;46:102433.
            </li>
            <li>
              Cleveland Clinic. Switching disease modifying therapies in multiple sclerosis. Accessed
              September 12, 2023. <br />
              https://my.clevelandclinic.org/departments/neurological/depts/multiple-sclerosis/ms-approaches/switching-disease-modifying-therapies
            </li>
            <li>
              Bigaut K, Cohen M, Durand-Dubief F, et al. How to switch disease-modifying treatments in
              multiple sclerosis: guidelines from the French Multiple Sclerosis Society (SFSEP). <em>Scler Relat.</em>
              2021;53:103076.
            </li>
            <li>
              Spelman T, Magyari M, Piehl F, et al. Treatment escalation vs immediate initiation of highly
              effective treatment for patients with relapsing-remitting multiple sclerosis data from 2
              different national strategies. <em>JAMA Neurol.</em> 2021;78(10):1197-1204.
            </li>
            <li>
              Padro G, Jones DE. The sequence of disease‑modifying therapies in relapsing multiple
              sclerosis: safety and immunologic considerations. <em>J Neurol.</em> 2017;264:2351-2374. 
            </li>
            <li>
              Heesen C, Solari A, Giordano A, Kasper J, Köpke S. Decisions on multiple sclerosis
              immunotherapy: new treatment complexities urge patient engagement. <em>J Neurol Sci.</em>
              2011;306(1-2):192-197.
            </li>
            <li>
              Lynd LD, Henrich NJ, Hategeka C, et al. Perspectives of patients with multiple sclerosis on
              drug treatment: a qualitative study. <em>Int J MS Care.</em> 2018;20:269-277. 
            </li>
          </ol>
        </template>
      </RefsModal>
    </div>
  </div>
</template>

<script>
import PodcastPage from "@/components/PodcastPage";
import RefsModal from "@/components/RefsModal";

export default {
  name: "SwitchingDmtsPodcast",
  components: {
    PodcastPage,
    RefsModal,
  },
  data() {
    return {
      id: this.$router.currentRoute.params.id,
      audio: {
        id: "switching-disease",
        title:
          "Switching Disease-Modifying Therapies in MS Podcast",
        src: "assets/audio/switching-DMTs.mp3",
        analyticsLabel:
          "Switching Disease-Modifying Therapies in MS Podcast",
        desc: "Dr Jason Freeman, neurologist (Medical Director, Novartis), and Dr James Stankiewicz (Executive Medical Director, Novartis) discuss reasons to consider switching disease-modifying therapies and some of the risks and benefits associated with switching treatments.",
      },
    };
  },
  methods: {
    showPdf: function (data) {
      this.$router.push({ name: data.src.split(".")[0] });

      this.$gtag.event("click", {
        event_category: "view_pdf",
        event_label: `${data.analyticsLabel}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.podcast-page {
  color: $black;
  background: rgb(255, 255, 255);
  position: relative;
}

.mesh {
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    pointer-events: none;
    background-image: url("~@/assets/img/video-bg.png");
    top: 0;
    right: 0;
  }
}

.tactic-btn {
  border: 1px solid $white;
  background: none;
  color: $white;
  padding: rem(10px) rem(25px);
  text-align: left;
  position: relative;
  width: 100%;
  min-height: rem(60px);
  display: block;
  margin-bottom: rem(20px);

  &:last-child {
    margin-bottom: 0px;
  }
}

.tactic-btn-label {
  float: left;
  font-size: rem(18px);
  font-weight: $font-weight-bold;
  width: calc(100% - 50px);
  position: absolute;
  top: 50%;
  left: rem(20px);
  transform: translate(0%, -50%);
}

.tactic-btn-icon {
  float: left;
  width: rem(20px);
  margin-left: rem(30px);
  position: absolute;
  top: 50%;
  right: rem(20px);
  transform: translate(0%, -50%);

  img {
    width: 100%;
  }

  &.launch-icon {
    width: rem(27px);
  }
}

:deep(.audio-title) {
  display: none !important;
}
</style>
