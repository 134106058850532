<template>
  <div :id="`${id}`" class="podcast-page mesh">
    <div class="inner-wrapper">
      <PodcastPage>
        <template #header>{{ audio.title }}</template>
        <template #podcast-asset><img src="~@/assets/img/choice-of-therapy-podcast.png" alt="Sound waves behind an elderly patient looking at their reports along with their doctor" /></template>
        <template #podcast-copy>{{ audio.desc }}</template>
        <template #podcast-content>
          <AudioPlayer :data="audio" :key="audio.id" />
        </template>

        <template #transcript>    
          <p><strong>Jason Freeman</strong></p>
          <p>This podcast is sponsored by Novartis Pharmaceuticals Corporation. The content provided herein is for your background and educational purposes only. The material is for your sole use and may not be altered or further disseminated in any fashion for further use.</p>
          <p>Welcome to our podcast. My name is Dr Jason Freeman, and I&rsquo;m a neurologist and Medical Director with Novartis Pharmaceuticals. Joining me today is Dr Brandon Brown, also a Senior Medical Director with Novartis Pharmaceuticals.</p>
          <p>In a previous podcast, we discussed the benefits and barriers of adherence to treatment in patients with multiple sclerosis, or MS. Today, we want to dig a little bit deeper into factors related to treatment that may impact patient adherence.</p>
          <p>MS is a demyelinating disorder that affects the central nervous system. There is convincing literature that suggest that disease-modifying therapies, or DMTs, can help slow the rate of disease progression, reduce the likelihood of relapse rates, and reduce disability progression.<sup>1,2</sup></p>
          <p>So, Dr Brown, let&rsquo;s start by discussing what adherence really means and how it is typically measured in clinical practice. Is there a standard way of measuring this?</p>
          <p><strong>Brandon Brown</strong></p>
          <p>Thank you, Dr Freeman. It&rsquo;s a pleasure to be here with you today discussing this important topic.</p>
          <p>So if we look at the published literature, we use terms like &ldquo;adherence,&rdquo; &ldquo;compliance,&rdquo; and &ldquo;persistence,&rdquo; and these all describe different aspects of clinical care. These are all related concepts as they capture different aspects of medication use.<sup>3</sup> For instance, adherence is defined as &ldquo;the extent to which a person&rsquo;s behavior, either taking a medication, following a diet, and/or executing lifestyle changes, corresponds with the agreed recommendations from a health care provider.&rdquo; And compliance, on the other hand, is the extent to which the patient&rsquo;s behavior matches the prescriber&rsquo;s recommendations. So think of adherence as more proactive than compliance. Compliance is more of a passive behavior in which the patient simply follows the instructions from the health care provider.<sup>4</sup> And persistence measures the duration of time on therapy without interruptions from initiation until discontinuation.<sup>3</sup> And of course, discontinuation can occur at any time where there are significant gaps between treatments or switch in treatment.<sup>5</sup></p>
          <p>So coming back to your point about measuring adherence, there&rsquo;s really no gold standard for assessing this. According to the World Health Organization, adherence may be assessed using both subjective and objective measures. The most commonly used subjective measurement is self-reported, of course, and provider assessments. However, it&rsquo;s important to keep in mind that patients may underreport their lack of adherence to avoid disapproval from their provider.<sup>6</sup></p>
          <p>On the other hand, objective measures may be useful to validate subjective reports or to confirm the suspicion of a patient who is not taking their medication as prescribed. And this can be done in various ways, such as counting pills, electronic monitoring, secondary database analysis, and biochemical testing.<sup>6</sup></p>
          <p>Both approaches, of course, have their own strengths and weaknesses, and therefore, clinicians have limited measures to assess whether a patient is being adherent to their prescribed treatment plan.</p>
          <p><strong>Jason Freeman</strong></p>
          <p>Thanks, Dr Brown. As you note, adherence is a complex outcome to monitor.&nbsp;</p>
          <p>So, with the lack of reliable assessment tools for adherence, what the evidence that connects treatment adherence to disease activity in patients with MS?</p>
          <p><strong>Brandon Brown</strong></p>
          <p>So peer-reviewed publications showing data from long-term studies, clinical practice guidelines, and real-world observations suggest that treatment adherence may be associated with improvements across multiple domains. For example, patients who report high levels of adherence to medication were reported to be less likely to experience relapses,<sup>7,8</sup> less likely to experience MS-related hospitalizations,<sup>7,8</sup> have lower MS-related medical costs,<sup>7</sup> and have improved relationships with their health care providers compared to patients who reported low levels of adherence to medication.<sup>9</sup> Naturally, causality cannot be inferred from these studies, of course, as they are typically retrospective in nature. So it is also possible, for example, that patients who have good relationships with their physicians are more adherent to treatment. However, the research does suggest there may be benefits associated with treatment adherence across multiple domains.</p>
          <p>Increasingly, we are also learning that symptoms can occur and worsen in the absence of clear relapses and may be markers of ongoing neuroinflammation that are not being captured by standard clinical exams or MRIs. This has been termed &ldquo;progression independent of relapse activity.&rdquo;<sup>10</sup> So we can look to longer-term observational studies to explore the impact of treatment adherence on those outcomes that are important to patients.</p>
          <p><strong>Jason Freeman</strong></p>
          <p>We may also want to consider that the data on medication efficacy is largely based on clinical trials, which are associated with adherence rates approaching 100%.<sup>3</sup> This is contradictory to real-world data, which suggests only about 50% of patients with MS are adherent to their prescribed treatment plan.<sup>8,11</sup> Why do you think there&rsquo;s such a disparity in these adherence rates?</p>
          <p><strong>Brandon Brown</strong></p>
          <p>Yes, as you know, clinical trial efficacy outcomes are associated with a high rate of adherence to treatment, likely close to a 100% in most studies. It&rsquo;s also important to keep in mind that trial outcomes are usually assessed over a short period of time. There was one study that estimated the average duration of a phase 3 clinical trial in MS was only 2 years.<sup>12</sup> And finally, the primary efficacy outcomes are usually limited to aspects of disease that are easy to measure.</p>
          <p>Common outcome measures in clinical trials are annualized relapse rates, along with other markers such as progression due to physical disability and MRI lesion changes. And these outcome measures are easily quantifiable and have been positively impacted by disease-modifying therapies for MS. For example, we estimate relapse rate reductions in trials anywhere from 53% to 68% in some of the trials investigating monoclonal antibody<sup>13,14,15</sup> and oral therapies.<sup>16</sup></p>
          <p>Therefore, clinical trial data is compelling in terms of the impact of disease-modifying therapies on slowing progression in patients with MS.</p>
          <p>It&rsquo;s important to note that some patients are less concerned about outcomes that can be assessed objectively, such as relapse rates and imaging to assess disease activity, and are more concerned with what they often refer to as silent symptoms, and these are symptoms such as cognitive impairment, fatigue, and depression.<sup>10</sup></p>
          <p>However, current MS medications are not indicated for symptom management. Therefore, in clinical trials, we really should find a better way to assess aspects of disease that are important to these patients, such as fatigue and cognitive function.</p>
          <p>But taken together, data from clinical experience, real-world data, and clinical trials provide ample evidence of the importance of medication adherence in patients with MS.</p>
          <p><strong>Jason Freeman</strong></p>
          <p>Thanks, Dr Brown.</p>
          <p>We know that adherence is complex, and the research has identified multiple factors&mdash;such as age, duration of illness, severity of symptoms, patient health literacy, and their relationship with their health care provider&mdash;that impact adherence. Perhaps we can focus now on treatment-specific factors, such as route of administration, efficacy or perceived efficacy, and side effects. Is there evidence that different types of medications are associated with different levels of adherence?</p>
          <p><strong>Brandon Brown</strong></p>
          <p>Yeah, so as you know, there are multiple published data that suggest that multiple patient-specific factors might impact adherence. So some examples are patients who are younger, have a lower education, who are from lower incomes, and have milder disability were reported less likely to be&nbsp;adherent. Shared decision-making may be associated with improved patient satisfaction and better adherence.<sup>3,17</sup></p>
          <p>In terms of treatment, yes, there is some evidence that some factors may impact adherence.</p>
          <p>In MS, the route of administration might be the first thing that comes to mind for clinicians in terms of adherence to therapy. Many clinicians still remember the broad side effect profile of early injectable DMTs, including injection site reactions and flu-like symptoms, and when oral DMTs became available, they had a different side effect profile, like fatigue and headache. However, if we look at data from retrospective studies based on both patient- and physician-reported outcomes, there&rsquo;s a suggestion that adherence levels in patients on oral DMTs may be comparable to those on injectables.<sup>18,19</sup></p>
          <p>Interestingly, patient-reported outcomes suggest that frequency of dosing may affect treatment adherence. For instance, some patients prefer once-weekly injections over oral DMTs that might be given once or twice a day.<sup>20</sup> On the other hand, patients with a needle phobia or who like the routine of daily medication may prefer an oral medication.<sup>2,3,11</sup> While some may feel empowered by self-injection, others may want to deal with their treatments less often and prefer an IV therapy.<sup>21</sup> Based on the available literature, the initial treatment decision should be guided by patient preference, of course.<sup>18,19</sup></p>
          <p>Side effects should also be closely monitored, and clinicians should be very proactive in their discussions, asking about side effects as some may be associated with higher rates of nonadherence, for example, injection site reactions and flu-like symptoms.<sup>3,8</sup></p>
          <p>And finally, efficacy is an important factor and, in particular, patient-perceived efficacy. Physicians should have an open discussion to manage patient expectations about the potential benefits and limitations of MS medications as patients that perceive a lack of efficacy are less likely to be adherent.<sup>2,3,8,21</sup> Patients should be informed that the available treatment options may lower the relapse rate, slow the formation of new lesions, and potentially reduce the risk of brain atrophy and disability accumulation, but they may not reverse existing disability.<sup>22</sup></p>
          <p>So a shared decision-making approach about preferences regarding treatment options, risk tolerance, and perceived benefits was shown to be associated with improved treatment satisfaction and promotes adherence.<sup>3</sup></p>
          <p><strong>Jason Freeman</strong></p>
          <p>So you make an interesting point about adherence and patient perception or at least the belief that a treatment is working. As clinicians, we know that MS is a disease that can progress slowly for years and that even early neuroinflammatory activity can be irreversible.</p>
          <p>But we assess progression using measures like relapses and decreased mobility that can take years to occur. In addition, neuronal damage may not always be visible on MRI. So how can clinicians encourage patients to take responsibility for assessing their own disease activity and how might that lead to improved adherence?</p>
          <p><strong>Brandon Brown</strong></p>
          <p>Yeah, this is a great question. The literature suggests a few things that clinicians can do that might help. So, of course, demographics and lifestyle are beyond the control of clinicians. However, satisfaction with treatment may play a considerable role in adherence.</p>
          <p>So we discussed already that many patients are more concerned with the daily impact of disease, such as fatigue, cognitive functioning, and depression, over these outcomes that are more difficult for them to evaluate, such as relapses, disability, and MRI activity. So open and honest communication about how a patient is feeling about their disease activity is critical to promote adherence.<sup>23</sup></p>
          <p>Patients should be made aware of the available digital tools to monitor symptoms and track adherence.<sup>24</sup> This, along with evaluating side effects and the perceived burden of taking medication, should be explored routinely to determine if patients are taking their medications as prescribed or if switching medications could help address concerns that are impacting adherence.<sup>23</sup></p>
          <p><strong>Jason Freeman</strong></p>
          <p>Those are great insights; thank you for that.</p>
          <p>But let's shift gears and talk about perceived treatment efficacy a little bit more. We already discussed that sometimes patients have different priorities than their health care providers. What do we mean by perceived treatment efficacy compared to real efficacy?</p>
          <p><strong>Brandon Brown</strong></p>
          <p>Well, it&rsquo;s important to note that the FDA does not differentiate between DMTs and deems all of them effective and safe. However, there are multiple observational data that suggest certain types of medications may be associated with differing results in regards to reducing relapses and slowing progression of disease activity. This is an example of perceived treatment efficacy, where patients may think a treatment has better efficacy over others.</p>
          <p>Patient-reported outcomes that measure an impact on patient&rsquo;s daily lives and quality of life, including these things such as fatigue, cognitive functioning, and depression, are assessed as exploratory outcomes. Therefore, these are typically overshadowed by traditional efficacy outcomes like relapse rate and MRI activity in these trials.<sup>25,26</sup></p>
          <p>It&rsquo;s important for a patient to understand that DMTs do not treat symptoms. However, these patient-reported outcomes might influence patient perception of efficacy as well as satisfaction with care and therefore adherence. So future research is definitely needed in this area.</p>
          <p><strong>Jason Freeman</strong></p>
          <p>So we&rsquo;ve already talked about some ways physicians can play a positive role in patient adherence, but are there other strategies we can use to improve adherence?</p>
          <p><strong>Brandon Brown</strong></p>
          <p>Patient education continues to be consistently associated with improved satisfaction and adherence. We know clinical time is a limiting factor for many health care providers, so we can encourage patients to take control of their disease by guiding them to online patient-centered educational programs.<sup>21 </sup></p>
          <p>These programs can help distill complicated information about proper dosing and timing and the symptoms associated with under- and overusing medication.<sup>21</sup> Additionally, educational resources such as videos, hands-on demonstrations and group discussions can help improve patients&rsquo; attitudes towards disease management and adherence.<sup>21</sup></p>
          <p>It&rsquo;s also noteworthy that the American Academy of Neurology practice guidelines encourage clinicians to evaluate a patient&rsquo;s readiness or reluctance to treatment prior to initiating as this can impact adherence.<sup>26</sup> Further, the guidelines recommend that clinicians should proactively discuss the importance of adherence and evaluate any barriers to adherence before initiating treatment for patients with MS as a part of good clinical practice.<sup>27</sup> It&rsquo;s also important to address any comorbidities that may impact decision-making and treatment adherence, for example, depression.<sup>27</sup></p>
          <p><strong>Jason Freeman</strong></p>
          <p>Thank you, Dr Brown, for the insights you&rsquo;ve shared today. In this episode, we discussed specific treatment-related factors that can impact adherence, and how health care providers can help their patients navigate these barriers. We also discussed some of the challenges in interpreting the literature, recognizing the variability and definitions that need to be considered. We also discussed the important factors of patient perception and satisfaction with care in promoting adherence.</p>
          <p>Improved awareness of the factors that may impact nonadherence can help clinicians identify and address these issues such that patients can truly experience the benefits of medication adherence.<sup>21</sup></p>
          <p><strong>Brandon Brown</strong></p>
          <p>Yes, I think a great take-home message is to empower patients to better understand their disease and to communicate challenges early on. The more empowered patients feel, the more likely they are to be motivated to manage their disease and adhere to their treatment.<sup>11</sup></p>
          <p><strong>Jason Freeman</strong></p>
          <p>Thank you for listening to this podcast on medication adherence in patients with multiple sclerosis. I hope you will explore the other resources available on our website, MSHCPeducation.com, for health care providers who manage patients with MS.</p>

          <p class="date-job-code">
            <span class="date">11/23</span> <span class="job-code">286194</span>
          </p>
        </template>
      </PodcastPage>

      <RefsModal v-if="$store.state.showModal === 'references'">
        <template #refs-list>
          <ol class="refs-list">
            <li>
              Remington G, Rodriguez Y, Logan D, et al. Facilitating medication adherence in patients with multiple
              sclerosis. <em>Int J MS Care.</em> 2013;15:36-45. doi:10.7224/1537-2073.2011-038
            </li>
            <li>
              Menzin J, Caon C, Nichols C, et al. Narrative review of the literature on adherence to disease-modifying
              therapies among patient with multiple sclerosis. <em>J Manag Care Pharm.</em> 2013;19(1-a):S24-S40.
              doi:10.18553/jmcp.2013.19.s1.S24
            </li>
            <li>
              Kołtuniuk A, Chojdak-Łukasiewicz J. Adherence to therapy in patients with multiple sclerosis-review. <em>Int J
              Environ Res Public Health.</em> 2022;19:2203. doi:10.3390/ijerph19042203 
            </li>
            <li>
              Mir TH. Adherence versus compliance. <em>HCA Healthc J Med.</em> 2023;4(2):219-220. doi:10.36518/2689-0216.1513
            </li>
            <li>
              Gorritz M, Chen C-C, Tuly R, et al. Real-world persistence of ofatumumab versus oral disease modifying
              therapies (DMTs) in patients with multiple sclerosis (MS). Presented at: Consortium of Multiple Sclerosis
              Centers Annual Meeting; May 31–June 3, 2023; Aurora, CO.
            </li>
            <li>
              Lam WY, Fresco P. Medication adherence measures: an overview. <em>Biomed Res Int.</em> 2015;2015:217047. doi:10.1155/2015/217047
            </li>
            <li>
              Tan H, Cai Q, Agarwal S, et al. Impact of adherence to disease-modifying therapies on clinical and
              economic outcomes among patients with multiple sclerosis. <em>Adv Ther.</em> 2011; 28(1):51-61.
              doi:10.1007/s12325-010-0093-7 
            </li>
            <li>
              Lizan L, Comellas M, Paz S, et al. Treatment adherence and other patient-reported outcomes as cost
              determinants in multiple sclerosis: a review of the literature. <em>Patient Prefer Adherence.</em> 2014;1653-1664.
              doi:10.2147/PPA.S67253
            </li>
            <li>
              Kołtuniuk A, Pytel A, Krówczyńska D, Chojdak-Łukasiewicz J. The quality of life and medication
              adherence in patients with multiple sclerosis—cross-sectional study. <em>Int J Environ Res Public Health.</em>
              2022;19:14549. doi:10.3390/ ijerph192114549 
            </li>
            <li>
              Giovannoni G, Butzkueven H, Dhib-Jalbut S, et al. Brain health: time matters in multiple sclerosis. <em>Mult
              Scler Relat Disord.</em> 2016;9:S5-S48. doi:10.1016/j.msard.2016.07.003
            </li>
            <li>
              Brown MT, Bussell JK. Medication adherence: WHO cares? <em>Mayo Clin Proc.</em> 2011;86(4):304-314.
              doi:10.4065/mcp.2010.0575
            </li>
            <li>
              Gehr S, Kaiser T, Kreutz R, et al. Suggestions for improving the design of clinical trials in multiple
              sclerosis—results of a systematic analysis of completed phase III trials. <em>EPMA Journal.</em> 2019; 10:425-436.
              doi:10.1007/s13167-019-00192-z
            </li>
            <li>
              Cohen JA, Coles AJ, Arnold DL, et al. Alemtuzumab versus interferon beta 1a as first-line treatment for
              patients with relapsing-remitting multiple sclerosis: a randomised controlled phase 3 trial. <em>Lancet.</em>
              2012;380(9856):1819-1828. doi:10.1016/S0140-6736(12)61769-3 
            </li>
            <li>
              Coles AJ, Compston DAS, Selmaj KW, et al. Alemtuzumab vs. interferon beta-1a in early multiple
              sclerosis. <em>N Engl J Med.</em> 2008;359(17):1786-1801. doi:10.1056/NEJMoa0802670
            </li>
            <li>
              Polman Ch, Connor PWO, Havrdova E, et al. A randomized, placebo-controlled trial of natalizumab for
              relapsing multiple sclerosis. <em>N Engl J Med.</em> 2006;354(9):899-910. doi:10.1056/NEJMoa044397
            </li>
            <li>
              Gold R, Kappos L, Arnold DL, et al. Placebo-controlled phase 3 study of oral BG-12 for relapsing multiple
              sclerosis. <em>N Engl J Med.</em> 2012;367(12):1098-1107. doi:10.1056/NEJMoa1114287
            </li>
            <li>
              McKay KA, Tremlett H, Patten SB, et al. Determinants of non-adherence to disease-modifying therapies
              in multiple sclerosis: a cross-Canada prospective study. <em>Mult Scler.</em> 2017;23(4):588-596.
              doi:10.1177/1352458516657440
            </li>
            <li>
              Chen J, Watson C, Thirumulai D, et al. Adherence to disease-modifying treatments in patients with active
              and nonactive progressive multiple sclerosis in the United States. Presented at: the International Society
              for Pharmacoeconomics and Outcomes Research Annual Meeting; May 7-10, 2023; Boston, MA. 
            </li>
            <li>
              Burks J, Marshall TS, Ye X. Adherence to disease-modifying therapies and its impact on relapse, health
              resource utilization, and costs among patients with multiple sclerosis. <em>Clinicoecon Outcomes Res.</em>
              2017;9:251-260. doi:10.2147/CEOR.S130334
            </li>
            <li>
              Utz K, Hoog J, Wentrup A, et al. Patient preferences for disease-modifying drugs in multiple sclerosis
              therapy: a choice-based conjoint analysis. <em>Ther Adv Neurol Disord.</em> 2014;7(6):263-275.
              doi:10.1177/1756285614555335
            </li>
            <li>
              Hamtaeigashti S, Shamsi M, Sahraian MA, et al. Effect of an educational intervention based on the
              theory of planned behavior on improving medication adherence in patients with multiple sclerosis
              treated with injectable disease-modifying drugs: randomized controlled trial. <em>BMC Public Health.</em>
              2023;23(1):999. doi:10.1186/s12889-023-15910-6 
            </li>
            <li>
              Mayo Clinic. Multiple sclerosis. Accessed September 28, 2023. <br />https://www.mayoclinic.org/diseases-conditions/multiple-sclerosis/diagnosis-treatment/drc-20350274 
            </li>
            <li>
              Washington F, Langdon D. Factors affecting adherence to disease modifying therapies in multiple
              sclerosis: systematic review. <em>J Neurology.</em> 2022;269:1861-1872. doi:10.1007/s00415-021-10850-w 
            </li>
            <li>
              Marzinial M, Brichetto G, Feys P, et al. The use of digital and remote communication technologies as a
              tool for multiple sclerosis management: narrative review. <em>JMIR Rehabil Assist Technol.</em> 2018;5(1):e5.
              doi:10.2196/rehab.7805
            </li>
            <li>
              van Munster CEP, Utidehaag BMJ. Outcome measures in clinical trials for multiple sclerosis. <em>CNS Drugs.</em>
              2017;31(3):217-236. doi:10.1007/s40263-017-0412-5
            </li>
            <li>
              Nowinski CJ, Miller DM, Cella D. Evolution of patient-reported outcomes and their role in multiple
              sclerosis clinical trials. <em>Neurotherapeutics.</em> 2017;14(4):934-944. doi:10.1007/s13311-017-0571-6
            </li>
            <li>
              American Academy of Neurology (AAN) publication. Practice guideline recommendations: Disease-modifying therapies for adults with multiple sclerosis. Neurology, April 2018
            </li>
          </ol>
        </template>
      </RefsModal>
    </div>
  </div>
</template>

<script>
import PodcastPage from "@/components/PodcastPage";
import RefsModal from "@/components/RefsModal";

export default {
  name: "PatientAdherencePodcast",
  components: {
    PodcastPage,
    RefsModal,
  },
  data() {
    return {
      id: this.$router.currentRoute.params.id,
      audio: {
        id: "patient-adherence",
        title:
          "Choice of Therapy and Patient Adherence in MS Podcast",
        src: "assets/audio/ms-choice-of-therapy-and-patient-adherence.mp3",
        analyticsLabel:
          "Choice of Therapy and Patient Adherence in MS Podcast",
        desc: "Dr Jason Freeman, neurologist (Medical Director, Novartis), along with Dr Brandon Brown (Senior Medical Director, Novartis) dig a little bit deeper into treatment-related factors that may impact patient adherence.",
      },
    };
  },
  methods: {
    showPdf: function (data) {
      this.$router.push({ name: data.src.split(".")[0] });

      this.$gtag.event("click", {
        event_category: "view_pdf",
        event_label: `${data.analyticsLabel}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.podcast-page {
  color: $black;
  background: rgb(255, 255, 255);
  position: relative;
}

.mesh {
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    pointer-events: none;
    background-image: url("~@/assets/img/video-bg.png");
    top: 0;
    right: 0;
  }
}

.tactic-btn {
  border: 1px solid $white;
  background: none;
  color: $white;
  padding: rem(10px) rem(25px);
  text-align: left;
  position: relative;
  width: 100%;
  min-height: rem(60px);
  display: block;
  margin-bottom: rem(20px);

  &:last-child {
    margin-bottom: 0px;
  }
}

.tactic-btn-label {
  float: left;
  font-size: rem(18px);
  font-weight: $font-weight-bold;
  width: calc(100% - 50px);
  position: absolute;
  top: 50%;
  left: rem(20px);
  transform: translate(0%, -50%);
}

.tactic-btn-icon {
  float: left;
  width: rem(20px);
  margin-left: rem(30px);
  position: absolute;
  top: 50%;
  right: rem(20px);
  transform: translate(0%, -50%);

  img {
    width: 100%;
  }

  &.launch-icon {
    width: rem(27px);
  }
}

:deep(.audio-title) {
  display: none;
  display: none !important;
}
</style>
