<template>
  <div class="inner-wrapper">
    <div class="inner-content">
      <section>
        <h3>A UNIQUE, PERSONALIZED EDUCATION COURSE</h3>
        <h1>MS: Early Symptoms to Disease Progression</h1>
        <div
          class="app-cards"
          :style="{ 'background-image': 'url(./assets/img/video-bg.png)' }"
        >
          <AppCard v-for="card in cards" :key="card.title" :card="card">
          </AppCard>
        </div>
        <p class="footnote">MS, multiple sclerosis.</p>
      </section>
    </div>
  </div>
</template>

<script>
import AppCard from "@/components/AppCard.vue";
export default {
  components: {
    AppCard,
  },
  data() {
    return {
      cards: [
        {
          title: "Early Pathophysiology & Symptoms of MS",
          desc: "Test your knowledge of some of the early symptoms and potential consequences of delayed treatment in patients with MS.",
          imageSrc: "assets/img/early-symptoms-patho.svg",
          link: {
            urlDev: "https://kf1.amplifire.com/amp/#register/ms2learning",
            urlProd: "https://kf1.amplifire.com/amp/#register/ms2learning",
            analyticsLabel: "Early Pathophysiology & Symptoms of MS",
          },
        },
        {
          title: "Timing of Intervention in MS",
          desc: "Test your knowledge of early treatment optimization to potentially help manage patients with MS.",
          imageSrc: "assets/img/early-symptoms-timing.svg",
          link: {
            urlDev: "https://kf1.amplifire.com/amp/#register/ms2learning",
            urlProd: "https://kf1.amplifire.com/amp/#register/ms2learning",
            analyticsLabel: "Timing of Intervention in MS",
          },
        },
        {
          title:
            "High-efficacy Treatment Strategies & Managing Progression in MS",
          desc: "Test your knowledge of treatment strategies to potentially help manage the progression of MS.",
          imageSrc: "assets/img/early-symptoms-treatment.svg",
          link: {
            urlDev: "https://kf1.amplifire.com/amp/#register/ms2learning",
            urlProd: "https://kf1.amplifire.com/amp/#register/ms2learning",
            analyticsLabel:
              "High-efficacy Treatment Strategies & Managing Progression in MS",
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.page {
  background-image: url("~@/assets/img/video-bg.png");
}
section {
  padding-top: 60px;
  h1 {
    font-size: 2.5rem;
    line-height: 46px;
    text-align: left;

    @include breakpoint(xs) {
      font-size: 1.875rem;
      line-height: 24px;
    }
  }

  h3 {
    font-size: 30px;
    line-height: 46px;
    text-align: left;
    font-weight: normal;
    margin-bottom: 6px;

    @include breakpoint(xs) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .app-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 28px 0;
    gap: 20px;

    @include breakpoint(laptop) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
    @include breakpoint(xs) {
      flex-direction: column;
      justify-content: unset;
      align-items: center;
      gap: 20px;
    }
  }

  .footnote {
    text-align: left;
  }
}
</style>
