export const store = {
  state: {
    showModal: "",
    showTopBtn: false,
    showMenu: false,
    currentSectionToShow: "all",
    showRefs: false,
  },
  searchedTerm: "",
  searchData: [],
  toggleModal(id = "") {
    console.log("ToggleModal", id)
    /*
      If id is the same as modal already opened, then close existing modal. Otherwise open modal with id.
    */
    if (id === this.state.showModal) {
      this.state.showModal = ""
    } else {
      this.state.showModal = id
    }
  },
  toggleMenu() {
    this.state.showMenu = !this.state.showMenu
  },
  setSearchData: function (data) {
    this.state.searchData = data
  },
}

export {store as default}
