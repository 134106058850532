<template>
  <div :id="`${id}`" class="podcast-page mesh">
    <div class="inner-wrapper">
      <PodcastPage>
        <template #header>{{ audio.title }}</template>
        <template #podcast-asset><img src="~@/assets/img/evolving-landscape-podcast.png" alt="Sound waves behind an elderly woman in a wheelchair extending her hand towards a health care provider"/></template>
        <template #podcast-copy>{{ audio.desc }}</template>
        <template #podcast-content>
          <AudioPlayer :data="audio" :key="audio.id" />
        </template>

        <template #transcript>
          <p><strong>Jason Freeman</strong></p>
          <p>
            This podcast is sponsored by Novartis Pharmaceuticals. The
            information herein is provided for disease educational purposes
            only, and is not intended to be, nor does it imply, medical or
            diagnostic advice.
          </p>
          <p>
            Hello, and welcome to our podcast. My name is Dr Jason Freeman, a
            neurologist and medical director with Novartis Pharmaceuticals.
            Today I am joined by 2 guests.
          </p>
          <p><strong>Gina Cox</strong></p>
          <p>
            Hi Dr Freeman, thanks for having me on the podcast today. My name is
            Dr Gina Cox, and I'm a neuroimmunologist and senior medical director
            at Novartis Pharmaceuticals.
          </p>
          <p><strong>Brandon Brown</strong></p>
          <p>
            Hi Dr Freeman, thanks for having me. My name is Dr Brandon Brown, a
            clinical pharmacist and medical director at Novartis
            Pharmaceuticals.
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            For today's topic, we'll be discussing managing multiple sclerosis.
            One of the topics we'll touch on today is phenotypic classification
            in MS, and really how those classifications are standardized and
            utilized and allow HCPs to really develop different treatment
            strategies for patients across the MS spectrum.
          </p>
          <p>
            So, there are generally accepted MS types, which are often further
            divided into 2 phases: Both active disease that's characterized by
            neuroinflammatory processes, and progressive disease that's thought
            to be irreversible. So, Dr Cox, given the wealth of evidence on MS,
            do these distinctions continue to align with clinical practice?
          </p>
          <p><strong>Gina Cox</strong></p>
          <p>
            Dr Freeman, as you mentioned, there are generally accepted
            phenotypic classifications of MS, and these are really created to
            standardize the way we talk about patients within the literature as
            well as help to develop treatment strategies for patients across the
            MS spectrum. These range from clinically isolated syndrome, to
            radiologically isolated syndrome, to the most common subtype of MS,
            which is relapsing-remitting MS. Other subtypes representing more
            progressive features of the disease include secondary progressive
            MS, where patients have transitioned from RRMS, as well as primary
            progressive MS, those patients presenting with progression from the
            very beginning of their disease onset.<sup>1–3</sup>
          </p>
          <p>
            In addition to the phenotypic classifications of MS that we spoke
            about, we also think about how we might divide the disease as it
            progresses across the spectrum. So, there's this belief that early
            disease activity is characterized by active neuroinflammation, and
            this is what we observe on MRI scans and even clinical relapses. As
            the disease progresses and the neurodegenerative phase of the
            disease becomes apparent, this may be representative of a more
            difficult-to-treat scenario. And while these were initially thought
            to occur in sequence, so, active neuroinflammation followed by
            neurodegeneration, there's a lot of evidence now that suggests that
            neurodegeneration occurs from the very beginning of the disease.<sup
              >4,5</sup
            >
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            So, what I hear you saying, Dr Cox, is we've got both clinical
            phenotypes—relapsing-remitting, primary progressive, secondary
            progressive—but also this idea that there's really pathophysiologic
            sort of phenotypes or subtypes of MS, or at least disease processes
            that are ongoing throughout one's diagnosis. And I'm wondering how
            that actually then plays into clinical practice and how we go about
            managing patients?
          </p>
          <p><strong>Gina Cox</strong></p>
          <p>
            Sure. So, it's not always easy to be able to see silent disease
            activity, or sometimes the active neuroinflammation that's
            occurring, if it doesn't present in a clinically apparent way.<sup
              >3,6</sup
            >
            And so, we're looking at strategies to actually look under the
            surface, if you will, and understand how much subclinical disease
            activity is happening and how much urgency that might create in a
            treatment scenario.
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            So that may play into some experts out there who suggest that MS is
            really a continuum of disease versus these distinct clinical
            phenotypes.
          </p>
          <p><strong>Gina Cox</strong></p>
          <p>
            Exactly, Dr Freeman. And as a one-stage disease, if we classify it
            that way, we recognize that all these mechanisms are occurring in
            parallel, and again, really creates an urgency and a window of
            opportunity for therapeutic intervention early in the disease.<sup
              >7</sup
            >
          </p>
          <p>
            We've actually coined a new term around this, so, progression
            independent of relapse activity. And I think this has also helped to
            make very apparent that even in the absence of relapses, progression
            is occurring independent of that.<sup>5,7,8</sup> And we've defined
            a methodology actually for measuring this, and so we know it's
            occurring, again, suggesting that these inflammatory and
            neurodegenerative processes are occurring in parallel representing 1
            disease course.<sup>5</sup> And so, we can really focus and optimize
            our therapeutic treatment strategies from the very beginning of the
            disease.<sup>7</sup>
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            Thank you, Dr Cox. So, Dr Brown, what is the current thinking on
            treatment strategies? It appears that within the MS community there
            are physicians with differing mindsets, some who prefer escalation,
            and others who prefer high efficacy treatment strategies. What does
            this mean?
          </p>
          <p><strong>Brandon Brown</strong></p>
          <p>
            Sure. So, as you state, there's generally 2 accepted treatment
            mindsets in the community: The escalation strategy and the
            high-efficacy early treatment, or sometimes referred to as the early
            intense strategy. So, prescribers who believe in the early intense
            strategy favor initiation of what they consider to be high-efficacy
            treatment options. Now, I think I need to say that there's no clear
            standard definition of what's considered to be high-efficacy
            treatment, but their goal is to prioritize clinical benefits and
            take advantage of that window of opportunity that we know occurs
            early in the disease course.<sup>8–10</sup> On the other hand, those
            that believe in the escalation strategy prioritize treatments
            considered to have a lower risk of adverse events but that may have
            less impact on disease activity, with a plan to escalate to
            high-efficacy treatments if disease activity worsens.<sup>10</sup>
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            So, do we have any sense in the MS community of which particular
            approach is better for patient outcomes?
          </p>
          <p><strong>Brandon Brown</strong></p>
          <p>
            So, we don't, as of yet.<sup>10,11</sup> I will state that on either
            side of the camp there are strong opinions, but various treatment
            approaches are being systematically investigated in several
            real-world clinical trials that may help us understand the optimal
            timing of certain medications.<sup>10,12,13</sup>
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            So, Dr Cox, has the tolerance for disease activity changed over
            time? How have the treatment goals for MS patients evolved in the
            last 2 decades?
          </p>
          <p><strong>Gina Cox</strong></p>
          <p>
            Yes, absolutely we've evolved the treatment goals for MS patients.
            These goals have really moved from just managing acute inflammatory
            disease activity to really slowing or completely halting disease
            progression as an ultimate goal. As you mentioned, the armamentarium
            has grown, and in part really supports this shift of treatment
            goals, but also, we have new ways of measuring disease activity and
            the tolerance of that disease activity.
          </p>
          <p>
            For example, no evidence of disease activity, or NEDA, is a concept
            that has really emerged, shifting our expectations for a higher
            therapeutic goal. NEDA as a treatment goal is really defined by the
            absence of clinical and radiologic activity, as well as the absence
            of clinical disease progression.<sup>4</sup> And furthermore, we're
            also looking at ways to expand the definition of NEDA, creating
            further stringency or higher treatment goals to even have patients
            have relative control of things like brain atrophy, as well as
            including, potentially, blood biomarkers, for example, as a way to
            really look at how well under therapy patients’ subclinical disease
            activity is controlled as well.<sup>4,5</sup>
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            So, what I hear you saying is that by defining treatment goals,
            whether that be NEDA, or brain atrophy, or you included the
            potential future use of biomarkers, it really helps to inform one's
            treatment strategy. Is that correct?
          </p>
          <p><strong>Gina Cox</strong></p>
          <p>
            Yes. I think this also was discussed in our last section and really
            feeds into the idea of escalation vs the use of highly effective
            therapies. So, as we look to achieve our treatment goal of NEDA,
            many physicians are favoring early treatment escalation and/or the
            initial use of highly effective therapies as opposed to waiting for
            disease worsening and that accrual of permanent neurological
            disability.<sup>4,10</sup>
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            Thanks, Gina. So, Dr Brown, what factors define treatment paradigm
            in patients with MS?
          </p>
          <p><strong>Brandon Brown</strong></p>
          <p>
            Yeah, that's a great question. So let me just start by saying MS is
            a very heterogeneous and unpredictable disease. It's very difficult
            to predict how quickly patients will progress to a debilitating form
            of the disease. Therefore, the decision to initiate treatment and
            choice of treatment is impacted by many factors that vary across
            patients and their clinicians. So, personalized treatment selection
            for each individual MS patient is typically influenced by
            demographic, environmental, clinical, and prognostic factors, as
            well as treatment guidelines, and then perceived treatment burden,
            for example, route or frequency of administration, and, of course,
            access to treatments. Regardless of the presence or absence of these
            known risk factors, of course, it's critically important that
            patient preference and willingness to initiate disease-modifying
            therapy is taken into consideration as we know that adherence can be
            a critical point to optimize outcomes.<sup>9</sup>
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            So, you've talked about patient factors and preferences. What about
            treating physicians? What factors shape how they approach treating
            multiple sclerosis?
          </p>
          <p><strong>Brandon Brown</strong></p>
          <p>
            Great question. Research actually suggests that the characteristics
            of the prescriber may play an important role. So, factors that
            influence treatment decision for health care professionals include
            their understanding of disease progression, personal perspectives on
            prioritizing safety compared to efficacy, of course, peer networks,
            who they are in contact with on a regular basis, in addition to
            patient relapse history.<sup>14</sup> So, taking all of these
            factors into consideration, unfortunately, there's still research
            that many patients go for months or even years without being on
            disease-modifying therapies, likely impacted by adverse effects,
            treatment fatigue, perceived lack of efficacy, and practical
            barriers such as fear of injections.<sup>15</sup>
          </p>
          <p>
            Overall, I think the literature really suggests there remains a need
            to more accurately assess and predict disease activity and the
            impact of disease treatment strategies on disease progression to
            optimally treat these patients going forward.<sup>14</sup>
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            Thanks, Brandon. So, sounds like it's really important to both
            consider the patient's preferences, but also be very aware of our
            own preferences in terms of how we manage those with MS. Thanks a
            lot.
          </p>
          <p><strong>Brandon Brown</strong></p>
          <p>Absolutely.</p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            So, Dr Cox, how can HCPs address the challenges in managing patients
            with MS?
          </p>
          <p><strong>Gina Cox</strong></p>
          <p>
            Sure. So, today we've discussed the evolving knowledge of the
            pathophysiology across the spectrum of MS, as well as the elevated
            treatment goals that have been established, including NEDA as we
            talked about earlier. But how can we further improve outcomes for
            patients? Well, we've noted that the pressing challenge is really
            the ability to prognosticate individual patients and calculate their
            risk profile. This could really help to better inform early
            disease-modifying therapy selection.<sup>11</sup>
          </p>
          <p>
            One method we might be able to accomplish this is to continue to
            develop prognostic biomarkers that provide higher sensitivity for
            underlying inflammatory disease activity and help us understand risk
            for future progression. These biomarkers could also help us to
            monitor the treatment response and ultimately guide clinical
            decision making.<sup>3–5</sup>
          </p>
          <p>
            Another key challenge that we should certainly note is the need for
            better medications to address neurodegeneration and underlying
            disease progression. Until we have these therapies, we should
            continue working to advance the ways we can prognosticate disease at
            the individual patient level, and ultimately optimize therapy with
            our current DMTs.<sup>4</sup>
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            Thanks Dr Cox. So, it sounds like there's quite a number of things
            that are on the horizon that might help us manage our patients a
            little better, but also really to your point, monitor the underlying
            disease process. So, really exciting things ahead in the future
            management of MS patients.
          </p>
          <p>
            Thank you for listening to this podcast on managing multiple
            sclerosis. I hope you will explore the other resources on our
            website, MSHCPeducation.com, for health care providers who manage
            patients with MS.
          </p>
          <p class="date-job-code">
            <span class="date">2/23</span> <span class="job-code">254678</span>
          </p>
        </template>
      </PodcastPage>

      <RefsModal v-if="$store.state.showModal === 'references'">
        <template #refs-list>
          <ol class="refs-list">
            <li>
              Kantarci OH. Phases and phenotypes of multiple sclerosis.
              <em>Continuum (Minneap Minn)</em>. 2019;25(3):636-654.
              doi:10.1212/CON.0000000000000737
            </li>
            <li>
              Thompson AJ, Banwell BL, Barkhof F, et al. Diagnosis of multiple
              sclerosis: 2017 revisions of the McDonald criteria.
              <em>Lancet Neurol</em>. 2018;17(2):162-173.
              doi:10.1016/S1474-4422(17)30470-2
            </li>
            <li>
              Giovannoni G, Butzkueven H, Dhib-Jalbut S, et al. Brain health:
              time matters in multiple sclerosis.
              <em>Mult Scler Relat Disord</em>. 2016;9:S5-S48.
              doi:10.1016/j.msard.2016.07.003
            </li>
            <li>
              Dobson R, Giovannoni G. Multiple sclerosis – a review.
              <em> Eur J Neurol </em>. 2019;26(1):27-40. doi:10.1111/ene.13819
            </li>
            <li>
              Sandi D, Fricska-Nagy Z, Bencsik K, Vécsei L. Neurodegeneration in
              multiple sclerosis: symptoms of silent progression, biomarkers and
              neuroprotective therapy—kynurenines are important players.
              <em>Molecules</em>. 2021;26(11):1-28.
              doi:10.3390/molecules26113423
            </li>
            <li>
              Lakin L, Davis BE, Binns CC, Currie KM, Rensel MR. Comprehensive
              approach to management of multiple sclerosis: addressing invisible
              symptoms—a narrative review. <em>Neurol Ther</em>.
              2021;10(1):75-98. doi:10.1007/s40120-021-00239-2
            </li>
            <li>
              Cagol A, Schaedelin S, Barakovic M, et al. Association of brain
              atrophy with disease progression independent of relapse activity
              in patients with relapsing multiple sclerosis.
              <em>JAMA Neurol</em>. 2022;79(7):682-692.
              doi:10.1001/jamaneurol.2022.1025
            </li>
            <li>
              Lublin FD, Häring DA, Ganjgahi H, et al. How patients with
              multiple sclerosis acquire disability. <em>Brain</em>.
              2022;145(9):3147-3161. doi:10.1093/brain/awac016
            </li>
            <li>
              Filippi M, Amato MP, Centonze D, et al. Early use of high-efficacy
              disease‑modifying therapies makes the difference in people with
              multiple sclerosis: an expert opinion. <em>J Neurol</em>.
              2022;269(10):5382-5394. doi:10.1007/s00415-022-11193-w
            </li>
            <li>
              Casanova B, Quintanilla-Bordás C, Gascón F. Escalation vs. early
              intense therapy in multiple sclerosis. <em>J Pers Med</em>.
              2022;12(119):1-14. doi:10.3390/jpm12010119
            </li>
            <li>
              Cree BAC, Mares J, Hartung HP. Current therapeutic landscape in
              multiple sclerosis: an evolving treatment paradigm.
              <em> Curr Opin Neurol </em>. 2019;32(3):365-377.
              doi:10.1097/WCO.0000000000000700
            </li>
            <li>
              Ontaneda D, Tallantyre EC, Raza PC, et al. Determining the
              effectiveness of early intensive versus escalation approaches for
              the treatment of relapsing-remitting multiple sclerosis: the
              DELIVER-MS study protocol. <em>Contemp Clin Trials</em>.
              2020;95:106009. doi:10.1016/j.cct.2020.106009
            </li>
            <li>
              Ziemssen T, Engelmann U, Jahn S, et al. Rationale, design, and
              methods of a non-interventional study to establish safety,
              effectiveness, quality of life, cognition, health-related and work
              capacity data on alemtuzumab in multiple sclerosis patients in
              Germany (TREAT-MS). <em>BMC Neurol</em>. 2016;16(1):1-11.
              doi:10.1186/s12883-016-0629-9
            </li>
            <li>
              Cameron E, Rog D, McDonnell G, Overell J, Pearson O, French DP.
              Factors influencing multiple sclerosis disease-modifying treatment
              prescribing decisions in the United Kingdom: a qualitative
              interview study. <em>Mult Scler Relat Disord</em>.
              2019;27:378-382. doi:10.1016/j.msard.2018.11.023
            </li>
            <li>
              Fox RJ, Mehta R, Pham T, Park J, Wilson K, Bonafede M. Real-world
              disease-modifying therapy pathways from administrative claims data
              in patients with multiple sclerosis. <em>BMC Neurol</em>.
              2022;22(1):1-9. doi:10.1186/s12883-022-02738-7
            </li>
          </ol>
        </template>
      </RefsModal>
    </div>
  </div>
</template>

<script>
import PodcastPage from "@/components/PodcastPage";
import RefsModal from "@/components/RefsModal";

export default {
  name: "ManagingMsPodcast",
  components: {
    PodcastPage,
    RefsModal,
  },
  data() {
    return {
      id: this.$router.currentRoute.params.id,
      audio: {
        id: "management-audio1",
        title: "The Evolving Landscape of Managing MS Podcast",
        src: "assets/audio/earlyMS_and_impact.mp3",
        analyticsLabel: "The Evolving Landscape of Managing MS",
        desc: "Dr Jason Freeman, neurologist (Medical Director, Novartis), Dr Gina Cox, neuroimmunologist (Senior Medical Director, Novartis), and Dr Brandon Brown, clinical pharmacist (Medical Director, Novartis), discuss phenotypic classifications of MS and different treatment strategies for patients across the spectrum.",
      },
    };
  },
  methods: {
    showPdf: function (data) {
      this.$router.push({ name: data.src.split(".")[0] });

      this.$gtag.event("click", {
        event_category: "view_pdf",
        event_label: `${data.analyticsLabel}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.podcast-page {
  color: $black;
  background: rgb(255, 255, 255);
  position: relative;
}
.mesh {
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    pointer-events: none;
    background-image: url("~@/assets/img/video-bg.png");
    top: 0;
    right: 0;
  }
}

.tactic-btn {
  border: 1px solid $white;
  background: none;
  color: $white;
  padding: rem(10px) rem(25px);
  text-align: left;
  position: relative;
  width: 100%;
  min-height: rem(60px);
  display: block;
  margin-bottom: rem(20px);

  &:last-child {
    margin-bottom: 0px;
  }
}

.tactic-btn-label {
  float: left;
  font-size: rem(18px);
  font-weight: $font-weight-bold;
  width: calc(100% - 50px);
  position: absolute;
  top: 50%;
  left: rem(20px);
  transform: translate(0%, -50%);
}

.tactic-btn-icon {
  float: left;
  width: rem(20px);
  margin-left: rem(30px);
  position: absolute;
  top: 50%;
  right: rem(20px);
  transform: translate(0%, -50%);

  img {
    width: 100%;
  }
  &.launch-icon {
    width: rem(27px);
  }
}

:deep(.audio-title) {
  display: none !important;
}
</style>
