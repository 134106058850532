<template>
  <div id="covid-resources">
    <header>
      <InnerContent>
        <ButtonReturn to="home" colour="black" />
        <h1 class="title">COVID-19 Resources</h1>
      </InnerContent>
    </header>
    <section>
      <InnerContent>
        <div class="section-container intro-copy">
          <div class="column-50 left-text">
            <div class="text">
              This collection of resources was developed to share information
              and expert insights to support clinicians as they care for MS
              patients during the COVID-19 pandemic. View the COVID-19 version
              of the “Insights Into MS” information series, watch our expert
              videos, or listen to the audio versions and learn about COVID-19
              and MS care!
            </div>
          </div>
          <div class="column-50 virus-img">
            <img src="@/assets/img/resources-covid.png" alt="" />
          </div>
        </div>
      </InnerContent>
    </section>
    <section class="section covid" id="covid">
      <InnerContent>
        <div class="subheader video">
          <div class="icon video">
            <img src="@/assets/img/icon-video-black.svg" alt="Video" />
          </div>
          <h2>Video</h2>
        </div>
        <div class="section-container">
          <div class="column">
            <div class="resources">
              <h3 class="asset-title">
                Insights Into MS: COVID-19 and
                Disease&nbsp;Management&nbsp;(5:39)
              </h3>
              <router-link to="covid-video1">
                COVID-19 and Disease Management
              </router-link>
            </div>
          </div>
          <div class="column">
            <div class="resources">
              <h3 class="asset-title">
                The Impact of COVID-19 Pandemic on Patient&nbsp;Care&nbsp;(9:41)
              </h3>
              <router-link to="/telemedicine">Telemedicine in MS</router-link>
            </div>
          </div>
          <div class="column"></div>
        </div>
        <div class="section-container">
          <div class="column">
            <h3 class="asset-title" id="covidvideos">
              COVID-19, the Immune System, and Current Vaccines Video Series
            </h3>
          </div>
        </div>
        <div class="section-container">
          <div class="column">
            <ul class="resources-list">
              <li>
                <router-link
                  :to="{ name: 'covid-video', params: { id: 'video1' } }"
                  >Vaccine Effectiveness (2:25)</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'covid-video', params: { id: 'video2' } }"
                  >B-Cell Response (1:53)</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'covid-video', params: { id: 'video3' } }"
                  >B-Cell Depletion (2:20)</router-link
                >
              </li>
            </ul>
          </div>
          <div class="column">
            <ul class="resources-list">
              <li>
                <router-link
                  :to="{ name: 'covid-video', params: { id: 'video4' } }"
                  >Immune Response to Vaccine (2:30)</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'covid-video', params: { id: 'video5' } }"
                  >Innate Immune Response (2:55)</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'covid-video', params: { id: 'video6' } }"
                  >Demographics and Immune Response (2:46)
                </router-link>
              </li>
            </ul>
          </div>
          <div class="column">
            <ul class="resources-list">
              <li>
                <router-link
                  :to="{ name: 'covid-video', params: { id: 'video7' } }"
                  >Loss of Taste and Smell (3:38)</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'covid-video', params: { id: 'video8' } }"
                  >Presence of IgG and IgM (1:23)</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'covid-video', params: { id: 'video9' } }"
                  >Future of COVID-19 (2:40)</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'covid-video', params: { id: 'video10' } }"
                  >Vaccine Development (2:33)</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div class="subheader audio">
          <div class="icon audio">
            <img
              src="@/assets/img/icon-audio-black.svg"
              alt="Button plays audio file"
            />
          </div>
          <h2>Audio</h2>
        </div>
        <div class="section-container">
          <div class="column">
            <AudioPlayer :data="audio.covid[0]" :key="audio.covid[0].id" />
          </div>
        </div>
      </InnerContent>
    </section>

    <footer>
      <InnerContent>
        <p class="acr">MS, multiple sclerosis.</p>
      </InnerContent>
    </footer>
  </div>
</template>

<script>
import ButtonReturn from "@/components/ButtonReturn.vue";
import { videos } from "@/assets/covid-videos.js";

export default {
  name: "Resources",
  components: {
    ButtonReturn,
  },
  data() {
    return {
      videos: videos,
      audio: {
        covid: [
          {
            id: "covid-audio2",
            title: "Telemedicine in MS",
            src: "assets/audio/telemedicine.mp3",
            analyticsLabel: "COVID Resources: Telemedicine in MS",
          },
        ],
      },
      pdfs: {
        covid: [
          {
            src: "covid.pdf",
            analyticsLabel: "COVID-19: COVID and MS",
          },
        ],
      },
    };
  },
  methods: {
    showApp: function (app) {
      this.$gtag.event("click", {
        event_category: "launch_page",
        event_label: app.analyticsLabel,
      });

      let url =
        process.env.NODE_ENV === "development" ? app.urlDev : app.urlProd;

      window.open(url, "_self");
    },
    showPdf: function (data) {
      this.$router.push({ name: data.src.split(".")[0] });

      this.$gtag.event("click", {
        event_category: "view_pdf",
        event_label: `${data.analyticsLabel}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#covid-resources {
  width: 100%;
  position: relative;
}

:deep(.inner-content) {
  margin: 0 auto;
}
hr {
  margin: 3em 0;
}
.title {
  font-size: rem(46px);
  color: $blue;
  margin: rem(60px) 0 rem(20px) 0;

  @include breakpoint(xs) {
    font-size: rem(24px);
    line-height: rem(24px);
    margin: rem(20px) 0;
  }

  @include breakpoint(md) {
    font-size: rem(36px);
    line-height: rem(40px);
  }
}

h3 {
  font-size: 16px;
  margin-bottom: 1em;
}
.section-title {
  margin-bottom: rem(20px);
}

.subheader {
  margin-bottom: rem(20px);
  display: flex;
  align-items: center;

  .icon {
    margin-right: rem(15px);

    img {
      width: 100%;
    }

    &.app {
      width: rem(21px);
    }

    &.audio {
      width: rem(25px);
    }

    &.pdf {
      width: rem(21px);
    }

    &.video {
      width: rem(28px);
    }
  }
}

.intro-copy {
  width: 80%;
  font-size: rem(24px);
  line-height: rem(32px);
  margin-bottom: rem(20px);

  .left-text,
  .virus-img {
    width: 50%;
    img {
      width: 100%;
    }
    @include breakpoint(md) {
      width: 100%;
      font-size: rem(18px);
      line-height: rem(22px);
    }

    @include breakpoint(xs) {
      width: 100%;
      font-size: rem(16px);
      line-height: rem(22px);
      margin-bottom: 20px;
    }
  }

  .left-text {
    padding-right: 25px;
  }

  @include breakpoint(md) {
    width: 100%;
    font-size: rem(18px);
    line-height: rem(22px);
  }

  @include breakpoint(xs) {
    width: 100%;
    font-size: rem(16px);
    line-height: rem(22px);
    margin-bottom: 20px;
  }
}

.section {
  background: $grey;
  padding: rem(30px);
  padding-top: rem(50px);
  // margin-bottom: rem(15px);
}

.section-container {
  display: flex;
  // justify-content: space-between;
  align-items: flex-start;
  margin-top: 1em;
}

.column {
  width: 30%;
  margin-right: 3em;
}

.link {
  border: none;
  background: none;
  color: $black;
  display: flex;
  align-items: center;
  text-align: left;
  padding-left: 0;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.intro {
}
// .column-50 {
//   width: 50%;
//   &.virus-img {
//     text-align: center;
//     background: #222;
//     img {
//       max-height: 300px;
//     }
//   }
//   &.left-text {
//     height: 100%;
//     .text {
//       position: absolute;
//       top: 50%;
//       transform: translateY(-50%);
//       width: inherit;
//       padding-right: 4em;
//     }
//   }
// }

.img-container {
  width: 30%;

  &.white {
    background-color: $white;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.signs {
      img {
        width: 50%;
      }
    }

    &.progression {
      img {
        width: 90%;
      }
    }

    &.impact {
      img {
        width: 90%;
      }
    }

    &.care {
      img {
        width: 50%;
      }
    }
  }

  img {
    width: 100%;
  }
}

.resources {
  margin-bottom: rem(30px);
}

.resources-list {
  padding-left: 0;
  list-style-type: none;
  margin: 0;

  li {
    margin-bottom: rem(20px);
  }
}

a {
  color: $black;
  &:hover {
    text-decoration: none;
  }
}

:deep(.audio-title) {
  font-weight: $font-weight-reg;
  min-height: 35px;
  @include breakpoint(xs) {
    min-height: auto;
  }
}

.inner-wrapper {
  padding-bottom: rem(60px);
  background: linear-gradient(180deg, $grey 0%, $white 25%);
  position: relative;
}

@media (max-width: 1023px) {
}

@media screen and (max-width: 767px) {
  .section-container {
    display: block;
  }

  .section {
    padding-left: 0;
    padding-right: 0;
  }

  .img-container,
  .column {
    width: 100%;
  }

  .img-container {
    margin-bottom: rem(20px);
  }
}

@media (max-width: 500px) {
}
</style>
