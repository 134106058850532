<template>
  <footer id="footer" :class="bgColour">
    <div class="inner-content">
      <!-- <router-link class="refs-link" :to="getRefsRoute" :style="referencesStyle"
        >References</router-link
      > -->
      <button
        class="refs-link"
        @click="$store.toggleModal('references')"
        :style="referencesStyle"
      >
        References
      </button>
      <div class="contact-us">
        <div class="social-media">
          <b class="bold-title small">Follow Us</b>
          <a
            @click="
              $gtag.event('click', {
                event_category: 'go_to_twitter',
                event_label: 'Twitter',
              })
            "
            href="https://www.twitter.com/NovartisUSMed"
            target="_blank"
          >
            <img
              src="@/assets/img/sm-twitter.svg"
              alt="Twitter"
            />
          </a>
          <a
            @click="
              $gtag.event('click', {
                event_category: 'go_to_linkedin',
                event_label: 'LinkedIn',
              })
            "
            href="https://www.linkedin.com/company/novartis-us-medical/"
            target="_blank"
          >
            <img
              src="@/assets/img/sm-linkedin.svg"
              alt="LinkedIn"
            />
          </a>
        </div>
        <div class="contact-us-content">
          <b class="bold-title">Contact Us</b>
          <div class="sub-items">
            <span v-for="key of headerLinks" :key="key + '-menu'">
              <a
                :href="subItems[key].urlProd"
                @click="
                  $gtag.event('click', {
                    event_category: subItems[key].eventCategory,
                    event_label: subItems[key].title,
                  })
                "
                v-html="subItems[key].title"
                target="_blank"
              >
              </a>
              <img
                src="@/assets/img/icon-browser-launch-black.svg"
                alt="This link will open in a separate browser tab."
              />
            </span>
          </div>
          <p>
            You can reach us by Phone (1-888-669-6682),
            <a
              @click="
                $gtag.event('click', {
                  event_category: 'go_to_email',
                  event_label: 'Email',
                })
              "
              href="https://medinfo.novartispharmaceuticals.com/EMedSubmitInquiry#email"
              target="_blank"
              >Email</a
            >, or
            <a
              @click="
                $gtag.event('click', {
                  event_category: 'go_to_live_chat',
                  event_label: 'Live Chat',
                })
              "
              href="https://medinfo.novartispharmaceuticals.com/EMedChat"
              target="_blank"
              >Live Chat</a
            >
          </p>
          <p>Monday-Friday, 8:30 AM-5:00 PM (ET)</p>
        </div>
      </div>

      <div class="location">
        <b class="bold-title">Novartis Pharmaceuticals Corporation</b>
        <p>One Health Plaza</p>
        <p>East Hanover, NJ 07936-1080</p>
      </div>

      <div class="sitemap">
        <a
          href="/sitemap"
          @click="
            $gtag.event('click', {
              event_category: 'launch_menu_item',
              event_label: 'Site Map',
            })
          "
          >Site Map</a
        >
      </div>

      <span v-show="showTerms" class="copy-terms">
        Use of website is governed by the
        <a
          @click="
            $gtag.event('click', {
              event_category: 'go_to_terms',
              event_label: 'Terms of Use',
            })
          "
          href="https://www.novartis.us/terms-use"
          target="_blank"
          aria-describedby="new-window-6"
          >Terms of Use</a
        >
        and
        <a
          @click="
            $gtag.event('click', {
              event_category: 'go_to_privacy',
              event_label: 'Privacy Policy',
            })
          "
          href="https://www.novartis.us/privacy-policy"
          target="_blank"
          aria-describedby="new-window-6"
          >Privacy Policy</a
        >.
      </span>

      <div class="legal-copy">
        <div class="copyright">
          <span
            >&copy; {{ new Date().getFullYear() }} Novartis Pharmaceuticals
            Corporation.</span
          >
          <span class="linebreak"> All rights reserved.</span>
        </div>
        <div class="date-job-code">
          <span class="date">6/24</span>
          <span class="jobcode" v-html="jobCode"></span>
        </div>
      </div>
      <div hidden>
        <span id="new-window-6"
          >Select to launch an external site in a new tab.</span
        >
      </div>
    </div>
  </footer>
</template>

<script>
import { ItemLinkTypes } from "@/assets/constants.js";

export default {
  name: "AppFooter",
  props: {
    bgColour: {
      type: String,
      default: "white",
    },
    showReferences: {
      type: Boolean,
      default: false,
    },
    showTerms: {
      type: Boolean,
      default: false,
    },
    jobCode: {
      type: String,
      default: "XXX - ######",
    },
    subOrder: {
      type: Array,
      default: () => ["reportAdverse", "submitMedical"],
    },
  },
  data() {
    return {
      subItems: {
        reportAdverse: {
          type: ItemLinkTypes.EXTERNAL,
          ariaDescribedBy: "Report adverse event",
          title: "Report adverse event",
          urlDev: "https://www.report.novartis.com/",
          urlProd: "https://www.report.novartis.com/",
        },
        submitMedical: {
          type: ItemLinkTypes.EXTERNAL,
          ariaDescribedBy: "Submit medical inquiry",
          title: "Submit medical inquiry",
          urlDev:
            "https://medinfo.novartispharmaceuticals.com/EMedSubmitInquiry",
          urlProd:
            "https://medinfo.novartispharmaceuticals.com/EMedSubmitInquiry",
        },
      },
    };
  },
  computed: {
    headerLinks() {
      return this.subOrder.filter(
        (so) => so === "reportAdverse" || so === "submitMedical"
      );
    },

    getRefsRoute: function () {
      let refsRoute = this.$router.resolve({ name: "references" });

      return refsRoute.resolved.matched.length > 0
        ? { name: "references" }
        : "/";
    },
    referencesStyle: function () {
      return this.showReferences ? {} : { display: "none" };
    },
    env() {
      return process.env.NODE_ENV;
    },
  },
  methods: {
    logEvent(category, label) {
      this.$gtag.event("click", {
        event_category: category,
        event_label: label,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#footer {
  width: 100%;
  // padding: 20px 0;
  min-height: rem(100px);
  background: white;
  font-size: rem(14px);
  // z-index: 100;
}

a {
  font-weight: $font-weight-bold;
  color: $black;
}

.inner-content {
  margin: 0 auto;
  padding: rem(25px);
  display: flex;
  flex-direction: column;
}

.refs-link {
  width: fit-content;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  text-align: left;
  font-weight: $font-weight-bold;
  text-decoration: underline;
}
.bold-title {
  display: block;
  margin-bottom: 0.5;
  &.small {
    font-size: 0.7;
  }
}
.refs-link,
.copy-terms,
.contact-us,
.sitemap,
.location {
  margin-bottom: rem(15px);
  display: inline-block;
  h4 {
    margin-bottom: rem(2px);
  }
  p {
    line-height: rem(18px);
  }
}

.social-media {
  float: right;
  display: inline-block;
  text-align: right;
  font-size: 10px;
  // font-weight: $font-weight-bold;

  img {
    width: 20px;
    height: auto;
  }

  a {
    margin-right: 7px;
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 1024px) {
    float: left;
    text-align: left;
    margin-bottom: 10px;
  }
}

.contact-us {
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
}

.sitemap {
  font-size: 20px;
  line-height: 20px;
  a {
    color: #0460a9;
  }
}

.legal-copy {
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}

.date-job-code {
  .date {
    margin-right: rem(20px);
  }
}

@media (max-width: 1024px) {
  .copyright {
    margin-bottom: rem(15px);
  }

  .legal-copy {
    white-space: normal;
    display: flex;
    flex-direction: column;

    .copyright,
    .date {
      margin-right: rem(5px);
    }
  }

  .date-job-code {
    .date {
      margin-right: rem(20px);
    }
  }
}

.sub-items {
  margin: 10px 0;

  span {
    display: block;
    margin: 5px 0;
  }
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      color: $black;
    }
  }
  img {
    width: 14px;
    margin-left: 10px;
  }
}

// @media (max-width: 600px) {
//   .date-job-code {
//     width: 90%;
//     display: flex;
//     justify-content: space-between;
//   }
// }

// @media screen and (min-width: 1681px) {
//   .inner-content {
//     max-width: 1440px;
//   }
// }
</style>
