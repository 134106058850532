<template>
  <div :id="`${id}`" class="ms-mod-video mesh">
    <div class="inner-wrapper">
      <VideoPage>
        <template #header>{{ video.title }}</template>
        <template #video-copy>{{ video.desc }}</template>
        <template #video-content>
          <KalturaVideo :videoData="video.kalturaData" />
        </template>
      </VideoPage>
    </div>
  </div>
</template>

<script>
import KalturaVideo from "@/components/KalturaVideo";
import VideoPage from "@/components/VideoPage";

export default {
  name: "Telemedicine",
  components: {
    KalturaVideo,
    VideoPage,
  },
  data() {
    return {
      id: this.$router.currentRoute.params.id,
      video: {
        title: "Telemedicine in MS",
        desc: "",
        kalturaData: {
          eventLabel: "COVID-19 Video: Telemedicine in MS",
          id: 2076321,
          uiConfId: "45829591",
          entryId: "1_7aopr7ma",
        },
      },
      pdfs: {
        covid: [
          {
            src: "covid.pdf",
            analyticsLabel: "COVID-19: COVID and MS",
          },
        ],
      },
    };
  },
  methods: {
    showPdf: function (data) {
      this.$router.push({ name: data.src.split(".")[0] });

      this.$gtag.event("click", {
        event_category: "view_pdf",
        event_label: `${data.analyticsLabel}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ms-mod-video {
  color: $black;
  background: rgb(255, 255, 255);
  position: relative;
}
.mesh {
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    pointer-events: none;
    background-image: url("~@/assets/img/video-bg.png");
    top: 0;
    right: 0;
  }
}

.tactic-btn {
  border: 1px solid $white;
  background: none;
  color: $white;
  padding: rem(10px) rem(25px);
  text-align: left;
  position: relative;
  width: 100%;
  min-height: rem(60px);
  display: block;
  margin-bottom: rem(20px);

  &:last-child {
    margin-bottom: 0px;
  }
}

.tactic-btn-label {
  float: left;
  font-size: rem(18px);
  font-weight: $font-weight-bold;
  width: calc(100% - 50px);
  position: absolute;
  top: 50%;
  left: rem(20px);
  transform: translate(0%, -50%);
}

.tactic-btn-icon {
  float: left;
  width: rem(20px);
  margin-left: rem(30px);
  position: absolute;
  top: 50%;
  right: rem(20px);
  transform: translate(0%, -50%);

  img {
    width: 100%;
  }
  &.launch-icon {
    width: rem(27px);
  }
}
</style>
