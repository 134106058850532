<template>
  <InnerContent>
    <header>
      <div class="back-button-row">
        <slot name="back-button">
          <ButtonReturn :to="backNav" colour="black" />
        </slot>
      </div>
      <div class="title-page-row">
        <div class="title-page">
          <!-- <h2 class="legal-note">US Medical Affairs</h2> -->
          <slot name="breadcrumb"></slot>
          <h1><slot name="header">Tactic title</slot></h1>
        </div>
        <p class="tactic-copy">
          <slot name="video-copy">Video copy</slot>
        </p>
      </div>
    </header>

    <div
      class="video-container"
      oncontextmenu="return false"
      onselectstart="return false"
      ondragstart="return false"
    >
      <slot name="video-content">
        <p>Video content.</p>
      </slot>
    </div>
    <div class="transcript-container">
      <div
        tabindex="0"
        class="transcript-button"
        role="button"
        :aria-expanded="[showTranscript ? true : false]"
        aria-controls="transcript"
        @click="toggleTranscript"
        v-on:keyup.enter="toggleTranscript"
      >
        <span>View transcript</span>
        <span class="open" v-show="showTranscript === false">+</span>
        <span class="close" v-show="showTranscript === true">-</span>
      </div>
      <transition name="fade">
        <div
          tabindex="0"
          id="transcript"
          class="transcript"
          v-show="showTranscript"
        >
          <slot name="transcript"></slot>
        </div>
      </transition>
      <div class="transcript-grad" v-show="showTranscript"></div>
    </div>    
    <div class="related-content-container">
      <slot name="related-content"></slot>
    </div>
  </InnerContent>
</template>

<script>
import ButtonReturn from "@/components/ButtonReturn.vue";

export default {
  name: "VideoChapterizedPage",
  components: {
    ButtonReturn,
  },
  props: {
    customContent: {
      type: Boolean,
      default: false,
    },
    backNav: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      showTranscript: false,
    };
  },

  methods: {
    toggleTranscript() {
      this.showTranscript = !this.showTranscript;
    },
  },
  mounted() {
    this.$store.state.showRefs = true;
  },
  beforeDestroy() {
    this.$store.state.showRefs = false;
  },
};
</script>

<style lang="scss" scoped>
header {
  margin: 0 0 25px;
}

h1 {
  font-size: rem(40px);
  margin: 0 0 rem(20px) 0;
  color: $black;
  line-height: 1;
}

.subheader {
  max-width: 783px;
  color: $black;
  margin: 0;
  margin-bottom: rem(45px);
  line-height: 1.3;
}

.title-page-row {
  margin-top: 1em;
  width: 100%;
}

.back-button-row {
  width: 100%;
  display: inline-block;
}

:deep(.video-container) {
  video {
    width: 100%;
  }
}

:deep(.tactic-copy) {
  font-size: rem(21px);
  line-height: rem(26px);
}

:deep(.preview-img) {
  width: 100%;
  margin-bottom: rem(25px);

  img {
    width: 100%;
  }
}

.related-content-container {
  margin: 40px 0;
  :deep(h2) {
    font-size: rem(40px);
  }

  :deep(ul) {
    list-style: none;
    padding-left: 0;
    margin-bottom: 40px;

    li {
      font-size: rem(26px);
      margin: 26px 0;

      a,
      .pdf-button {
        color: $black;
        display: flex;
        align-items: center;
        padding: 0;
        &:hover {
          text-decoration: none;
        }
        img {
          width: 12px * 2;
          height: 19px * 2;
          margin-right: 20px;
          &.thumbnail {
            width: 86px;
            height: auto;
            border: 1px solid $grey-darkest;
          }
        }
      }

      .pdf-button {
        background: transparent;
        border: none;
        text-decoration: underline;
        text-align: left;
        padding: 0;
      }
    }
  }
}

.transcript-container {
  background-color: $white;
  position: relative;
  margin-top: 40px;

  .transcript-grad {
    position: absolute;
    bottom: 1px;
    left: 40px;
    width: calc(100% - 80px);
    height: 40px;
    background-image: linear-gradient(transparent, #fff 95%);
    pointer-events: none;
  }
  .transcript-button {
    background-color: $white;
    border: 1px solid $black;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    &:focus {
      outline: 4px solid #ffd086;
    }

    .open,
    .close {
      font-size: rem(24px);
    }
  }
  .transcript {
    overflow: scroll;
    max-height: 300px;
    padding: 20px 40px;
    margin-bottom: 40px;
    border: 1px solid $black;
    border-top: 0;
    &:focus {
      outline: 4px solid #ffd086;
    }

    :deep(p) {
      margin: 20px 0;
      &.date-job-code {
        padding: 0;
        margin: 40px 0;
        text-align: right;
        .date {
          margin-right: 20px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .breakpoint-landscape {
    display: block;
  }

  .breakpoint-portrait {
    display: none;
  }
}

@media (max-width: 767px) {
  .breakpoint-landscape {
    display: none;
  }

  .breakpoint-portrait {
    display: block;
  }

  .content-container {
    display: block;
  }

  h1 {
    font-size: rem(30px);
    margin: 0 0 rem(20px) 0;
    line-height: 1;
  }

  :deep(.tactic-copy) {
    font-size: rem(15px);
    line-height: rem(18px);
  }

  .related-content-container {
    :deep(h2) {
      font-size: rem(22px);
    }

    :deep(ul) {
      li {
        font-size: rem(14px);
        margin: 10px 0;
        a {
          img {
            // width: 27px;
            // height: 22px;
            margin-right: 14px;
            &.thumbnail {
              width: 66px;
              height: auto;
              border: 1px solid $grey-darkest;
            }
          }
        }

        .pdf-button {
          img {
            margin-right: 14px;

            &.thumbnail {
              width: 66px;
              height: auto;
              border: 1px solid $grey-darkest;
            }
          }
        }
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
