<template>
  <div class="audio-player" :id="`audio-${data.id}`">
    <b class="audio-title" v-html="data.title"></b>
    <div class="audio-element-container">
      <vue-plyr class="player" ref="plyr">
        <audio crossorigin playsinline :id="data.id" tabindex="-1">
          <source :src="require('@/' + data.src)" type="audio/mp3" />
        </audio>
      </vue-plyr>
    </div>
  </div>
</template>

<script>
export default {
  name: "AudioPlayer",
  props: {
    data: {
      type: [Object, String],
    },
  },
  mounted() {
    this.$refs.plyr.player.on("playing", () => {
      let temp = document.getElementsByTagName("audio");
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].id !== this.data.id) {
          temp[i].pause();
        }
      }
      if (this.$refs.plyr.player.currentTime === 0) {
        this.$gtag.event("click", {
          event_category: "play_audio",
          event_label: this.data.analyticsLabel,
        });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.audio-player {
  width: 100%;
  margin-bottom: rem(25px);
}

.audio-title {
  margin-bottom: rem(12px);
  display: inline-block;
  font-weight: $font-weight-reg;
}

:deep() {
  .plyr.plyr__tab-focus:focus {
    box-shadow: 0 0 0 3px #ffd086;
    outline: none;
  }
}

:deep() {
  .plyr input {
    &::-ms-thumb {
      background-color: white;
    }
  }
}
:deep() {
  .plyr--full-ui input[type="range"] {
    &::-ms-fill-lower {
      color: white;
    }
  }
}
</style>
