<template>
  <button class="btn-back-to-top" @click="goTop">
    <div class="icon">
      <img
        src="@/assets/img/arrow-up-icon.svg"
        alt="Back to top button - Select to go back to the top of the page."
      />
    </div>
  </button>
</template>

<script>
export default {
  name: "BtnTop",
  components: {},
  methods: {
    goTop: function () {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

      // this.$analytics.logEvent('scroll_to_top', { screen: `${this.$router.currentRoute.name}` });

      this.$gtag.event("click", {
        event_category: "scroll_to_top",
        event_label: "scroll to top",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-back-to-top {
  position: fixed;
  bottom: rem(110px);
  right: rem(50px);
  z-index: 100;
  display: inline-block;
  padding: 10px 15px;
  border: none;
  background: $blue-card1;
  color: $white;
}

.icon {
  display: block;
  max-width: 30px;

  img {
    display: block;
    width: 100%;
  }
}
</style>
