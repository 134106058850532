<template>
  <ul class="accordion-list">
    <li
      v-for="item of items"
      :key="item.id"
      :class="item"
      class="accordion-item"
      >
      <!-- IE11 Issue: Can't trigger events nested within button element 
      so had to turn button element to div. Also adjusted the arrow icon to be a button element so it can be tabbed through. 
      Not ideal for accessibility but cannot change now from eMed submission. -->
      <div class="accordion-link-container">
        <a :href="env === 'development' ? menuItems.home[item.id].urlDev : menuItems.home[item.id].urlProd" 
          @click="logEvent('launch_tactic', menuItems.home[item.id].title)"
          class="accordion-link">
          <slot :name="`accordion-${item.id}-link-label`">
            Toggle
          </slot>
        </a>
        <button v-show="!isExpanded(item.id)" class="accordion-button-icon" @click="toggleItem(item)">
          <slot name="accordion-button-icon-expand">
              <img src="@/assets/img/icon-menu-arrow.svg" alt="">
          </slot>
        </button>
        <button v-show="isExpanded(item.id)" class="accordion-button-icon up" @click="toggleItem(item)">
          <slot name="accordion-button-icon-collapse">
              <img src="@/assets/img/icon-menu-arrow.svg" alt="">
          </slot>
        </button>
      </div>
      <transition
        name="accordion-expand"
        @enter="enter"
        @after-enter="afterEnter"
        @leave="leave"
        >
        <div v-if="isExpanded(item.id)" class="accordion-item-content">
          <slot :name="`accordion-${item.id}-content`">
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Beatae tempore ut facere aliquid, dolores, dicta ab porro sint totam molestias fuga, laboriosam perferendis exercitationem tenetur expedita vero accusamus repudiandae? Exercitationem amet commodi maxime, laborum distinctio nihil quisquam! Officiis voluptatum, deserunt, molestiae, aspernatur eveniet facere magni ipsa sapiente architecto consequatur delectus.</p>
          </slot>
        </div>
      </transition>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'AccordionMenus',
  props: {
    url: {
      type: Object,
      default: () => {}
    },
    items: {
      type: Array,
      default: () => ['item1', 'item2', 'item3'],
    },
    menuItems: {
      type: Object,
      default: () => {}
    },
    defaultItem: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      expandedItem: '',
    };
  },
  watch: {
    defaultItem: function(newVal) {
      this.expandOnMount(newVal);
    }
  },
  computed: {
    env() {
      return process.env.NODE_ENV;
    }
  },
  methods: {
    expandOnMount(itemId) {
      this.expandedItem = itemId;
    },
    isExpanded(itemId) {
      return this.expandedItem === itemId;
    },
    logEvent(category, label) {
      this.$gtag.event('click', {'event_category': category,'event_label': label})
    },
    toggleItem(item) {
      this.expandedItem = this.expandedItem === item.id ? '' : item.id;

      if (this.expandedItem) {
        this.$gtag.event('click', {'event_category': 'expand_accordion', 'event_label': `${item.label}`})
      }
    },
    enter(el) {
      const $el = el;
      const { width } = getComputedStyle(el);

      $el.style.width = width;
      $el.style.position = 'absolute';
      $el.style.visibility = 'hidden';
      $el.style.height = 'auto';

      const { height } = getComputedStyle(el);

      $el.style.width = 'auto';
      $el.style.position = 'static';
      $el.style.visibility = 'visible';
      $el.style.height = 0;

      // Force repaint to make sure the
      // animation is triggered correctly.
      // eslint-disable-next-line no-unused-expressions
      getComputedStyle($el).height;

      requestAnimationFrame(() => {
        $el.style.height = height;
      });
    },
    afterEnter(el) {
      const $el = el;
      $el.style.height = 'auto';
    },
    leave(el) {
      const $el = el;
      const { height } = getComputedStyle($el);
      $el.style.height = height;

      // Force repaint to make sure the
      // animation is triggered correctly.
      // eslint-disable-next-line no-unused-expressions
      getComputedStyle($el).height;

      requestAnimationFrame(() => {
        $el.style.height = 0;
      });
    },
  },
  mounted() {}
};
</script>

<style lang="scss">
  .accordion-list {
    list-style: none;
    margin: 0;
    padding: 0;
    // @include breakpoint(lg) {
    //   display: none;
    // }

    ul {
      li {
        list-style-type: disc;
      }
    }
  }

  .accordion-item {
    // margin: 0 0 rem(10px) 0;
    margin: 0;
    border-bottom: 1px solid $grey-dark;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .accordion-link-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    padding: rem(20px) rem(10px) rem(20px) rem(0px);
    line-height: 1;
    cursor: pointer;
  }

  .accordion-link {
    padding-right: 20px;
    width: 80%;
    font-weight: $font-weight-bold;
    color: $black;
    text-decoration: none;

    h2 {
      font-size: rem(22px);
      margin: 0;
    }

    @media (max-height: 510px), (max-width: 600px) {
      h2 {
        font-size: rem(20px);
      }
    }
  }

  .accordion-button-icon {
    width: rem(35px);
    flex-shrink: 0;
    border: none;
    cursor: pointer;
    padding: 5px;
    background: none;
    img {
      width: 100%;
    }

    &.up {
      transform: rotate(180deg);
    }
  }

  .accordion-item-content {
    padding: rem(0px) rem(15px) rem(15px) rem(25px);
  }

  .accordion-expand-enter-active,
  .accordion-expand-leave-active {
    box-sizing: content-box;
    transition: all 0.3s;
    overflow: hidden;
  }

  .accordion-expand-enter,
  .accordion-expand-leave-to {
    height: 0;
    padding: 0;
    opacity: 0;
  }
</style>
