<template>
  <div class="pdf-page">
    <div class="inner-wrapper">
      <PdfViewer pdfName="early-treatment">
        <template #header
          >Insights Into MS: Timing of Treatment Initiation in MS
        </template>
      </PdfViewer>
    </div>
  </div>
</template>

<script>
import PdfViewer from "@/components/PdfViewer";

export default {
  name: "EarlyTreatment",
  components: {
    PdfViewer,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.pdf-page {
  color: $black;
  background-color: $grey-light;
  position: relative;
}
</style>
