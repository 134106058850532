<template>
  <article class="tactic-card">
    <div class="card-image-container">
      <figure
        v-if="card.image.fill"
        class="card-image-fill"
        :style="{
          backgroundImage: 'url(' + require('@/' + card.image.src + '') + ')',
        }"
        :alt="card.image.alt"
      ></figure>
      <figure v-else class="card-image">
        <img :src="require(`@/${card.image.src}`)" :alt="card.image.alt" />
      </figure>
    </div>
    <div class="card-info">
      <div class="card-copy">
        <h3 class="card-title" v-html="card.title"></h3>
        <p class="card-desc" v-html="card.desc"></p>
      </div>
      <button @click="$emit('button-clicked')" class="card-btn cf">
        <div class="card-btn-content">
          <span v-html="card.button.label" class="card-btn-label"> </span>
          <span
            v-if="tacticId === 'musicTherapy'"
            class="card-btn-icon launch-icon"
          >
            <img src="@/assets/img/icon-browser-launch.svg" alt="" />
          </span>
          <span v-else class="card-btn-icon">
            <img src="@/assets/img/ButtonArrow.svg" alt="" />
          </span>
        </div>
      </button>
    </div>
  </article>
</template>

<script>
export default {
  name: "TacticCard",
  props: {
    tacticId: {
      type: String,
    },
    card: {
      type: Object,
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.tactic-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: rem(600px);
  background: $grey-darkest;
  color: $white;

  @media screen and (min-width: 1681px) {
    height: rem(575px);
  }
}

.card-image-container {
  margin: 0 auto;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex: 2;

  @media screen and (min-width: 1681px) {
    height: 40%;
  }

  &:before {
    content: "";
    position: absolute;
    top: 25px;
    left: 0;
    width: 100%;
    height: calc(100% - 48px);
    background: white;
    opacity: 0.5;
    z-index: 0;
  }
}

.card-image {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  padding: rem(10px);
  text-align: center;
  position: relative;
  z-index: 1;

  img {
    height: 100%;
  }
}

.card-image-fill {
  height: 75%;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
  background-size: cover;
  background-position: center;

  @media (min-width: 1680px) {
    // margin-top: rem(5px);
    height: 80%;
  }
}

.card-title {
  margin-bottom: rem(10px);
  // height: rem(70px);
  position: relative;
  font-size: rem(20px);
  line-height: rem(20px);
  min-height: rem(20px);
  background-color: $white;
  color: $blue;
  text-transform: uppercase;
  padding: 12px 10px 10px;
  display: inline-flex;
  align-items: center;
  font-weight: $font-weight-med;
}

.card-info {
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 rem(20px);
  padding-bottom: rem(15px);
  height: 60%;
  position: relative;
}

.card-desc {
  margin-top: rem(20px);
  font-size: rem(14px);
  line-height: rem(20px);
}

.card-btn {
  border: 1px solid $white;
  background: none;
  color: $white;
  padding: rem(10px);
  text-align: left;
  position: relative;
}

.card-btn-content {
  min-height: 60px;
}

.card-btn-label {
  float: left;
  font-size: rem(18px);
  font-weight: $font-weight-bold;
  width: calc(100% - 50px);
  position: absolute;
  top: 50%;
  left: rem(10px);
  transform: translate(0%, -50%);
}

.card-btn-icon {
  float: left;
  width: rem(20px);
  margin-left: rem(30px);
  position: absolute;
  top: 50%;
  right: rem(10px);
  transform: translate(0%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }
  &.launch-icon {
    width: rem(27px);
  }
}

@media (max-width: 767px) {
  .card-title {
    min-height: rem(0px);
  }
}
</style>
