<template>
  <button class="launch-btn cf" :class="colour">
    <div class="launch-btn-content">
      <span
        class="launch-btn-label"
      >
        <slot name="label">Button label</slot>
      </span>
      <span v-if="icon === 'launch'" class="launch-btn-icon launch-icon">
        <img src="@/assets/img/icon-browser-launch.svg" alt="">
      </span>
      <span v-if="icon === 'arrow'" class="launch-btn-icon">
        <img src="@/assets/img/ButtonArrow.svg" alt="">
      </span>
    </div>
  </button>
</template>

<script>
export default {
  name: 'ButtonLaunch',
  props: {
    icon: {
      type: String,
      default: 'arrow'
    },
    colour: {
      type: String,
      default: ''
    }
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.launch-btn {
  border: 1px solid $black;
  background: $black;
  color: $white;
  padding: rem(10px);
  text-align: left;
  position: relative;
  width: 100%;

  &.blue {
    border: 1px solid $blue;
    background: $blue;
  }

  &.blue-dark {
    border: 1px solid $blue-card1;
    background: $blue-card1;
  }

  &.orange {
    border: 1px solid $orange;
    background: $orange;
  }
}

.launch-btn-content {
  min-height: 60px;
}

.launch-btn-label {
  float: left;
  font-size: rem(18px);
  font-weight: $font-weight-bold;
  width: calc(100% - 50px);
  position: absolute;
  top: 50%;
  left: rem(10px);
  transform: translate(0%, -50%);
}

.launch-btn-icon {
  float: left;
  width: rem(20px);
  margin-left: rem(30px);
  position: absolute;
  top: 50%;
  right: rem(10px);
  transform: translate(0%, -50%);

  img {
    width: 100%;
  }
  &.launch-icon {
    width: rem(27px);
  }
}
</style>
