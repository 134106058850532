<template>
  <div :id="`${id}`" class="podcast-page mesh">
    <div class="inner-wrapper">
      <PodcastPage>
        <template #header>{{ audio.title }}</template>
        <template #podcast-asset><img src="~@/assets/img/pregnancy-management-podcast.png" alt="Sound waves behind a pregnant woman" /></template>
        <template #podcast-copy>{{ audio.desc }}</template>
        <template #podcast-content>
          <AudioPlayer :data="audio" :key="audio.id" />
        </template>

        <template #transcript>          
          <p><strong>Jason Freeman</strong></p>
          <p>This podcast is sponsored by Novartis Pharmaceuticals Corporation. The content provided herein is for your background and educational purposes only. The material is for your sole use and may not be altered or further disseminated in any fashion for further use.</p>
          <p>Thanks for joining, everyone. My name is Dr Jason Freeman; I&rsquo;m a neurologist and Medical Director with Novartis Pharmaceuticals. And for today&rsquo;s topic, it&rsquo;s my pleasure to welcome our guest Dr Ali Chappell, one of our medical science liaisons who has a PhD and background in reproductive endocrinology.</p>
          <p>Today, we&rsquo;ll be talking about pregnancy management in patients with multiple sclerosis, or MS. MS is a leading cause of nontraumatic disability in the developed world and is usually diagnosed between the ages of 20 and 50,<sup>1</sup> which includes women of childbearing age.</p>
          <p>So, Dr Chappell, let&rsquo;s start with a discussion of timing. When should physicians raise the topic of pregnancy and family planning in women who have been diagnosed with MS?</p>
          <p><strong>Ali Chappell</strong></p>
          <p>Thank you, Dr Freeman, it is a pleasure to be here today.</p>
          <p>So this is a great question, when to initiate patient discussions around family planning, and it&rsquo;s very important.</p>
          <p>First of all, in all women of childbearing age, the discussions around family planning and treatment courses both before and during pregnancy as well as postpartum should happen proactively and ideally shortly after diagnosis. Now, there are a few reasons for this timing, and this really is regardless of a woman's plans to grow her family at the time of diagnosis.</p>
          <p>First, women may be worried about fertility. They may be worried their MS is going to impact pregnancy complications or have risks to the baby. They may feel like they have to choose between managing their disease and choosing a DMT that might have risks to the baby. There's a lot of literature on pregnancy and MS, and so it's important for clinicians to provide clear information to help patients understand the impact of their disease on pregnancy and childbirth.<sup>2</sup></p>
          <p>In addition, planning should consider current disease activity as well as medications because MS management around pregnancy really covers starting, stopping, and switching medications before or around the time of conception.<sup>3</sup></p>
          <p>Finally, it&rsquo;s important to remember that not all pregnancies are planned. So patients should be counseled on contraception, and they should be explained the risks of treatment.</p>
          <p><strong>Jason Freeman</strong></p>
          <p>Thank you, Dr Chappell. Now let&rsquo;s dig a little deeper into these issues. What are the potential risks associated with a diagnosis of MS to the mother during pregnancy and to the fetus?</p>
          <p><strong>Ali Chappell</strong></p>
          <p>So the good news is having MS does not affect fertility rates or the risk of miscarriage. In addition, becoming pregnant while having MS has not been shown to accelerate disability progression.<sup>4,5</sup></p>
          <p>So back in 2018, there was a large study of 5 million pregnancies&mdash;about 4000 of which were women with MS. And they found that just having MS did not affect the risk of adverse pregnancy outcomes, such as likelihood of a caesarean section, poor fetal growth, preeclampsia, chorioamnionitis, or still births.<sup>6</sup></p>
          <p>In addition, disease activity before delivery did not predict adverse outcomes,<sup>6</sup> but there are some risks that should be explained to women diagnosed with MS who are of childbearing age. First, there&rsquo;s a slightly increased risk of MS in children who have a family member with MS. For example, children of a parent with MS have a 3% risk of also going on to develop MS, which is roughly 3 times the rate in the general population.<sup>7</sup></p>
          <p>Also, women with MS are more likely to experience infections during delivery and to have preterm deliveries.<sup>6</sup> The most common infections are urinary and respiratory tract infections, so women should be closely monitored for infections during the perinatal period.<sup>6</sup></p>
          <p><strong>Jason Freeman</strong></p>
          <p>Thank you for these insights. Let&rsquo;s talk about treatment in the pre- and perinatal stages. What are the recommended guidelines and what risks should be explained to patients?</p>
          <p><strong>Ali Chappell</strong></p>
          <p>Great question. You know, the discussion around family planning and patients with MS is a delicate balance between prioritizing a safe and healthy pregnancy and managing disease progression. And not all treatments have the same risks.</p>
          <p>Some DMTs, or disease-modifying therapies, are contraindicated in pregnancy. They&rsquo;re considered dangerous to the fetus and require extra diligence around planning the pregnancy, including taking contraception.<sup>3,5</sup></p>
          <p>In addition to posing a risk to the fetus, some treatments are associated with an increased risk of disease rebound after stopping treatments for a few months. Patients on these treatments should consider switching before trying to conceive rather than stopping treatment at conception.<sup>3,5</sup></p>
          <p>In all cases, patients with MS who become pregnant should let their doctor know right away and shouldn&rsquo;t make changes to treatment without consulting their doctor first.<sup>5</sup></p>
          <p>In the case of a relapse during pregnancy, short courses of corticosteroids may be used in the second and third trimester, but this should only be used for severe relapses and should not be used in the first trimester.<sup>5,8</sup></p>
          <p><strong>Jason Freeman</strong></p>
          <p>That leads us to the third reason you mentioned about why it&rsquo;s important to discuss family planning with your patients as early as possible because not all pregnancies are planned. Are there any particular considerations for these cases that we haven&rsquo;t already discussed?</p>
          <p><strong>Ali Chappell</strong></p>
          <p>Absolutely! So first, the rate of unplanned pregnancy in patients with MS is 32%, so it&rsquo;s common; it&rsquo;s about 1 in 3 patients.<sup>9</sup> Unplanned pregnancies were more common in younger, unmarried women,<sup>9</sup> and so this is a demographic that should be carefully monitored. Also, women who have received family planning counseling were less likely to have an unplanned pregnancy, suggesting counseling is an important part of care. In this study, there were no differences in birth outcomes for the planned versus unplanned pregnancy, though the sample size may have impacted the power of the study to detect differences.</p>
          <p><strong>Jason Freeman</strong></p>
          <p>So to summarize the information we&rsquo;ve heard so far: Careful consideration should be taken in selecting medication in women with MS of childbearing age, especially if they are trying to conceive. Once a patient becomes pregnant, they should notify their health care provider immediately, who will discuss a plan to stop all disease-modifying therapies as appropriate.</p>
          <p>Let&rsquo;s talk about the postnatal period. What is the guidance around restarting treatment after childbirth?</p>
          <p><strong>Ali Chappell</strong></p>
          <p>This is such an important topic. Many don't realize that the postpartum period is the most at-risk period of a woman's entire disease. So, in general, patients should not restart treatment until they have stopped breastfeeding.<sup>3,4,8</sup></p>
          <p>Studies have documented detectable but very low amounts of monoclonal antibodies in breastmilk, which are likely degraded and denatured by proteolytic enzymes in the infant&rsquo;s GI tract when they&rsquo;re ingested.<sup>10</sup> However, as with pregnancy, more research is needed to understand the potential long-term impact on the infant.<sup>11</sup> Within the first 3 months after giving birth, about 25% of women may experience a relapse.<sup>5,12</sup> Interestingly, some studies have suggested that breastfeeding may be beneficial in reducing postpartum relapses in patients, but ultimately, these data are inconclusive and may depend on external factors such as prenatal disease activity and control.<sup>8</sup></p>
          <p>Weighing the benefits of breastfeeding for the infant vs treatment for the mother should be assessed postpartum, or ideally earlier, depending on disease activity before and during pregnancy.<sup>8</sup> Women with a low risk of disease activation may be encouraged to breastfeed for the first 3 to 6 months postpartum; however, in patients with highly active disease, breastfeeding should not delay reinitiating treatment that could be beneficial to the mother.<sup>13</sup></p>
          <p><strong>Jason Freeman</strong></p>
          <p>Are there any factors that can help physicians predict which patients may be more likely to experience relapses?</p>
          <p><strong>Ali Chappell</strong></p>
          <p>Yes, potentially. There was a meta-analysis that investigated risk factors associated with higher risk of relapse in patients who had not received treatment during pregnancy.<sup>13</sup> The study found that a patient&rsquo;s relapse activity 1 year before conception and higher levels of disability as assessed by the Expanded Disability Status Scale, or EDSS, at conception might be predictive of an increased risk of relapse and disability progression both during pregnancy and in the postpartum period.<sup>13</sup></p>
          <p>Another factor associated with an increased risk of relapse was prolonging time to restart treatment after giving birth.<sup>13</sup> In addition, sometimes we do a washout period before&nbsp;conception, depending on the potential risk of damage to the fetus, and a longer period has also been associated with a higher risk of postpartum relapse.</p>
          <p><strong>Jason Freeman</strong></p>
          <p>So what are some physical and psychological impacts of pregnancy that should be considered in women with MS that we haven&rsquo;t already discussed?</p>
          <p><strong>Ali Chappell</strong></p>
          <p>This is such an important topic that doesn't commonly get addressed. So the risk of postpartum depression increases 2-fold for patients with MS in the third trimester.<sup>14&nbsp;</sup>Other cognitive symptoms that might be worsened in patients with MS include anxiety and fatigue.<sup>12,14</sup></p>
          <p>So, physical challenges faced by pregnant women with MS include muscle spasticity, pain, balance, and issues with the pelvic floor that could worsen existing incontinence issues and further increase the high risk of contracting a urinary tract infection.<sup>12</sup> Patients facing these challenges may benefit from working closely with physical therapists or urologists.<sup>12</sup></p>
          <p>Considering all these challenges, patients should be encouraged to plan on social and emotional support to help with practical things like caring for the newborn or getting help with household tasks. There are also benefits of having a multidisciplinary team available to address the variable impact of pregnancy and childbirth on a patient&rsquo;s physical but also mental health.<sup>12</sup></p>
          <p><strong>Jason Freeman</strong></p>
          <p>Thank you for all of these insights. This discussion has highlighted some really important information about managing and educating women with MS of childbearing age.</p>
          <p>If I can summarize our discussion today: Neurologists are often the primary source of information for family-planning decisions, and counselling should ideally begin before conception.<sup>4,12&nbsp;</sup>Health care providers should consider the unique situation of each patient, their priorities, concerns, and disease activity, as personalized care is crucial for patients to make informed decisions about family planning. Discussions around family planning may potentially span several years, from diagnosis to conception, through pregnancy and postnatal periods, and it is important to foster open communication to ensure the best possible outcomes for mother and child.<sup>12</sup></p>
          <p><strong>Ali Chappell</strong></p>
          <p>Thank you for having me, Dr Freeman.</p>
          <p><strong>Jason Freeman</strong></p>
          <p>And thank you for listening to this podcast on pregnancy management for patients with multiple sclerosis. I hope you will explore the other resources on our website, MSHCPeducation.com, for health care providers who manage patients with MS.</p>

          <p class="date-job-code">
            <span class="date">11/23</span> <span class="job-code">286192</span>
          </p>
        </template>
      </PodcastPage>

      <RefsModal v-if="$store.state.showModal === 'references'">
        <template #refs-list>
          <ol class="refs-list">
            <li>
              Burks J, Marshall TS, Ye X. Adherence to disease-modifying therapies and its impact on relapse,
              health resource utilization, and costs among patients with multiple sclerosis. <em>Clinicoecon
              Outcomes Res.</em> 2017;9:251-260. doi:10.2147/CEOR.S130334
            </li>
            <li>
              Varytė G, Zakarevičienė J, Ramašauskaitė D, et al. Multiple sclerosis: an update on the disease
              modifying treatment strategy and a review of pregnancy's impact on disease activity. <em>Medicina
              (Kaunas).</em> 2020;56(2):49. doi:10.3390/medicina56020049
            </li>
            <li>
              Langer-Gould AM. Pregnancy and family planning in multiple sclerosis. <em>Continuum (Minneap
              Minn).</em> 2019;25(3):773-792. doi:10.1212/CON.0000000000000745
            </li>
            <li>
              Bonavita S, Lavorgna L, Worton H, Russell S, Jack D. Family planning decision making in people
              with multiple sclerosis. <em>Front Neurol.</em> 2021;12:1-8. doi:10.3389/fneur.2021.620772
            </li>
            <li>
              Dobson R, Dassan P, Roberts M, Giovannoni G, Nelson-Piercy C, Brex PA. UK consensus on
              pregnancy in multiple sclerosis: “Association of British Neurologists” guidelines. <em>Pract Neurol.</em>
              2019;19(2):106-114. doi:10.1136/practneurol-2018-002060
            </li>
            <li>
              MacDonald SC, McElrath TF, Hernández-Díaz S. Pregnancy outcomes in women with multiple
              sclerosis. <em>Am J Epidemiol.</em> 2019;188(1):57-66. doi:0.1093/aje/kwy197
            </li>
            <li>
              Patsopoulos NA. Genetics of multiple sclerosis: an overview and new directions. <em>Cold Spring
              Harb Perspect Med.</em> 2018;8:a028951. doi:10.1101/cshperspect.a028951
            </li>
            <li>
              Simone IL, Tortorella C, Ghirelli A. Influence of pregnancy in multiple sclerosis and impact of
              disease-modifying therapies. <em>Front Neurol.</em> 2021;12. doi:10.3389/fneur.2021.697974
            </li>
            <li>
              Smith AL, Cohen JA, Ontaneda D, Rensel M. Pregnancy and multiple sclerosis: risk of unplanned
              pregnancy and drug exposure in utero. <em>Mult Scler J Exp Transl Clin.</em> 2019:1-10.
              doi:10.1177/205521731989174
            </li>
            <li>
              Huy-Pham A, Top KA, Constantinescu C, et al. The use and impact of monoclonal antibody
              biologics during pregnancy. <em>CMAJ.</em> 2021;193:E1129-E1136. doi:10.1503/cmaj.202391
            </li>
            <li>
              Gklinos P, Dobson R. Monoclonal antibodies in pregnancy and breastfeeding in patients with
              multiple sclerosis: a review and an updated clinical guide. <em>Pharmaceuticals (Basel).</em>
              2023;16(5):770. doi:10.3390/ph16050770
            </li>
            <li>
              Krysko KM, Bove R, Dobson R, Jokubaitis V, Hellwig K. Treatment of women with multiple
              sclerosis planning pregnancy. <em>Curr Treat Options Neurol.</em> 2021;23(4):11. doi:10.1007/s11940-021-00666-4 
            </li>
            <li>
              Bsteh G, Hegen H, Riedl K, et al. Estimating risk of multiple sclerosis disease reactivation in
              pregnancy and postpartum: the VIPRiMS score. <em>Front Neurol.</em> 2022;12:1-9.
              doi:10.3389/fneur.2021.766956
            </li>
            <li>
              Eid K, Torkildsen ØF, Aarseth J, et al. Perinatal depression and anxiety in women with multiple
              sclerosis: a population-based cohort study. <em>Neurology.</em> 2021;96(23):e2789-e2800.
              doi:10.1212/WNL.0000000000012062
            </li>
          </ol>
        </template>
      </RefsModal>
    </div>
  </div>
</template>

<script>
import PodcastPage from "@/components/PodcastPage";
import RefsModal from "@/components/RefsModal";

export default {
  name: "PregnancyManagementPodcast",
  components: {
    PodcastPage,
    RefsModal,
  },
  data() {
    return {
      id: this.$router.currentRoute.params.id,
      audio: {
        id: "pregnancy-management",
        title:
          "Pregnancy Management in MS Podcast",
        src: "assets/audio/pregnancy-management.mp3",
        analyticsLabel:
          "Pregnancy Management in MS Podcast",
        desc: "Dr Jason Freeman, neurologist (Medical Director, Novartis), and Dr Ali Chappell (Medical Science Liaison, Novartis) share valuable insights on how pregnancy can be best managed from the perspectives of both patients living with MS as well as health care providers.",
      },
    };
  },
  methods: {
    showPdf: function (data) {
      this.$router.push({ name: data.src.split(".")[0] });

      this.$gtag.event("click", {
        event_category: "view_pdf",
        event_label: `${data.analyticsLabel}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.podcast-page {
  color: $black;
  background: rgb(255, 255, 255);
  position: relative;
}

.mesh {
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    pointer-events: none;
    background-image: url("~@/assets/img/video-bg.png");
    top: 0;
    right: 0;
  }
}

.tactic-btn {
  border: 1px solid $white;
  background: none;
  color: $white;
  padding: rem(10px) rem(25px);
  text-align: left;
  position: relative;
  width: 100%;
  min-height: rem(60px);
  display: block;
  margin-bottom: rem(20px);

  &:last-child {
    margin-bottom: 0px;
  }
}

.tactic-btn-label {
  float: left;
  font-size: rem(18px);
  font-weight: $font-weight-bold;
  width: calc(100% - 50px);
  position: absolute;
  top: 50%;
  left: rem(20px);
  transform: translate(0%, -50%);
}

.tactic-btn-icon {
  float: left;
  width: rem(20px);
  margin-left: rem(30px);
  position: absolute;
  top: 50%;
  right: rem(20px);
  transform: translate(0%, -50%);

  img {
    width: 100%;
  }

  &.launch-icon {
    width: rem(27px);
  }
}

:deep(.audio-title) {
  display: none;
  display: none !important;
}
</style>
