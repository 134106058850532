export const videos = {
	video1: {
		title: "Early neuroinflammation and progressive neurodegeneration in MS",
		desc: "Dr. [X], expert in [to be filled in], will discuss how lesion loads on MRIs reflect neuroinflammation, and their effect on progressive neurodegeneration.",
		kalturaData: {
			eventLabel: 'MS Video 1',
			id: 1603811077,
			uiConfId: '34239831',
			entryId: '1_dl4jho9c',
		},
		image: {
			src: "assets/img/tactic-fpo.png",
			alt: ""
		},
		pdf: {
			thumbnail: {
				src: "assets/img/tactic-fpo.png",
				alt: ""
			},
			src: "fpo.pdf",
			eventLabel: "PDF: MS Video 1"
		},
		button: {
			label: "Play Video",
			ariaDescribedBy: ""
		},
		log: 'MS Video 1',
	},
	video2: {
		title: "Emerging data around the timing of DMT",
		desc: "Early treatment in MS is known to be clinically important to prognosis.  Hear Dr. [X] discuss the ‘window of opportunity’ in MS and implications of different treatment paradigms.",
		kalturaData: {
			eventLabel: 'MS Video 2',
			id: 1603811077,
			uiConfId: '34239831',
			entryId: '1_dl4jho9c',
		},
		image: {
			src: "assets/img/tactic-fpo.png",
			alt: ""
		},
		pdf: {
			thumbnail: {
				src: "assets/img/tactic-fpo.png",
				alt: ""
			},
			src: "fpo.pdf",
			eventLabel: "PDF: MS Video 2"
		},
		button: {
			label: "Play Video",
			ariaDescribedBy: ""
		},
		log: 'MS Video 2'
	},
	video3: {
		title: "High-Efficacy Treatment (HET)",
		desc: "Listen to emerging expert views on the benefits and risks of ‘High-Efficacy Treatments’ (HET) and where they fit in the treatment paradigm for patients with MS.",
		kalturaData: {
			eventLabel: 'MS Video 3',
			id: 1603811077,
			uiConfId: '34239831',
			entryId: '1_dl4jho9c',
		},
		image: {
			src: "assets/img/tactic-fpo.png",
			alt: ""
		},
		pdf: {
			thumbnail: {
				src: "assets/img/tactic-fpo.png",
				alt: ""
			},
			src: "fpo.pdf",
			eventLabel: "PDF: MS Video 3"
		},
		button: {
			label: "Play Video",
			ariaDescribedBy: ""
		},
		log: 'MS Video 3'
	},
	video4: {
		title: "The Pathophysiology of MS",
		desc: "An expert in the molecular pathogenesis of MS, Dr. [X] highlights the emerging role of B and T cell activity in early MS neuroinflammation.",
		kalturaData: {
			eventLabel: 'MS Video 4',
			id: 1603811077,
			uiConfId: '34239831',
			entryId: '1_dl4jho9c',
		},
		image: {
			src: "assets/img/tactic-fpo.png",
			alt: ""
		},
		pdf: {
			thumbnail: {
				src: "assets/img/tactic-fpo.png",
				alt: ""
			},
			src: "fpo.pdf",
			eventLabel: "PDF: MS Video 4"
		},
		button: {
			label: "Play Video",
			ariaDescribedBy: ""
		},
		log: 'MS Video 4'
	},
	video5: {
		title: "Current research highlighting potential therapeutic targets ",
		desc: "Our continually evolving understanding of MS has led to advancements in therapeutic targets.  Here, we will discuss differences between historical, current and future disease modifying therapies.",
		kalturaData: {
			eventLabel: 'MS Video 5',
			id: 1603811077,
			uiConfId: '34239831',
			entryId: '1_dl4jho9c',
		},
		image: {
			src: "assets/img/tactic-fpo.png",
			alt: ""
		},
		pdf: {
			thumbnail: {
				src: "assets/img/tactic-fpo.png",
				alt: ""
			},
			src: "fpo.pdf",
			eventLabel: "PDF: MS Video 5"
		},
		button: {
			label: "Play Video",
			ariaDescribedBy: ""
		},
		log: 'MS Video 5'
	},
	video7: {
		title: "Defining holistic treatment in MS",
		desc: "In this video, learn about the benefits of a holistic treatment approach and effective ways for healthcare providers to communicate with their MS patients.",
		kalturaData: {
			eventLabel: 'MS Video 7',
			id: 1603811077,
			uiConfId: '34239831',
			entryId: '1_dl4jho9c',
		},
		image: {
			src: "assets/img/tactic-fpo.png",
			alt: ""
		},
		pdf: {
			thumbnail: {
				src: "assets/img/tactic-fpo.png",
				alt: ""
			},
			src: "fpo.pdf",
			eventLabel: "PDF: MS Video 7"
		},
		button: {
			label: "Play Video",
			ariaDescribedBy: ""
		},
		log: 'MS Video 7'
	},
	video8: {
		title: "Disease progression: Patient changing perspectives and priorities",
		desc: "Patients’ perspectives and priorities change with progression of MS.  Listen to Dr. [X] explain how to address patients’ concerns and questions.",
		kalturaData: {
			eventLabel: 'MS Video 8',
			id: 1603811077,
			uiConfId: '34239831',
			entryId: '1_dl4jho9c',
		},
		image: {
			src: "assets/img/tactic-fpo.png",
			alt: ""
		},
		pdf: {
			thumbnail: {
				src: "assets/img/tactic-fpo.png",
				alt: ""
			},
			src: "fpo.pdf",
			eventLabel: "PDF: MS Video 8"
		},
		button: {
			label: "Play Video",
			ariaDescribedBy: ""
		},
		log: 'MS Video 8'
	},
	video9: {
		title: "Empowering patients throughout the disease spectrum",
		desc: "Empowering patients throughout the disease spectrum is an important part of a holistic management approach.  Learn about the key topics to discuss with your MS patients.",
		kalturaData: {
			eventLabel: 'MS Video 9',
			id: 1603811077,
			uiConfId: '34239831',
			entryId: '1_dl4jho9c',
		},
		image: {
			src: "assets/img/tactic-fpo.png",
			alt: ""
		},
		pdf: {
			thumbnail: {
				src: "assets/img/tactic-fpo.png",
				alt: ""
			},
			src: "fpo.pdf",
			eventLabel: "PDF: MS Video 9"
		},
		button: {
			label: "Play Video",
			ariaDescribedBy: ""
		},
		log: 'MS Video 9'
	},
	video10: {
		title: "Diagnostic challenges of MS",
		desc: "Early diagnosis of MS remains an ongoing challenge.  In this video, Dr. [X] discusses common and subtle early symptoms that should raise the index of suspicion for MS.",
		kalturaData: {
			eventLabel: 'MS Video 10',
			id: 1603811077,
			uiConfId: '34239831',
			entryId: '1_dl4jho9c',
		},
		image: {
			src: "assets/img/tactic-fpo.png",
			alt: ""
		},
		pdf: {
			thumbnail: {
				src: "assets/img/tactic-fpo.png",
				alt: ""
			},
			src: "fpo.pdf",
			eventLabel: "PDF: MS Video 10"
		},
		button: {
			label: "Play Video",
			ariaDescribedBy: ""
		},
		log: 'MS Video 10'
	},
	video11: {
		title: "Risk and management of COVID-19 in patients with MS",
		desc: "COVID-19 is a current-day reality.  Here, we will discuss the similarities in the pathogenesis of MS and COVID-18, the risks of COVID-19 in patients with MS, and implications of MS treatments on future vaccinations.",
		kalturaData: {
			eventLabel: 'MS Video 11',
			id: 1603811077,
			uiConfId: '34239831',
			entryId: '1_dl4jho9c',
		},
		image: {
			src: "assets/img/tactic-fpo.png",
			alt: ""
		},
		pdf: {
			thumbnail: {
				src: "assets/img/tactic-fpo.png",
				alt: ""
			},
			src: "fpo.pdf",
			eventLabel: "PDF: MS Video 11"
		},
		button: {
			label: "Play Video",
			ariaDescribedBy: ""
		},
		log: 'MS Video 11'
	},
	video12: {
		title: "Cognitive Decline in MS Progression	",
		desc: "In this video, we will discuss the impact of cognitive impairment across the disease spectrum and highlight some strategies to promote cognitive reserve in patients with MS.",
		kalturaData: {
			eventLabel: 'MS Video 12',
			id: 1603811077,
			uiConfId: '34239831',
			entryId: '1_dl4jho9c',
		},
		image: {
			src: "assets/img/tactic-fpo.png",
			alt: ""
		},
		pdf: {
			thumbnail: {
				src: "assets/img/tactic-fpo.png",
				alt: ""
			},
			src: "fpo.pdf",
			eventLabel: "PDF: MS Video 12"
		},
		button: {
			label: "Play Video",
			ariaDescribedBy: ""
		},
		log: 'MS Video 12'
	}
}