<template>
  <div :id="`patient-lens-video--${id}`" class="patient-lens-video mesh">
    <div class="inner-wrapper">
      <VideoPage>
        <template #header>{{ video.title }}</template>
        <template #video-copy>{{ video.desc }}</template>
        <template #video-content>
          <KalturaVideo :videoData="video.kalturaData" />
        </template>
      </VideoPage>
    </div>
  </div>
</template>

<script>
import KalturaVideo from "@/components/KalturaVideo";
import VideoPage from "@/components/VideoPage";

export default {
  name: "PatientLensVideo2",
  components: {
    KalturaVideo,
    VideoPage,
  },
  data() {
    return {
      id: this.$router.currentRoute.params.id,
      video: {
        title:
          "Transitioning from RRMS to SPMS: patient discussions and treatment approaches",
        kalturaData: {
          eventLabel: "Transitioning from RRMS to SPMS Video",
          id: 1612148397,
          uiConfId: "46180831",
          entryId: "1_3xbcyyiu",
        },
      },
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.patient-lens-video {
  color: $white;
  background: rgb(59, 103, 159);
  background: linear-gradient(
    160deg,
    rgba(59, 103, 159, 1) 0%,
    rgba(61, 67, 104, 1) 64%,
    rgba(116, 57, 72, 1) 100%
  );
  position: relative;
}
.mesh {
  &:before,
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 40%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 0;
    pointer-events: none;
  }

  &:before {
    top: 0;
    right: 0;
    background-position: 100% 0;
    background-image: url("~@/assets/img/bg-video-mesh-top.svg");
  }

  &:after {
    bottom: 0;
    left: 0;
    background-position: 0 100%;
    background-image: url("~@/assets/img/bg-video-mesh-bottom.svg");
  }
}

.tactic-btn {
  border: 1px solid $white;
  background: none;
  color: $white;
  padding: rem(10px) rem(25px);
  text-align: left;
  position: relative;
  width: 100%;
  min-height: rem(60px);
  display: block;
  margin-bottom: rem(20px);

  &:last-child {
    margin-bottom: 0px;
  }
}

.tactic-btn-label {
  float: left;
  font-size: rem(18px);
  font-weight: $font-weight-bold;
  width: calc(100% - 50px);
  position: absolute;
  top: 50%;
  left: rem(20px);
  transform: translate(0%, -50%);
}

.tactic-btn-icon {
  float: left;
  width: rem(20px);
  margin-left: rem(30px);
  position: absolute;
  top: 50%;
  right: rem(20px);
  transform: translate(0%, -50%);

  img {
    width: 100%;
  }
  &.launch-icon {
    width: rem(27px);
  }
}
</style>
