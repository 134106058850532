<template>
  <div class="vidWrapper">
    <iframe
      @click="play()"
      title="vimeo-player"
      ref="video"
      id="vid"
      :src="`https://player.vimeo.com/video/${videoData.vimeoId}`"
      width="640"
      height="360"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
import Player from "@vimeo/player"

export default {
  name: "VimeoVideoEmbed",
  props: {
    currentTime: {
      type: Number,
    },
    videoData: {
      type: Object,
      require: true,
      validator: (to) => ["vimeoId"].every((key) => key in to),
    },

    setCurrentTimeUpdate: {
      type: Function,
    },

    setTheTimelineFlag: {
      type: Boolean,
    },

    updateTheTimelineFlag: {
      type: Function,
    },
  },

  data: () => {
    return {
      time: 0,
    }
  },

  watch: {
    currentTime: {
      handler(newVal, prevVal) {
        if (this.setTheTimelineFlag) {
          this.seekTo(newVal)
        }
      },
      deep: true,
    },
  },

  methods: {
    install() {
      // this.player = new Player(this.$el).on("play", () => {
      //   this.$gtag.event("click", {
      //     event_category: "play_video",
      //     event_label: this.videoData.eventLabel,
      //   })
      // })

      this.player = new Player(this.$refs.video)

      this.player.on("play", () => {
        console.log("video play!")
        // Example event tracking, replace with your actual tracking code
        this.$gtag.event("click", {
          event_category: "play_video",
          event_label: this.videoData.eventLabel,
        })
      })

      // to check the time has been updated
      this.player.on("timeupdate", (v) => {
        this.$emit("setCurrentTimeUpdate", v.seconds)
      })

      // Set initial time if specified
      // if (this.currentTime > 0) {
      // }
    },

    seekTo(seconds) {
      this.player
        .setCurrentTime(seconds)
        .then((time) => {
          this.$emit("updateTheTimelineFlag", false)
        })
        .catch(function (error) {
          switch (error.name) {
            case "RangeError":
              // the time was less than 0 or greater than the video’s duration
              break
            default:
              // some other error occurred
              break
          }
        })
    },
  },

  mounted() {
    this.install()
  },
  destroyed() {
    if (this.player) {
      this.player.destroy()
    }
  },
}
</script>

<style lang="scss">
.vidWrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  border: 1px solid #c4c8ce;
  background-color: #fff;
}
.vidWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
