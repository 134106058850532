<template>
  <button
    @click="gotoRoute()"
    class="button-return"
    :class="colour"
    >
    <div class="button-return-icon">
      <img v-if="colour === 'white'" src="@/assets/img/icon-return-white.svg" alt="icon-return" aria-hidden="true" focusable="false">
      <img v-if="colour === 'black'" src="@/assets/img/icon-return.svg" alt="icon-return" aria-hidden="true" focusable="false">
    </div>
    <span class="button-return-label">
      <slot>Back</slot>
    </span>
  </button>
</template>

<script>
export default {
  name: 'ButtonReturn',
  props: {
    to: {
      type: [Object, String],
    },
    colour: {
      type: String,
      default: 'white'
    }
  },
  methods: {
    gotoRoute() {
      if (this.to) {
        this.$router.push({ name: `${this.to}`});
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .button-return {
    float: right;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    background: transparent;
    border: none;
    font-size: rem(16px);
    color: $white;
    font-weight: $font-weight-bold;

    @media (max-width: 600px) {
      justify-content: flex-start;
      margin-bottom: rem(16px);
    }

    &.black {
      color: $black;
    }
  }

  .button-return-icon {
    display: inline-block;
    width: 35px;
    margin-right: 15px;

    img {
      display: block;
      width: 100%;
    }
  }
</style>
