import "core-js/stable";
import "custom-event-polyfill";
import cssVars from "css-vars-ponyfill";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCookies from "vue-cookies";
import VueGtag from "vue-gtag";
import VueResource from "vue-resource";
import NovartisPlugin from "./plugins/NovartisPlugin";
import { currUID } from "./assets/constants.js";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
import vueVimeoPlayer from "vue-vimeo-player";
import VueCarousel from "vue-carousel";

// Allows for custom CSS variables to work in IE11
cssVars({
  onlyLegacy: true,
});
//comment
// Debug mode for analytics is available through the "Google Analytics Debugger" Chrome extension: https://chrome.google.com/webstore/detail/google-analytics-debugger/
Vue.config.productionTip = false;

Vue.prototype.$store = Vue.observable(store);

Vue.use(VueCarousel);
Vue.use(NovartisPlugin);
Vue.use(VueCookies);
Vue.use(VueResource);
Vue.use(vueVimeoPlayer);

Vue.use(VueGtag, {
  config: {
    id: currUID,
    params: {
      send_page_view: false,
    },
  },
});

Vue.use(VuePlyr, {
  plyr: {
    controls: ["play", "progress", "current-time"],
  },
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
