<template>
  <div style="width: 100%; display: inline-block; position: relative"> 
    <vue-plyr ref="plyr">
      <div data-plyr-provider="vimeo" :data-plyr-embed-id="videoData.vimeoId"></div>
    </vue-plyr>
  </div>
</template>

<script>


export default {
  name: 'VimeoVideo',
  props: {
    videoData: {
      type: Object,
      require: true,
      validator: to => ['vimeoId'].every(key => key in to)
    },
  },
  data() {
    return {
      options: {
				muted: true,
        autoplay: true,
			}
    }
  },
  mounted() {
    this.$refs.plyr.player.on("playing", () => {
      if (this.$refs.plyr.player.currentTime === 0) {
        this.$gtag.event("click", {
          event_category: "play_video",
          event_label: this.videoData.eventLabel,
        });
      }
    });
  }
};
</script>

<style lang="scss">
#vimeo-player {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#ratio-16-9 {
  margin-top: 56.25%;
}
</style>
