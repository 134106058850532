<template>
  <div id="sitemap">
    <header>
      <InnerContent>
        <ButtonReturn colour="black" />
        <h1 class="title">
          Multiple Sclerosis (MS) Disease Information Site Map
        </h1>
      </InnerContent>
    </header>

    <section class="section">
      <InnerContent>
        <div class="column">
          <h2 class="home-header">
            <a
              :href="
                env === 'development'
                  ? menuItems.home.main.urlDev
                  : menuItems.home.main.urlProd
              "
              @click="logEvent('sitemap', menuItems.home.main)"
            >
              <span>Home</span>
              <img
                class="chevron"
                src="@/assets/img/icon-chevron-sitemap.svg"
                alt=""
              />
            </a>
          </h2>

          <h2>
            <a
              :href="
                env === 'development'
                  ? menuItems.home.neuropathology.urlDev
                  : menuItems.home.neuropathology.urlProd
              "
              @click="
                logEvent(
                  'launch_menu_item',
                  menuItems.home.neuropathology.title
                )
              "
            >
              <span>Mechanism of Disease</span>
              <img
                class="chevron"
                src="@/assets/img/icon-chevron-sitemap.svg"
                alt=""
              />
            </a>
          </h2>
          <ul>
            <h2>
              <a
                href="/mechanism-of-disease#bTCell"
                @click="
                  logEvent(
                    'launch_menu_item',
                    video.mspathovideo.analyticsLabel
                  )
                "
              >
                <span>B- and T-Cell Activity</span>
                <img
                  class="chevron"
                  src="@/assets/img/icon-chevron-sitemap.svg"
                  alt=""
                />
              </a>
            </h2>
            <li>
              <button class="pdf-button" @click="showApp(video.msmod)">
                Mechanism of Disease in MS – Video
              </button>
            </li>
            <li>
              <button class="pdf-button" @click="showApp(video.mspathovideo)">
                B- and T-Cell Pathophysiology in MS – Video
              </button>
            </li>
            <li>
              <button class="pdf-button" @click="showPdf(pdfs.neuro[0])">
                The Pathogenic Immune System in MS – PDF
              </button>
            </li>
            <li>
              <button class="pdf-button" @click="showPdf(pdfs.neuro[1])">
                Insights Into MS: B- and T-Cell Pathophysiology – PDF
              </button>
            </li>
          </ul>

          <ul>
            <h2>
              <a
                href="/mechanism-of-disease#neuro"
                @click="
                  logEvent('launch_menu_item', video.msvideo.analyticsLabel)
                "
              >
                <span class="shorten"
                  >Neuroinflammation and&nbsp;Neurodegeneration</span
                >
                <img
                  class="chevron"
                  src="@/assets/img/icon-chevron-sitemap.svg"
                  alt=""
                />
              </a>
            </h2>
            <li>
              <button class="pdf-button" @click="showApp(video.msvideo)">
                From Neuroinflammation to Neurodegeneration in MS – Video
              </button>
            </li>
            <li>
              <button class="pdf-button" @click="showPdf(pdfs.neuro[2])">
                Insights Into MS: From Neuroinflammation to Neurodegeneration –
                PDF
              </button>
            </li>
          </ul>

          <ul>
            <h2>
              <a
                href="/mechanism-of-disease#immunoglobulins"
                @click="
                  logEvent(
                    'launch_menu_item',
                    video.immunoglobulins.analyticsLabel
                  )
                "
              >
                <span>Immunoglobulins</span>
                <img
                  class="chevron"
                  src="@/assets/img/icon-chevron-sitemap.svg"
                  alt=""
                />
              </a>
            </h2>
            <li>
              <button
                class="pdf-button"
                @click="showApp(video.immunoglobulins)"
              >
                Immunoglobulins and MS – Video
              </button>
            </li>
            <li>
              <button
                class="pdf-button"
                @click="showApp(video.neuroimmunologyConsiderations)"
              >
                Neuroimmunology Considerations in MS – Video
              </button>
            </li>
            <li>
              <button class="pdf-button" @click="showPdf(pdfs.neuro[3])">
                Insights Into MS: The Role of Immunoglobulins – PDF
              </button>
            </li>
          </ul>
          <h2>
            <a
              :href="
                env === 'development'
                  ? menuItems.home.recognizing.urlDev
                  : menuItems.home.recognizing.urlProd
              "
              @click="logEvent('sitemap', menuItems.home.recognizing.title)"
              ><span>Diagnosis</span
              ><img
                class="chevron"
                src="@/assets/img/icon-chevron-sitemap.svg"
                alt=""
            /></a>
          </h2>
          <ul>
            <h2>
              <a
                href="/diagnosis#earlySignsAndSymptoms"
                @click="logEvent('sitemap', menuItems.home.recognizing.title)"
                ><span>Early Signs and Symptoms in MS</span
                ><img
                  class="chevron"
                  src="@/assets/img/icon-chevron-sitemap.svg"
                  alt=""
              /></a>
            </h2>
            <li>
              <button class="pdf-button" @click="showApp(apps.recognizing)">
                Early MS Symptoms – Interactive
              </button>
            </li>
            <li>
              <button class="pdf-button" @click="showPdf(pdfs.signs[2])">
                Insights Into MS: Subtle Symptoms and Disease Progression – PDF
              </button>
            </li>
          </ul>

          <ul>
            <h2>
              <a
                href="/diagnosis#diagnosticChallenges"
                @click="logEvent('sitemap', menuItems.home.recognizing.title)"
                ><span>Diagnostic Challenges</span>
                <img
                  class="chevron"
                  src="@/assets/img/icon-chevron-sitemap.svg"
                  alt=""
              /></a>
            </h2>
            <li>
              <button
                class="pdf-button"
                @click="showApp(video.diagnosticchallengesvideo)"
              >
                Diagnostic Challenges of MS – Video
              </button>
            </li>
            <li>
              <button class="pdf-button" @click="showPdf(pdfs.signs[1])">
                Insights Into MS: Diagnostic Challenges – PDF
              </button>
            </li>
          </ul>

          <ul>
            <h2>
              <a
                href="/diagnosis#diagnosticCriteria"
                @click="logEvent('sitemap', menuItems.home.recognizing.title)"
                ><span>Diagnostic Criteria</span
                ><img
                  class="chevron"
                  src="@/assets/img/icon-chevron-sitemap.svg"
                  alt=""
              /></a>
            </h2>
            <li>
              <button class="pdf-button" @click="showPdf(pdfs.signs[0])">
                Diagnostic Criteria – Interactive
              </button>
            </li>
          </ul>

          <h2>
            <a
              href="/management"
              @click="
                logEvent(
                  'launch_menu_item',
                  menuItems.home.hcppatientconnections.title
                )
              "
            >
              <span>Management</span>
              <img
                class="chevron"
                src="@/assets/img/icon-chevron-sitemap.svg"
                alt=""
              />
            </a>
          </h2>

          <ul>
            <h2>
              <a
                href="/management#therapeuticTargets"
                @click="logEvent('sitemap', menuItems.home.recognizing.title)"
                ><span>Therapeutic Targets in MS</span
                ><img
                  class="chevron"
                  src="@/assets/img/icon-chevron-sitemap.svg"
                  alt=""
              /></a>
            </h2>
            <li>
              <button class="pdf-button" @click="showApp(video.anticd20)">
                Anti-CD20 Monoclonal Antibodies in MS – Video
              </button>
            </li>
            <li>
              <button class="pdf-button" @click="showPdf(pdfs.managing[1])">
                Insights Into MS: Evolving Therapeutic Targets in MS – PDF
              </button>
            </li>
            <li>
              <router-link to="/switching-dmts/podcast">
                Switching Disease-Modifying Therapies in MS Podcast - Audio
              </router-link>
            </li>
          </ul>

          <ul>
            <h2>
              <a
                href="/management#treatmentInitiation"
                @click="logEvent('sitemap', menuItems.home.recognizing.title)"
                ><span>Treatment Initiation in MS</span>
                <img
                  class="chevron"
                  src="@/assets/img/icon-chevron-sitemap.svg"
                  alt=""
              /></a>
            </h2>
            <li>
              <button class="pdf-button" @click="showPdf(pdfs.exploring[0])">
                Insights Into MS: Disease-Modifying Treatment Strategies – PDF
              </button>
            </li>
            <li>
              <button class="pdf-button" @click="showPdf(pdfs.managing[0])">
                Insights Into MS: Timing of Treatment Initiation – PDF
              </button>
            </li>
            <li>
              <router-link to="/managing-ms/podcast">
                The Evolving Landscape of Managing MS Podcast - Audio
              </router-link>
            </li>
          </ul>

          <ul>
            <h2>
              <a
                href="/management#patientCenteredCare"
                @click="logEvent('sitemap', menuItems.home.recognizing.title)"
                ><span>Patient-Centered Care</span>
                <img
                  class="chevron"
                  src="@/assets/img/icon-chevron-sitemap.svg"
                  alt=""
              /></a>
            </h2>
            <li>
              <button
                class="pdf-button"
                @click="showApp(video.empowerpatientvideo)"
              >
                Empowering Patients – Video
              </button>
            </li>

            <li>
              <button class="pdf-button" @click="showPdf(pdfs.care[0])">
                Insights Into MS: Holistic Patient Care – PDF
              </button>
            </li>
            <li>
              <button class="pdf-button" @click="showPdf(pdfs.care[1])">
                Insights Into MS: Empowering Patients – PDF
              </button>
            </li>
            <li>
              <button class="pdf-button" @click="showPdf(pdfs.managing[2])">
                Family Planning in MS – PDF
              </button>
            </li>
            <li>
              <button class="pdf-button" @click="showPdf(pdfs.managing[3])">
                Pregnancy Management in MS – PDF
              </button>
            </li>
            <li>
              <router-link to="/neurologists-perspective/podcast">
                Empowering Patients With MS: The Neurologist’s Perspective
                Podcast - Audio
              </router-link>
            </li>
            <li>
              <router-link to="/maximizing-adherence/podcast">
                Maximizing Adherence to Treatment Plans: Balancing Safety,
                Efficacy, and Patient Priorities Podcast - Audio
              </router-link>
            </li>
            <li>
              <router-link to="/pregnancy-management/podcast">
                Pregnancy Management in MS Podcast - Audio
              </router-link>
            </li>
            <li>
              <router-link to="/patient-adherence/podcast">
                Choice of Therapy and Patient Adherence in MS Podcast - Audio
              </router-link>
            </li>
          </ul>

          <ul>
            <h2>
              <a
                href="/management#unmetNeeds"
                @click="logEvent('sitemap', menuItems.home.recognizing.title)"
                ><span>Unmet Needs in MS</span
                ><img
                  class="chevron"
                  src="@/assets/img/icon-chevron-sitemap.svg"
                  alt=""
              /></a>
            </h2>
            <li>
              <button class="pdf-button" @click="showApp(video.manyfacesvideo)">
                Diversity in MS Clinical Trials – Video
              </button>
            </li>
            <li>
              <router-link to="/silent-progression/podcast">
                Assessing Silent Progression Podcast - Audio
              </router-link>
            </li>
          </ul>
        </div>

        <div class="column">
          <h2>
            <a
              href="/progression"
              @click="logEvent('sitemap', menuItems.home.exploring.title)"
            >
              <span>MS Progression</span>
              <img
                class="chevron"
                src="@/assets/img/icon-chevron-sitemap.svg"
                alt=""
            /></a>
          </h2>

          <ul>
            <h2>
              <a
                href="/progression#progressionVisualizations"
                @click="logEvent('sitemap', menuItems.home.exploring.title)"
              >
                <span class="shorten"
                  >Disease&nbsp;Progression Visualizations</span
                >
                <img
                  class="chevron"
                  src="@/assets/img/icon-chevron-sitemap.svg"
                  alt=""
              /></a>
            </h2>
            <li>
              <button class="pdf-button" @click="showApp(apps.triptych)">
                MS Disease Progression Visualizer – Interactive
              </button>
            </li>
            <li>
              <button class="pdf-button" @click="showApp(apps.exploring)">
                MS Disease Progression Visualizer – Video
              </button>
            </li>
          </ul>

          <ul>
            <h2>
              <a
                href="/progression#cognitiveReserve"
                @click="logEvent('sitemap', menuItems.home.exploring.title)"
              >
                <span class="shorten"
                  >Cognitive&nbsp;Reserve</span
                >
                <img
                  class="chevron"
                  src="@/assets/img/icon-chevron-sitemap.svg"
                  alt=""
              /></a>
            </h2>
            <li>
              <button
                class="pdf-button"
                @click="showApp(video.cognitivefuncvideo)"
              >
                Cognitive Decline in MS Progression – Video
              </button>
            </li>
            <li>
              <button class="pdf-button" @click="showPdf(pdfs.progression[1])">
                Insights Into MS: Cognitive Functioning – PDF
              </button>
            </li>
          </ul>
          <ul>
            <h2>
              <a
                href="/progression#patientImpact"
                @click="logEvent('sitemap', menuItems.home.care.title)"
              >
                <span>Patient Impact</span>
                <img
                  class="chevron"
                  src="@/assets/img/icon-chevron-sitemap.svg"
                  alt=""
              /></a>
            </h2>
            <li>
              <button class="pdf-button" @click="showApp(apps.progression)">
                Patient Journeys in MS – Interactive
              </button>
            </li>
            <li>
              <button class="pdf-button" @click="showPdf(pdfs.signs[3])">
                Insights Into MS: Patient Perspectives on Progression – PDF
              </button>
            </li>
            <li>
              <router-link to="/disease-continuum/podcast">
                MS: A Disease Continuum Podcast - Audio
              </router-link>
            </li>
          </ul>
          <h2>
            <a
              href="/resources"
              @click="logEvent('sitemap', menuItems.home.covidResources.title)"
            >
              <span>Resources</span>
              <img
                class="chevron"
                src="@/assets/img/icon-chevron-sitemap.svg"
                alt=""
              />
            </a>
          </h2>

          <ul>
            <h2>
              <a
                href="additional-resources#podcasts"
                @click="logEvent('sitemap', audio.podcasts.analyticsLabel)"
              >
                <span>Podcasts</span>
                <img
                  class="chevron"
                  src="@/assets/img/icon-chevron-sitemap.svg"
                  alt=""
                />
              </a>
            </h2>
            <li>
              <router-link to="/neurologists-perspective/podcast">
                Empowering Patients With MS: The Neurologist’s Perspective
                Podcast - Audio
              </router-link>
            </li>
            <li>
              <router-link to="/maximizing-adherence/podcast">
                Maximizing Adherence to Treatment Plans: Balancing Safety,
                Efficacy, and Patient Priorities Podcast - Audio
              </router-link>
            </li>
            <li>
              <router-link to="/managing-ms/podcast">
                The Evolving Landscape of Managing MS Podcast - Audio
              </router-link>
            </li>
            <li>
              <router-link to="/silent-progression/podcast">
                Assessing Silent Progression Podcast - Audio
              </router-link>
            </li>
            <li>
              <router-link to="/pregnancy-management/podcast">
                Pregnancy Management in MS Podcast - Audio
              </router-link>
            </li>
            <li>
              <router-link to="/patient-adherence/podcast">
                Choice of Therapy and Patient Adherence in MS Podcast - Audio
              </router-link>
            </li>
            <li>
              <router-link to="/switching-dmts/podcast">
                Switching Disease-Modifying Therapies in MS Podcast - Audio
              </router-link>
            </li>
            <li>
              <router-link to="/disease-continuum/podcast">
                MS: A Disease Continuum Podcast - Audio
              </router-link>
            </li>
          </ul>

          <ul>
            <h2 class="title-padding">
              <a
                href="/additional-resources"
                @click="logEvent('sitemap', menuItems.home.resources.title)"
              >
                <span>Resource Library</span>
                <img
                  class="chevron"
                  src="@/assets/img/icon-chevron-sitemap.svg"
                  alt=""
                />
              </a>
            </h2>

            <h2 class="title-padding">
              <router-link
                :to="
                  env === 'development'
                    ? menuItems.home.glossary.urlDev
                    : menuItems.home.glossary.urlProd
                "
                @click="logEvent('sitemap', menuItems.home.glossary)"
              >
                <span>Glossary of Terms</span>
                <img
                  class="chevron"
                  src="@/assets/img/icon-chevron-sitemap.svg"
                  alt=""
                />
              </router-link>
            </h2>
          </ul>
        </div>
      </InnerContent>
    </section>
    <footer>
      <InnerContent>
        <p class="acr">
          CD, cluster of differentiation; Interactive, interactive application;
          MS, multiple sclerosis; SPMS, secondary progressive MS.
        </p>
      </InnerContent>
    </footer>
  </div>
</template>

<script>
import ButtonReturn from "@/components/ButtonReturn.vue";

import { menuItems } from "@/assets/menuItems.js";
export default {
  name: "Sitemap",
  components: {
    ButtonReturn,
  },
  data() {
    return {
      menuItems: menuItems,
      apps: {
        recognizing: {
          urlDev: "/diagnosis/early-symptoms",
          urlProd: "/diagnosis/early-symptoms",
          analyticsLabel:
            "Sitemap: Early Multiple Sclerosis & Impact on Patient Symptoms",
        },
        exploring: {
          urlDev: "/progression/visualizer/video",
          urlProd: "/progression/visualizer/video",
          analyticsLabel:
            "Sitemap: Multiple Sclerosis Disease progression visualizer",
        },
        triptych: {
          urlDev: "/progression/visualizer",
          urlProd: "/progression/visualizer",
          analyticsLabel: "Sitemap: Disease progression visualizer app",
        },
        progression: {
          urlDev: "/progression/patient-journeys",
          urlProd: "/progression/patient-journeys",
          analyticsLabel: "Sitemap: Clinical presentations",
        },
        early: {
          urlDev: "/progression/courses",
          urlProd: "/progression/courses",
          route: "early-symptoms",
          analyticsLabel:
            "Sitemap: MS: Early Symptoms to Disease Progression Education Course",
        },
        hcppatientconnections: {
          urlDev: "/hcp-patient-connections",
          urlProd: "/hcp-patient-connections",
          analyticsLabel: "Sitemap: HCP Patient Connections",
        },
      },
      video: {
        diagnosticchallengesvideo: {
          urlDev: "/diagnosis/diagnostic-challenges/video",
          urlProd: "/diagnosis/diagnostic-challenges/video",
          analyticsLabel: "Sitemap: Diagnostic Challenges of MS",
        },
        msmod: {
          urlDev: "/mechanism-of-disease/video",
          urlProd: "/mechanism-of-disease/video",
          analyticsLabel: "Sitemap: Mechanism of Disease in MS – Video",
        },
        immunoglobulins: {
          urlDev: "/mechanism-of-disease/immunoglobulins-and-ms/video",
          urlProd: "/mechanism-of-disease/immunoglobulins-and-ms/video",
          analyticsLabel: "Sitemap: Immunoglobulins and MS - Video",
        },
        neuroimmunologyConsiderations: {
          urlDev:
            "/mechanism-of-disease/neuroimmunology-considerations-in-ms/video",
          urlProd:
            "/mechanism-of-disease/neuroimmunology-considerations-in-ms/video",
          analyticsLabel:
            "Sitemap: Neuroimmunology Considerations in MS – Video",
        },
        msvideo: {
          urlDev:
            "/mechanism-of-disease/neuroinflammation-neurodegeneration/video",
          urlProd:
            "/mechanism-of-disease/neuroinflammation-neurodegeneration/video",
          analyticsLabel:
            "Sitemap: From Neuroinflammation to Neurodegeneration in MS – Video",
        },
        mspathovideo: {
          urlDev: "/mechanism-of-disease/b-t-cell-pathophysiology/video",
          urlProd: "/mechanism-of-disease/b-t-cell-pathophysiology/video",
          analyticsLabel:
            "Sitemap: From Neuroinflammation to Neurodegeneration in MS – Video",
        },
        anticd20: {
          urlDev: "/management/anti-cd20-monoclonal-antibodies/video",
          urlProd: "/management/anti-cd20-monoclonal-antibodies/video",
          analyticsLabel:
            "Sitemap: Anti-CD20 Monoclonal Antibodies in MS – Video",
        },
        cognitivefuncvideo: {
          urlDev: "/progression/cognitive-functioning/video",
          urlProd: "/progression/cognitive-functioning/video",
          analyticsLabel: "Sitemap: Cognitive Decline in MS Progression – Video",
        },
        empowerpatientvideo: {
          urlDev: "/patient-centered-care/empowering-patients/video",
          urlProd: "/patient-centered-care/empowering-patients/video",
          analyticsLabel: "Sitemap: Empowering Patients – Video",
        },
        manyfacesvideo: {
          urlDev: "/patient-centered-care/diversity-clinical-trials/video",
          urlProd: "/patient-centered-care/diversity-clinical-trials/video",
          analyticsLabel: "Sitemap: Diversity in MS Clinical Trials – Video",
        },
        telemedicine: {
          urlDev: "/ms-and-covid-19/telemedicine/video",
          urlProd: "/ms-and-covid-19/telemedicine/video",
          analyticsLabel: "Sitemap: Telemedicine in MS – Video",
        }
      },
      audio: {
        covid: [
          {
            id: "covid-audio2",
            title: "Telemedicine in MS",
            src: "assets/audio/telemedicine.mp3",
            analyticsLabel: "Sitemap: Telemedicine in MS",
          },
        ],
        signs: [
          {
            id: "signs-audio1",
            title: "Early MS and impact on patient symptoms",
            src: "assets/audio/earlyms.mp3",
            analyticsLabel: "Sitemap: Signs and symptoms",
          },
        ],
        progression: [
          {
            id: "progression-audio1",
            title: "MS Disease Progression Audio",
            src: "assets/audio/msdiseaseprogression.mp3",
            analyticsLabel: "Sitemap: MS Disease Progression Audio",
          },
        ],
        recognizing: [
          {
            id: "recognizing-audio1",
            title: "Early MS & Impact on Patient Symptoms Audio",
            src: "assets/audio/earlyMS_and_impact.mp3",
            analyticsLabel:
              "Sitemap: Early MS & Impact on Patient Symptoms Audio",
          },
        ],
        podcasts: [
          {
            analyticsLabel: "Sitemap: Podcasts",
          },
        ],
      },
      pdfs: {
        covid: [
          {
            src: "covid.pdf",
            urlDev: "/covid",
            urlProd: "/covid",
            analyticsLabel: "COVID-19: COVID and MS",
          },
        ],
        neuro: [
          {
            src: "pathogenic-b-t-cells.pdf",
            urlDev:
              "/mechanism-of-disease/symptoms-and-pathogenic-immune-system/pdf",
            urlProd:
              "/mechanism-of-disease/symptoms-and-pathogenic-immune-system/pdf",
            analyticsLabel: "Sitemap: Pathogenic B Cells and T Cells",
          },
          {
            src: "early-pathophysiology.pdf",
            urlDev: "/mechanism-of-disease/ms-early-pathophysiology/pdf",
            urlProd: "/mechanism-of-disease/ms-early-pathophysiology/pdf",
            analyticsLabel: "Sitemap: B- and T-Cell Pathophysiology",
          },
          {
            src: "neuroinflammation.pdf",
            urlDev:
              "/mechanism-of-disease/neuroinflammation-neurodegeneration/pdf",
            urlProd:
              "/mechanism-of-disease/neuroinflammation-neurodegeneration/pdf",
            analyticsLabel:
              "Sitemap: From Neuroinflammation to Neurodegeneration",
          },
          {
            src: "immunoglobulin.pdf",
            urlDev: "/mechanism-of-disease/role-of-Immunoglobulins/pdf",
            urlProd: "/mechanism-of-disease/role-of-Immunoglobulins/pdf",
            analyticsLabel: "Sitemap: The Role of Immunoglobulins",
          },
        ],
        signs: [
          {
            src: "diagnostic-criteria.pdf",
            urlDev: "/diagnosis/diagnostic-criteria",
            urlProd: "/diagnosis/diagnostic-criteria",
            analyticsLabel: "Sitemap: Diagnosic Criteria",
          },
          {
            src: "diagnostic-challenges.pdf",
            urlDev: "/diagnosis/diagnostic-challenges/pdf",
            urlProd: "/diagnosis/diagnostic-challenges/pdf",
            analyticsLabel: "Sitemap: Diagnostic Challenges",
          },
          {
            src: "subtle-symptoms-and-disease-progression.pdf",
            urlDev: "/diagnosis/subtle-symptoms-and-disease-progression/pdf",
            urlProd: "/diagnosis/subtle-symptoms-and-disease-progression/pdf",
            analyticsLabel:
              "Sitemap: Clinical expression in early neuropathology",
          },
          {
            src: "patient-views-on-disease-progression.pdf",
            urlDev: "/progression/patient-views-on-disease-progression/pdf",
            urlProd: "/progression/patient-views-on-disease-progression/pdf",
            analyticsLabel: "Sitemap: Patient Perspectives on Progression",
          }
        ],
        exploring: [
          {
            src: "treatment-strategies.pdf",
            urlDev: "/management/disease-modifying-treatments/pdf",
            urlProd: "/management/disease-modifying-treatments/pdf",
            analyticsLabel: "Sitemap: Disease-Modifying Treatment Strategies",
          },
        ],
        managing: [
          {
            src: "early-treatment.pdf",
            urlDev: "/management/timing-of-treatment-initiation/pdf",
            urlProd: "/management/timing-of-treatment-initiation/pdf",
            analyticsLabel: "Sitemap: Patient views on MS progression",
          },
          {
            src: "current-therapeutic-targets.pdf",
            urlDev: "/management/evolving-therapeutic-targets/pdf",
            urlProd: "/management/evolving-therapeutic-targets/pdf",
            analyticsLabel: "Sitemap: Evolving Therapeutic Targets",
          },
          {
            src: "family-planning.pdf",
            urlDev: "/management/family-planning/pdf",
            urlProd: "/management/family-planning/pdf",
            analyticsLabel: "Sitemap: Family Planning in MS",
          },
          {
            src: "pregnancy-management.pdf",
            urlDev: "/management/pregnancy-management/pdf",
            urlProd: "/management/pregnancy-management/pdf",
            analyticsLabel: "Sitemap: Pregnancy Management in MS",
          },
        ],
        progression: [
          {
            src: "patient-views-on-disease-progression.pdf",
            urlDev:
              "https://nov-20364-dev-cac-inv.azurewebsites.net/patient-views-on-disease-progression",
            urlProd: "/patient-views-on-disease-progression",
            analyticsLabel: "Sitemap: Patient views on MS progression",
          },
          {
            src: "cognitive-impairment.pdf",
            urlDev: "/progression/cognitive-impairment/pdf",
            urlProd: "/progression/cognitive-impairment/pdf",
            analyticsLabel: "Sitemap: Cognitive impairment",
          },
        ],
        care: [
          {
            src: "holistic-treatment.pdf",
            urlDev: "/patient-centered-care/holistic-treatment/pdf",
            urlProd: "/patient-centered-care/holistic-treatment/pdf",
            analyticsLabel: "Sitemap: Holistic treatment",
          },
          {
            src: "empowering-patients.pdf",
            urlDev: "/patient-centered-care/empowering-patients/pdf",
            urlProd: "/patient-centered-care/empowering-patients/pdf",
            analyticsLabel: "Sitemap: Empowering patients",
          },
          {
            src: "immunoglobulin.pdf",
            urlDev:
              "https://nov-20364-dev-cac-inv.azurewebsites.net/immunoglobulin",
            urlProd: "/immunoglobulin",
            analyticsLabel: "Sitemap: Immunoglobulins in MS",
          },
        ],
      },
    };
  },
  computed: {
    env() {
      return process.env.NODE_ENV;
    },
  },
  methods: {
    showApp: function (app) {
      this.$gtag.event("click", {
        event_category: "launch_page",
        event_label: app.analyticsLabel,
      });

      if (app.route) {
        this.$router.push({ name: app.route });
      } else {
        let url =
          process.env.NODE_ENV === "development" ? app.urlDev : app.urlProd;
        window.open(url, "_self");
      }
    },
    showPdf: function (data) {
      this.$gtag.event("click", {
        event_category: "view_pdf",
        event_label: `${data.analyticsLabel}`,
      });

      if (data.route) {
        this.$router.push({ name: data.src.split(".")[0] });
      } else {
        let url =
          process.env.NODE_ENV === "development" ? data.urlDev : data.urlProd;
        window.open(url, "_self");
      }
    },
    downloadPdf: function (data) {
      this.$gtag.event("click", {
        event_category: "view_pdf",
        event_label: `${data.analyticsLabel}`,
      });
    },
    // beforeRouteEnter(to,from,next){
    //   // if(to.name="sitemap"){
    //     window.document.title = "Explore MS Site Map";
    //     next();
    //   // }
    // }
  },
};
</script>

<style lang="scss" scoped>
.title-padding {
  margin-bottom: 40px;

  &:last-child {
    margin-top: 40px;
  }
}
#resources {
  width: 100%;
  position: relative;
}

:deep(.inner-content) {
  margin: 0 auto;
}

.title {
  font-size: rem(46px);
  color: $blue;
  margin: rem(60px) 0 rem(20px) 0;

  @include breakpoint(xs) {
    font-size: rem(24px);
    line-height: rem(24px);
    margin: rem(20px) 0;
  }

  @include breakpoint(md) {
    font-size: rem(36px);
    line-height: rem(40px);
  }
}

.intro-copy {
  width: 80%;
  font-size: rem(24px);
  line-height: rem(32px);
  margin-bottom: rem(20px);

  @include breakpoint(md) {
    width: 100%;
    font-size: rem(18px);
    line-height: rem(22px);
  }

  @include breakpoint(xs) {
    width: 100%;
    font-size: rem(16px);
    line-height: rem(22px);
    margin-bottom: 20px;
  }
}

.section {
  background: $grey;
  padding: rem(30px);
  margin-bottom: rem(15px);

  .inner-content {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 414px) {
      flex-direction: column;
    }
  }

  h2 {
    font-size: rem(19px);
    line-height: rem(24px);

    &.home-header {
      margin-bottom: 40px;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .shorten {
        width: min-content;
      }
    }

    .chevron {
      margin-left: 10px;
    }
  }

  ul {
    list-style: none;
    padding-left: 30px;
    margin-bottom: 40px;

    li {
      font-size: rem(14px);
      margin: 10px 0;
    }
  }

  :deep(.audio-element-container) {
    width: 75%;
  }
}

.section-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.column {
  width: 50%;
  padding-right: 20px;

  @media screen and (max-width: 414px) {
    padding-right: 0;
  }
}

.link {
  border: none;
  background: none;
  color: $black;
  display: flex;
  align-items: center;
  text-align: left;
  padding-left: 0;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.resources {
  margin-bottom: rem(30px);
}

.resources-list {
  padding-left: 0;
  list-style-type: none;
  margin: 0;

  li {
    margin-bottom: rem(20px);
  }
}

a {
  color: $black;
  &:hover {
    text-decoration: none;
  }
}

.inner-wrapper {
  padding-bottom: rem(60px);
  background: linear-gradient(180deg, $grey 0%, $white 25%);
  position: relative;
}

@media (max-width: 1023px) {
}

@media screen and (max-width: 767px) {
  .section-container {
    display: block;
  }

  .section {
    padding-left: 0;
    padding-right: 0;
  }

  .img-container,
  .column {
    width: 100%;
  }

  .img-container {
    margin-bottom: rem(20px);
  }
}

.pdf-button {
  color: $black;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  text-align: left;
}
</style>
