<template>
  <div :id="`ms-mod-video--${id}`" class="ms-mod-video mesh">
    <div class="inner-wrapper">
      <VideoChapterizedPage>
        <template #header>{{ video.title }}</template>
        <template #video-copy>{{ video.desc }}</template>
        <template #video-content>
          <div>
            <VimeoVideoEmbed
              :videoData="video"
              :currentTime="currentTime"
              :setTheTimelineFlag="setTheTimeline"
              @setCurrentTimeUpdate="setCurrentTime"
              @updateTheTimelineFlag="updateTheTimelineFlag"
            />
            <div class="timeline-container">
              <div
                v-for="card in timeline"
                @click="
                  setCurrentTime(card.timeInSeconds)
                  updateTheTimelineFlag(true)
                "
                class="timeline-list"
              >
                <span class="time-show-text">
                  {{ card.timeToShow }}
                </span>
                <span>-</span>
                <span>
                  {{ card.title }}
                </span>
              </div>
            </div>
          </div>
          <!-- <KalturaVideo :videoData="video.kalturaData" /> -->
        </template>
        <template #transcript>
          <p>
            You know when patients are first diagnosed with MS, there's a lot of questions. I think they want to know what the disease is, what it means for them, how is this going to impact their life, how are they going to be treated?
          </p>
          <p>
            It's sometimes very challenging when you first diagnose a patient because you're making a diagnosis maybe when they're young, but you have to think about the larger picture of how are they going to be 20 or 30 years down the road? And that maybe looks very different as compared to when they’re young.
          </p>
          <p>
            My name's Elizabeth Lucassen. I am a Medical Director at Novartis in the MS franchise in the Neuroscience Unit. And my background is as a clinical neurologist. I have a fellowship in multiple sclerosis, and I practiced as an MS specialist for nearly a decade before coming over to Novartis.
          </p>
          <p>
            Fear, early on in the disease, is a very prevalent thing. Again, it’s an unknown diagnosis. You have to provide a lot of education about what is this diagnosis, what it can potentially mean.  And you also want to, you know, again, not just stoke their fears but give them some reassurance that this is a very treatable disease.
          </p>
          <p>
            You know, there is still a big stigma about what the diagnosis of multiple sclerosis means.
          </p>
          <p>
            "Is this something that's going to land me in a wheelchair?" That leads to the discussion about what we can do to prevent that outcome, which, when we think about the natural history of multiple sclerosis, was very much a common outcome for patients, you know, maybe 15 years down the road in the era before there were disease-modifying therapies available.
          </p>
          <p>
            So, I think, you know, trying to have patients understand that, "Hey, we have great options, this is a very treatable disease. Yes, this is a disease that you will carry with you for the rest of your life, but it's something that we can do something about. It's not just 'Diagnose and adios.'
          </p>
          <p>
            Up to two-thirds of those diagnosed with MS will have some form of cognitive impairment. It’s the combination of the loss of neurons, loss of connections, that is likely leading to cognitive impairment.
          </p>
          <p>
            The longer you go and the more disease burden you have, the more likely it is to show up. But it certainly can happen at any point.
          </p>
          <p>
            My name is Dr Jason Freeman. I’m a neurologist and Medical Director with the Novartis US Clinical Development and Medical Affairs Division Neuroscience franchise.
          </p>
          <p>
            Cognitive functioning in MS can present in a variety of fashions, including difficulties with attention, difficulty with processing speed, problems with working memory, and especially problems with overall executive functioning, and just a general sense of fogginess or slowness to processing that affects their daily lives.
          </p>
          <p>
            MS, as we know, can affect many parts of the brain, but what we see in terms of those who report an increased frequency of cognitive impairment is that there are often these T2 hyperintense lesions within the grey and white matter and certainly within the cortical and subcortical regions of the brain. These are the areas when impacted that are most likely to lead to cognitive dysfunction.
          </p>
          <p>
            And what we know about atrophy or loss of brain tissue and neurons is that, the more atrophy that there is, in general, the more cognitive dysfunction a patient may display.
          </p>
          <p>
            Despite significant cerebral injury or cortical injury, there are up to a third of patients who continue to have normal levels of cognitive functioning. And we believe that’s largely due to cognitive reserve and underlying brain plasticity.
          </p>
          <p>
            Our goal for therapy is always to have, you know, no disease activity. So, that means MRIs are not changing over time, patients aren't having relapses, they're not having progression of symptoms.
          </p>
          <p>
            But I think a lot of that disease activity early on at some point down the road can come back to us, in the form of secondary progressive MS. And, you know, this often goes along with that aging of the brain and spinal cord where we lose some of that neurological reserve. And so, things that may have not been problems before, like, you know, cognitive function, may become problematic.
          </p>
          <p>
            Again, it has often to do with the lesion burden and, again, their cognitive reserve at baseline. So, I think that can be one of the challenging things is that, you know, certain symptoms may pop up again. They may have been related to prior relapses that had, you know, gotten much better earlier on in the disease, but then they start to come back, again, become more prominent.
          </p>
          <p>
            The more we stay active, the more we stay engaged, the higher the likelihood that, despite the damage that we can see and the damage that we can’t, you'll be able to remain a functional patient.
          </p>
          <p>
            Some of the things that patients can do to promote cognitive reserve are really to remain active and engaged. So what does that mean? Well, number one, I would look to self-care. Things like meditation, yoga, daily exercise, or a regular exercise routine, are all things that are going to help stimulate our brains, stimulate our minds, and help to build and keep in reserve overall cognitive functioning.
          </p>
          <p>
            Being involved with family members, church groups, support groups; we know that things like music therapy help as well. There is evidence that when you look at things like EDSS and, you know, other sort of measures of disability over time, that those who are engaged in things like music therapy in general will function better.
          </p>
          <p>
            We should largely be looking at exercise and regular exercise routines as a part of treatment or as a part of therapy. And we know that the more you stay active, the better you will do long-term.
          </p>
          <p>
            I’d rather have a patient who is having great difficulty but still engaging in a normal—or whatever’s normal to them—exercise routine than not. That’s a patient who, as the disease gets worse, will figure out other ways and coping strategies to combat the disease.
          </p>
          <p>
            In the nature of clinical practice, there’s only so much time that you have with each patient. And so, it’s usually very difficult to spend a lot of time talking about these sort of quality of life issues and other sort of nonpharmacologic interventions that may ultimately improve outcomes.
          </p>
          <p>
            Giving them a therapy or a treatment is only a part of the story. One of the things that I do in my own clinical practice is really try and get to know a patient. To me, the most important part of the history is actually the social history: Who are you? Where are you from? Where did you grow up? Where did you go to school? Are you married? Do you have kids?
          </p>
          <p>
            Those sorts of things really inform who your patient is. And so, it’s only when you know those things about a patient that you really can begin speaking to them and sort of investigating some of the nonpharmacologic interventions that may have a big impact on their life.
          </p>
          <p>
            So again, I think we want to provide information, but also allay patients' fears and let them know that, you know, we do have treatments that can hopefully alter that outcome. But we do have that limited period of time where we really can act on that.
          </p>
          <p>
            When I went into neurology, I think there was, you know, a lot of interesting diseases, one of the things that drew me to multiple sclerosis is it's a very treatable disease.
          </p>    
          <p>
            We're, you know, constantly pushing the boundaries and understanding the disease better about how we can actually impact these patients in a beneficial way. Even just really, really, early on, changing the outcome, changing that trajectory for them.
          </p> 
          <p class="date-job-code">
            <span class="date">4/24</span>
            <span class="job-code">400453</span>
          </p>
        </template>
        <template #related-content>
          <h2>You may also be interested in:</h2>
          <ul>
            <li>
              <button class="pdf-button" @click="showPdf(pdfs.progression[0])">
                <img
                  class="thumbnail"
                  src="@/assets/img/thumbnail-insights-into-ms.png"
                  alt=""
                />
                <img src="@/assets/img/icon-pdf-black.svg" alt="PDF" />Insights Into MS:
                Cognitive Functioning
              </button>
            </li>
            <li>
              <a href="#" @click="showApp(apps.recognizing)">
                <img
                  class="thumbnail"
                  src="@/assets/img/thumbnail-int-early-ms.png"
                  alt=""
                />
                <img
                  src="@/assets/img/icon-interactive-blk.svg"
                  alt="Interactive app"
                />Early MS Symptoms
              </a>
            </li>
            <li>
              <button class="pdf-button" @click="showPdf(pdfs.signs[0])">
                <img
                  class="thumbnail"
                  src="@/assets/img/thumbnail-placeholder.png"
                  alt=""
                />
                <img src="@/assets/img/icon-pdf-black.svg" alt="PDF" />Insights Into MS:
                Patient Perspectives on Progression
              </button>
            </li>
          </ul>
        </template>
      </VideoChapterizedPage>
      <RefsModal v-if="$store.state.showModal === 'references'">
        <template #refs-list>
          <ol class="refs-list">
            <li>
              MultipleSclerosis.net. MS In America 2016: Being Diagnosed with Multiple Sclerosis. Health Union, LLC. Accessed April 10, 2024. https://multiplesclerosis.net/living-with-ms/ms-in-america-2016-being-diagnosed-with-multiple-sclerosis
            </li>
            <li>
              Burtchell J, Fetty K, Miller K, Minden K, Kantor D. Two Sides to Every Story: Perspectives from Four Patients and a Healthcare Professional on Multiple Sclerosis Disease Progression. <em>Neurol Ther.</em> 2019;8(2):185-205. doi:10.1007/s40120-019-0141-4
            </li>
            <li>
              Thompson AJ, Baranzini SE, Geurts J, Hemmer B, Ciccarelli O. Multiple sclerosis. <em>Lancet.</em> 2018;391(10130):1622-1636. doi:10.1016/S0140-6736(18)30481-1
            </li>
            <li>
              Rieckmann P, Centonze D, Elovaara I, et al. Unmet needs, burden of treatment, and patient engagement in multiple sclerosis: A combined perspective from the MS in the 21st Century Steering Group. <em>Mult Scler Relat Disord.</em> 2018;19:153-160. doi:10.1016/j.msard.2017.11.013
            </li>
            <li>
              Kister I, Bacon TE, Chamot E, et al. Natural history of multiple sclerosis symptoms. <em>Int J MS Care.</em> 2013;15(3):146-158. doi:10.7224/1537-2073.2012-053
            </li>
            <li>
              Giovannoni G, Butzkueven H, Dhib-Jalbut S, et al. Brain health: time matters in multiple sclerosis. <em>Mult Scler Relat Disord.</em> 2016;9:S5-S48. doi:10.1016/j.msard.2016.07.003
            </li>
            <li>
              Gil-González I, Martín-Rodríguez A, Conrad R, Pérez-San-Gregorio MÁ. Quality of life in adults with multiple sclerosis: a systematic review. <em>BMJ Open.</em> 2020;10(11):e041249. doi:10.1136/bmjopen-2020-04124
            </li>
            <li>
              Kantor D, Bright JR, Burtchell J. Perspectives from the patient and the healthcare professional in multiple sclerosis: social media and patient education. <em>Neurol Ther.</em> 2018;7(1):23-36. doi:10.1007/s40120-017-0087-3
            </li>
            <li>
              Desborough J, Brunoro C, Parkinson A, et al. 'It struck at the heart of who I thought I was': a meta-synthesis of the qualitative literature examining the experiences of people with multiple sclerosis. <em>Health Expect.</em> 2020;23(5):1007-1027. doi:10.1111/hex.13093
            </li>
            <li>
              Filippi M, Preziosa P, Banwell BL, et al. Assessment of lesions on magnetic resonance imaging in multiple sclerosis: practical guidelines. <em>Brain.</em> 2019;142(7):1858-1875. doi:10.1093/brain/awz144
            </li>
            <li>
              Van Schependom J, Nagels G. Targeting cognitive impairment in multiple sclerosis—the road toward an imaging-based biomarker. <em>Front Neurosci.</em> 2017;11:380. doi:10.3389/fnins.2017.00380
            </li>
            <li>
              Ziemssen T, Derfuss T, de Stefano N, et al. Optimizing treatment success in multiple sclerosis. <em>J Neurol.</em> 2016;263(6):1053-1065. doi:10.1007/s00415-015-7986-y
            </li>
            <li>
              Bristol Myers Squibb. MS in Harmony: helping people living with multiple sclerosis achieve mind-body harmony through musictherapy. Accessed April 10, 2024. https://www.bms.com/life-and-science/news-and-perspectives/ms-in-harmony-music-therapy-for-multiple-sclerosis-patients.html 
            </li>
            <li>
              Miller E, Morel A, Redlicka J, et al. Pharmacological and non-pharmacological therapies of cognitive impairment in multiple sclerosis. <em>Curr Neuropharmacol.</em> 2018;16(4):475-483. doi:10.2174/1570159X15666171109132650
            </li>
          </ol>
        </template>
      </RefsModal>
    </div>
  </div>
</template>

<script>
// import KalturaVideo from "@/components/KalturaVideo";
import VideoChapterizedPage from "@/components/VideoChapterizedPage";
import VimeoVideoEmbed from "@/components/VimeoVideoEmbed";
import RefsModal from "@/components/RefsModal";

export default {
  name: "CognitiveDeclineVideo",
  components: {
    // KalturaVideo,
    VideoChapterizedPage,
    VimeoVideoEmbed,
    RefsModal,
  },
  data() {
    return {
      id: this.$router.currentRoute.params.id,
      video: {
        title: "Cognitive Decline in MS Progression",
        desc: "Learn how preserving neurologic function can delay cognitive decline associated with MS progression.",
        vimeoId: "941308169?h=bf11be4997",
        eventLabel: "Cognitive Decline in MS Progression",
        kalturaData: {
          eventLabel: "Cognitive Decline in MS Progression",
          id: 1603811077,
          uiConfId: "34239831",
          entryId: "1_dl4jho9c",
        },
      },
      setTheTimeline: false,
      timeline: [
        {
          title: "Chapter 1: Diagnosing MS",
          timeToShow: "0:06",
          timeInSeconds: 6,
        },
        {
          title: "Chapter 2: Cognitive Impairment in MS",
          timeToShow: "1:52",
          timeInSeconds: 112,
        },
        {
          title:
            "Chapter 3: Neurological Reserve",
          timeToShow: "3:32",
          timeInSeconds: 212,
        },
        {
          title: "Chapter 4: Preserving Cognitive/Neurologic Reserve",
          timeToShow: "4:45",
          timeInSeconds: 285,
        },
        {
          title: "Chapter 5: Health Care Provider - Patient Relationship",
          timeToShow: "6:22",
          timeInSeconds: 382,
        },
      ],
      currentTime: 0,
      apps: {
        recognizing: {
          title: "Signs and symptoms",
          urlDev: "/diagnosis/early-symptoms",
          urlProd: "/diagnosis/early-symptoms",
        },
      },
      pdfs: {
        progression: [
          {
            src: "cognitive-impairment.pdf",
            analyticsLabel: "Video: Cognitive impairment",
          },
        ],
        signs: [
          {
            src: "patient-views-on-disease-progression.pdf",
            analyticsLabel: "Video: Patient perspectives on MS progression",
          },
        ],
      },
    };
  },
  methods: {
    showApp: function (app) {
      this.$gtag.event("click", {
        event_category: "launch_page",
        event_label: app.analyticsLabel,
      });

      let url =
        process.env.NODE_ENV === "development" ? app.urlDev : app.urlProd;

      window.open(url, "_self");
    },

    setCurrentTime(seconds) {
      this.currentTime = seconds
    },

    updateTheTimelineFlag(val) {
      this.setTheTimeline = val
    },

    showPdf: function (data) {
      this.$router.push({ name: data.src.split(".")[0] });

      this.$gtag.event("click", {
        event_category: "view_pdf",
        event_label: `${data.analyticsLabel}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ms-mod-video {
  color: $black;
  background: rgb(255, 255, 255);
  position: relative;
}
.mesh {
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    pointer-events: none;
    background-image: url("~@/assets/img/video-bg.png");
    top: 0;
    right: 0;
  }
}

.timeline-container {
  margin-top: 20px;
}

.timeline-list {
  padding-top: 5px;
  cursor: pointer;
}

.time-show-text {
  font-weight: 600;
  text-decoration: underline;
  text-underline-offset: 0.25rem;
}

.tactic-btn {
  border: 1px solid $black;
  background: none;
  color: $black;
  padding: rem(10px) rem(25px);
  text-align: left;
  position: relative;
  width: 100%;
  min-height: rem(60px);
  display: block;
  margin-bottom: rem(20px);

  &:last-child {
    margin-bottom: 0px;
  }
}

.tactic-btn-label {
  float: left;
  font-size: rem(18px);
  font-weight: $font-weight-bold;
  width: calc(100% - 50px);
  position: absolute;
  top: 50%;
  left: rem(20px);
  transform: translate(0%, -50%);
}

.tactic-btn-icon {
  float: left;
  width: rem(20px);
  margin-left: rem(30px);
  position: absolute;
  top: 50%;
  right: rem(20px);
  transform: translate(0%, -50%);

  img {
    width: 100%;
  }
  &.launch-icon {
    width: rem(27px);
  }
}

.link {
  border: none;
  background: none;
  color: $black;
  display: flex;
  align-items: center;
  text-align: left;
  padding-left: 0;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

:deep(.audio-player) {
  .audio-element-container {
    width: 33% !important;
  }
}
</style>
