<template>
  <div :id="`${id}`" class="podcast-page mesh">
    <div class="inner-wrapper">
      <PodcastPage>
        <template #header>{{ audio.title }}</template>
        <template #podcast-asset><img src="~@/assets/img/silent-progression-podcast.png" alt="Sound waves behind 3D images of a woman progressing on MS"/></template>
        <template #podcast-copy>{{ audio.desc }}</template>
        <template #podcast-content>
          <AudioPlayer :data="audio" :key="audio.id" />
        </template>

        <template #transcript>
          <p><strong>Jason Freeman</strong></p>
          <p>
            This podcast is sponsored by Novartis Pharmaceuticals. The
            information herein is provided for disease educational purposes
            only, and is not intended to be, nor does it imply, medical or
            diagnostic advice.
          </p>
          <p>
            Welcome everyone. My name is Dr Jason Freeman, I am a neurologist
            and medical director with Novartis Pharmaceuticals. And for today's
            topic, I'm gonna welcome our guest, Dr Stephen Yeung, also a
            neurologist, and more importantly, an MS specialist. He too works
            for Novartis Pharmaceuticals.
          </p>
          <p>
            So, today's topic is assessing silent progression in multiple
            sclerosis. So, multiple sclerosis, or MS, is a demyelinating
            disorder that affects the central nervous system. It's primarily
            diagnosed in young adults and has a higher prevalence in women and
            young women than it does in men. As we think about how patients with
            multiple sclerosis often present, we typically think of signs and
            symptoms such as weakness or difficulty walking, sometimes changes
            in vision, but there are a large number of patients who have what we
            describe as silent symptoms and undergo the process of silent
            progression over time.<sup>1</sup> And so, for today's topic, we're
            going to focus on how to assess silent progression in multiple
            sclerosis.
          </p>
          <p>
            So, Dr Yeung, can you talk to us about the subtypes that define
            multiple sclerosis? And, in addition to doing that, can you tell us
            how they're actually characterized? What makes up each one of these
            subtypes?
          </p>
          <p><strong>Stephen Yeung</strong></p>
          <p>
            In general, I think when anyone approaches multiple sclerosis, the 2
            main subtypes are relapsing disease or non-relapsing disease.<sup
              >2</sup
            >
            You know, 85% of multiple sclerosis is categorized as a relapsing
            type, or relapsing-remitting MS, if you will, or RRMS, is by far,
            comprises the bulk of this disease. Primary progressive MS then,
            therefore, picks up the remaining 10, 15% of the disease
            subtype.<sup>1,3</sup>
          </p>
          <p>
            However, I think it's really more important to focus on relapsing MS
            as a spectrum of the disease, and this has been really expanded on
            in recent years.<sup>3</sup> So now we have 2 different subtypes,
            clinically isolated syndrome, or CIS, and radiologically isolated
            syndrome, or RIS, and both of these can be viewed or thought of as a
            precursor. These subtypes will typically precede a full clinical
            diagnosis of MS.<sup>1,3</sup>
          </p>
          <p>
            So, the majority of patients with MS are diagnosed then to go on and
            transition into a progressively deteriorating state or phase, and
            this is known as secondary progressive MS, or SPMS. And this
            typically happens within 15 to 20 years after the initial
            diagnoses.<sup>3,4</sup>
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            Great, thanks. In the 2017 McDonald criteria, a diagnosis of MS is
            confirmed based on the presence of a clinical attack, flare up, or
            an exacerbation, defined as an unpredictable episode of neurologic
            dysfunction owing to demyelinating lesions in the optic nerve,
            spinal cord, brainstem, cerebellum, or the cerebral hemispheres.<sup
              >2,3</sup
            >
            However, increasingly in the literature, we are learning that
            patients with MS experience more subtle symptoms that may be a sign
            of silent progression.<sup>1,5</sup>
          </p>
          <p>
            So, Dr Yeung, what exactly does this concept of silent progression
            mean for clinicians who treat patients with MS? And more
            importantly, how can they understand what are some of the cues of
            silent progressions in order to better track disease activity in
            their patients?
          </p>
          <p><strong>Stephen Yeung</strong></p>
          <p>
            So, I think health care providers are generally very well versed in
            recognizing and discussing symptoms of MS that are objectively
            measured if time permitting in the office setting with patients.<sup
              >6</sup
            >
            So, I think the challenges of identifying and discussing and really
            putting a finger on how to measure invisible or silent symptoms
            boils down to purely a lack of time that providers are able to spend
            with patients.
          </p>
          <p>
            So, the common silent symptoms really includes things difficult to
            measure, such as fatigue or mood changes, cognitive changes,
            sometimes physical and emotional pain. These symptoms certainly can
            impact mental health, social lifestyle, employment, and just really
            daily living and quality of life.<sup>6</sup> So, you know, finding
            the time to probe and discuss these topics with patients is
            paramount to better identifying and really being able to measure
            this silent progression.
          </p>
          <p>Jason Freeman:</p>
          <p>
            Got it. So again, things like fatigue, mood changes, cognitive
            changes, changes in employment, lifestyle, living, these are often
            the silent symptoms that patients and/or their families are really
            sort of suffering through. And what I hear you saying is, we as
            neurologists are so used to sort of getting into our physical exam
            and our neurological exam, that sometimes we miss the opportunity to
            discuss these things and sort of investigate these things further.
            Does that sound about right?
          </p>
          <p><strong>Stephen Yeung</strong></p>
          <p>
            Absolutely. And I think that for the neurologists who are used to
            having discussions in patients and families with other chronic
            diseases like dementia, for example. Similarly, I think it's
            worthwhile to bring in family or partners and open that conversation
            and use as a tool to kind of see what is happening in that patient's
            life. It gets some outside perspective or more intimate perspective
            of what happens with the patient outside of that isolated, very
            artificial medical encounter.
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            Are there other tests that you rely on, or other sort of markers of
            disease that you include in your visit with patients when trying to
            assess for silent progression?
          </p>
          <p><strong>Stephen Yeung</strong></p>
          <p>
            So, going back to objective markers, I think that we're all very
            comfortable with MRI technology and so, when you're going back to
            the MRI, I think we're putting more emphasis on looking at brain
            atrophy, specifically cerebral cortex atrophy. That may be a sign of
            PIRA, or progression independent of relapse activity.<sup>7,8</sup>
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            So, Dr Yeung, besides the typical tools of maybe an MRI of the brain
            or spine, or typically our physical and neurologic exams, what tools
            out there are available really to assess for that silent disease
            progression in multiple sclerosis?
          </p>
          <p><strong>Stephen Yeung</strong></p>
          <p>
            So, there’s certainly a void, or a lacking in how we can measure, or
            even better identify, some of the silent progression that patients
            often will note or report. So, there is a growing base for putting
            more emphasis on patient-reported outcomes, or PROs. And this can be
            seen in clinical trials. These PROs, or patient-reported outcomes,
            really provides insights on disease progression, understanding how
            activities of daily life or quality of life changes over time.<sup
              >9</sup
            >
          </p>
          <p>
            Most clinicians don't use formal assessments for fatigue or
            cognition during routine visits, as they can certainly be very time
            consuming. So, I think there are, however, brief tools such as the
            Fatigue Impact Scale, or FIS, and Unidimensional Fatigue Impact
            Scale, or UFIS.<sup>9</sup> But again, I think that the bottom line
            really is that asking patients or spending time trying to discuss
            some of these metrics that they may be doing or participating in on
            their own, such as diaries, or checklists, or other things they may
            have found on their own and employed in their day-to-day life.
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            Great. So, I'm also thinking of things like maybe step count if
            you're using a Fitbit or an Apple Watch. I certainly have heard of
            lots of patients out there who use symptom trackers or disease
            trackers. Some of these are paper-based, some of these are apps that
            are on their phones or iPads, but it sounds like you're really
            saying just make sure that, in addition to your exam, in addition to
            your scans, you're asking about whether or not they're utilizing
            these as another way of assessing for disease progression.
          </p>
          <p><strong>Stephen Yeung</strong></p>
          <p>
            Absolutely. I think that a multifactorial approach, kind of
            tackling, identifying, and discussing these different aspects of
            progression really should be considered for optimal patient care.
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            So, Dr Yeung, we've talked a little bit about how to identify and/or
            inquire with patients and families about some of the silent
            progression in multiple sclerosis, but what about some of the ways
            in which we need to manage silent progression, especially early in
            patients with multiple sclerosis?
          </p>
          <p><strong>Stephen Yeung</strong></p>
          <p>
            So, I think it's really important that clinicians encourage and
            empower their patients to become more engaged in taking part in
            their treatment, promoting strategies that are associated with
            improving treatment adherence. There still remains great needs in
            the domain of finding better biomarkers for identifying and
            measuring disease activity and progression.
          </p>
          <p>
            Ultimately, I think that the current research, it does remain
            promising, and although there is nothing available as of now to
            really predict silent progression in patients with MS,<sup>7</sup>
            and I think that seeing tools soon to emerge, hopefully will give
            the field a great objective marker that we can easily use to measure
            and quantitate progression.<sup>5,10</sup>
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            Got it. And just to summarize that, I guess what I hear you saying
            is that silent progression is an important topic, just as important
            as someone with true neurologic dysfunction that we can measure on
            an exam or see on an MRI, and it likely is going to play out into a
            patient's long-term disability status. Does that sound about right?
          </p>
          <p><strong>Stephen Yeung</strong></p>
          <p>Yeah, absolutely. I think that's really well put.</p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            Thank you for listening to this podcast on assessing silent
            progression in patients with multiple sclerosis. I hope you will
            explore the other resources on our website, MSHCPeducation.com, for
            health care providers who manage patients with MS.
          </p>
          <p class="date-job-code">
            <span class="date">2/23</span> <span class="job-code">254677</span>
          </p>
        </template>
      </PodcastPage>

      <RefsModal v-if="$store.state.showModal === 'references'">
        <template #refs-list>
          <ol class="refs-list">
            <li>
              Giovannoni G, Butzkueven H, Dhib-Jalbut S, et al. Brain health:
              time matters in multiple sclerosis.
              <em>Mult Scler Relat Disord</em>. 2016;9:S5-S48.
              doi:10.1016/j.msard.2016.07.003
            </li>
            <li>
              Thompson AJ, Banwell BL, Barkhof F, et al. Diagnosis of multiple
              sclerosis: 2017 revisions of the McDonald criteria.
              <em>Lancet Neurol</em>. 2018;17(2):162-173.
              doi:10.1016/S1474-4422(17)30470-2
            </li>
            <li>
              Dobson R, Giovannoni G. Multiple sclerosis – a review.
              <em> Eur J Neurol </em>. 2019;26(1):27-40. doi:10.1111/ene.13819
            </li>
            <li>
              Davies F, Wood F, Brain KE, et al. The transition to secondary
              progressive multiple sclerosis: an exploratory qualitative study
              of health professionals’ experiences.<em> Int J MS Care</em>.
              2016;18(5):257-264. doi:10.7224/1537-2073.2015-062
            </li>
            <li>
              Sandi D, Fricska-Nagy Z, Bencsik K, Vécsei L. Neurodegeneration in
              multiple sclerosis: symptoms of silent progression, biomarkers and
              neuroprotective therapy—kynurenines are important players.
              <em>Molecules</em>. 2021;26(11). doi:10.3390/molecules26113423
            </li>
            <li>
              Lakin L, Davis BE, Binns CC, Currie KM, Rensel MR. Comprehensive
              approach to management of multiple sclerosis: addressing invisible
              symptoms—a narrative review. <em>Neurol Ther</em>.
              2021;10(1):75-98. doi:10.1007/s40120-021-00239-2
            </li>
            <li>
              Cagol A, Schaedelin S, Barakovic M, et al. Association of brain
              atrophy with disease progression independent of relapse activity
              in patients with relapsing multiple sclerosis.
              <em>JAMA Neurol</em>. 2022;79(7):682-692.
              doi:10.1001/jamaneurol.2022.1025
            </li>
            <li>
              Lublin FD, Häring DA, Ganjgahi H, et al. How patients with
              multiple sclerosis acquire disability. <em>Brain</em>.
              2022;145(9):3147-3161. doi:10.1093/brain/awac016
            </li>
            <li>
              Tur C, Moccia M, Barkhof F, et al. Assessing treatment outcomes in
              multiple sclerosis trials and in the clinical setting.
              <em> Nat Rev Neurol </em>. 2018;14(2):75-93.
              doi:10.1038/nrneurol.2017.171
            </li>
            <li>
              Kuhle J, Kropshofer H, Haering DA, et al. Blood neurofilament
              light chain as a biomarker of MS disease activity and treatment
              response. <em>Neurology</em>. 2019;92(10):E1007-E1015.
              doi:10.1212/WNL.0000000000007032
            </li>
          </ol>
        </template>
      </RefsModal>
    </div>
  </div>
</template>

<script>
import PodcastPage from "@/components/PodcastPage";
import RefsModal from "@/components/RefsModal";

export default {
  name: "SilentProgressionPodcast",
  components: {
    PodcastPage,
    RefsModal,
  },
  data() {
    return {
      id: this.$router.currentRoute.params.id,
      audio: {
        id: "progression-audio2",
        title: "Assessing Silent Progression Podcast",
        src: "assets/audio/assessing_silent_progression.mp3",
        analyticsLabel: "Assessing Silent Progression",
        desc: "Dr Jason Freeman, neurologist (Medical Director, Novartis), and Dr Stephen Yeung, MS specialist (Medical Director, Novartis), examine the clinical implications of assessing silent progression in MS.",
      },
    };
  },
  methods: {
    showPdf: function (data) {
      this.$router.push({ name: data.src.split(".")[0] });

      this.$gtag.event("click", {
        event_category: "view_pdf",
        event_label: `${data.analyticsLabel}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.podcast-page {
  color: $black;
  background: rgb(255, 255, 255);
  position: relative;
}
.mesh {
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    pointer-events: none;
    background-image: url("~@/assets/img/video-bg.png");
    top: 0;
    right: 0;
  }
}

.tactic-btn {
  border: 1px solid $white;
  background: none;
  color: $white;
  padding: rem(10px) rem(25px);
  text-align: left;
  position: relative;
  width: 100%;
  min-height: rem(60px);
  display: block;
  margin-bottom: rem(20px);

  &:last-child {
    margin-bottom: 0px;
  }
}

.tactic-btn-label {
  float: left;
  font-size: rem(18px);
  font-weight: $font-weight-bold;
  width: calc(100% - 50px);
  position: absolute;
  top: 50%;
  left: rem(20px);
  transform: translate(0%, -50%);
}

.tactic-btn-icon {
  float: left;
  width: rem(20px);
  margin-left: rem(30px);
  position: absolute;
  top: 50%;
  right: rem(20px);
  transform: translate(0%, -50%);

  img {
    width: 100%;
  }
  &.launch-icon {
    width: rem(27px);
  }
}

:deep(.audio-title) {
  display: none !important;
}
</style>
