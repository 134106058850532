export const ItemLinkTypes = Object.freeze({
  INTERNAL: "internal",
  EXTERNAL: "external",
  PDF: "pdf",
});

export const KalturaParams = Object.freeze({
  PARTNER_ID: "2076321",
});

let uids = {
  dev: "G-V8VBHLV7NB",
  prod: "G-N1ESC8LEJM",
  // prod: "G-Z43H6BMYER",
};
// let uids = {
//   dev: "UA-100596251-37",
//   prod: "UA-100596251-38"
// }

export const currUID =
  process.env.NODE_ENV === "development" ? uids.dev : uids.prod;
