<template>
  <div :id="`${id}`" class="podcast-page mesh">
    <div class="inner-wrapper">
      <PodcastPage>
        <template #header>{{ audio.title }}</template>
        <template #podcast-asset><img src="~@/assets/img/empowering-patients-podcast.png" alt="Sound waves behind a female patient and male doctor conversing"/></template>
        <template #podcast-copy>{{ audio.desc }}</template>
        <template #podcast-content>
          <AudioPlayer :data="audio" :key="audio.id" />
        </template>

        <template #transcript>
          <p><strong>Jason Freeman</strong></p>
          <p>
            This podcast is sponsored by Novartis Pharmaceuticals. The
            information herein is provided for disease educational purposes
            only, and is not intended to be, nor does it imply, medical or
            diagnostic advice.
          </p>
          <p>
            Hi, my name is Dr Jason Freeman. I am a neurologist and medical
            director with Novartis Pharmaceuticals. I'd like to introduce Dr
            Yousuf Ali, a senior medical director also with Novartis
            Pharmaceuticals. For today's topic, we'd like to discuss the role of
            patient empowerment strategies as a tool for health care providers
            who treat patients with multiple sclerosis, or MS.
          </p>
          <p>
            So, Dr Ali, there's considerable evidence on the benefits of
            promoting patient engagement and empowerment in the management of
            patients with MS. What exactly does this mean?
          </p>
          <p><strong>Yousuf Ali</strong></p>
          <p>
            You know, Dr Freeman, we hear frequently about patient empowerment
            when we are talking about models that promote patient-centered care.
            Looking at literature, a generally accepted definition of
            patient-centered care is a patient's ability to control their health
            as well as their ability to be more involved in making decisions
            around their own care.<sup>1</sup>
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            Got it. And so, as we think about and define patient empowerment,
            how do we do that then in a way that's useful for health care
            providers who then manage patients with MS? What are the things that
            they need to do when that patient is in front of them?
          </p>
          <p><strong>Yousuf Ali</strong></p>
          <p>
            Yeah. Historically, health care providers have made treatment
            decisions independently of patient preferences. So, when we truly
            want to empower patients with MS, this can be done by educating them
            to make informed decisions and ensuring they understand the risks
            and consequences of their choices.<sup>2</sup> And ideally, the
            foundation of patient empowerment is a collaborative, open
            relationship between the patient and their care providers, where
            there is ample opportunity to discover similarities as well as
            disparities in patient care and their unmet needs.<sup>2,3</sup>
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            So, essentially it sounds like we're sort of advising those who
            manage patients to move away from that traditional, paternalistic
            way of practice to a more collaborative approach, especially when it
            comes to therapies and treatment options.
          </p>
          <p><strong>Yousuf Ali</strong></p>
          <p>
            Exactly, and we are, ideally, we are looking at giving patients the
            ownership of their own health care through this shared
            decision-making process that incorporates, and recognizes and
            incorporates patient perspectives at every single step of their
            treatment and care.
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            Excellent. Each patient is unique: Their symptoms, their worries,
            their priorities, and their individual MS journey. They also have
            different interests and abilities when it comes to learning about
            MS. So how can clinicians initiate the process of educating their
            patients and getting them engaged in the decision-making process?
          </p>
          <p><strong>Yousuf Ali</strong></p>
          <p>
            Dr Freeman, you hit the nail on its head when you said each patient
            is unique. They all have different abilities to comprehend medical
            information, and they all have different emotional states and
            readiness for processing and accepting their diagnosis of MS. For
            some patients, the diagnosis is a relief, knowing that there is a
            path forward, whereas for others this diagnosis may be perceived as
            the end of this normal life or interrupting all their life plans.
          </p>
          <p>
            So, if you want the patient to be that equal stakeholder in the
            shared decision-making process, you want them to trust their health
            care team. And what a health care provider can do is deliver the
            diagnosis with empathy, listen to their patients, and more
            importantly, provide educational materials and access to these
            credible resources by encouraging their patients to ask questions,
            by tailoring it to their patient's unique priorities so that they
            treat every patient uniquely in this shared decision-making
            process.<sup>2,4</sup>
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            So, Dr Ali, what are some strategies that health care providers can
            use to encourage empowerment in their patients?
          </p>
          <p><strong>Yousuf Ali</strong></p>
          <p>
            Sure. So, Dr Freeman, we learned already from literature that
            patient priorities may not always be the same as those of their
            neurologists. Many neurologists are understandably focused on
            slowing disease progression, but at the same time, many patients on
            the other hand are more concerned with the impact of their silent
            symptoms on their daily activities and the quality of life, such as
            fatigue or cognitive function.<sup>2,5,6</sup> So, it's very
            important that the neurologists and the patient are on the same page
            from the very beginning.
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            So then, one strategy is just making sure that there's really ideal
            alignment in terms of what are the goals here, what is it that
            you're focused on vs what I'm focused on. What are some other
            strategies out there for empowering patients?
          </p>
          <p><strong>Yousuf Ali</strong></p>
          <p>
            Most patients will want a recommendation from their health care
            provider that is tailored to their preference, and that can relate
            to the types of treatment. For instance, there the impact of the
            route of administration when it comes to a DMT selection, as well as
            the side effects, they can be a concern for patients, depending on
            their unique needs.<sup>3,7,8</sup>
          </p>
          <p>
            First of all, just incorporating into the ongoing evaluation the
            quality of life and patient-reported outcomes.<sup>5</sup> Like, we
            mentioned cognitive functioning earlier into the discussion, and
            providing them not only credible resources that the patient
            understands their disease, but also including their unique needs and
            lifestyles into the discussion of treatment would not only encourage
            treatment adherence, but also bring the patient as that equally
            empowered stakeholder in the shared decision-making process.<sup
              >5</sup
            >
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            Got it. So, on one hand it's about aligning our objectives, on the
            other hand, in terms of thinking about treatment and treatment
            strategies, it's making sure that they have good knowledge and
            materials with respect to which therapy to choose and not just be
            sort of told this is what you should do. What are some additional
            things that we should take into account or strategies for empowering
            patients beyond treatments?
          </p>
          <p><strong>Yousuf Ali</strong></p>
          <p>
            So, on one hand, ensuring, as you said, the treatment plans reflect
            patient preference and their priorities. Also, patients want to hear
            how their lifestyle strategies can help manage their symptoms. For
            example, eating healthy, exercising, managing stress, all those are
            very important.<sup>5,7</sup> And by talking about how these things
            uniquely affect each patient's lives, you are bringing them into the
            decision-making process, ensuring that they are adhering to their
            treatment, but also are very responsible in managing their
            disease.<sup>3</sup>
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            Is there any support or evidence to show how patient engagement and
            empowerment may benefit their MS disease or MS journey?
          </p>
          <p><strong>Yousuf Ali</strong></p>
          <p>
            There is growing evidence to support the benefits of patient
            engagement on their MS disease. In fact, patient engagement in the
            earlier stages of disease can enhance this feeling of empowerment
            and disease acceptance.<sup>3</sup> For example, patients who
            participated in a community-based fatigue management program
            reported a range of positive outcomes, including lifestyle and
            occupational changes, altered thinking about fatigue, and overall
            improved social support.<sup>5,9</sup>
          </p>
          <p>
            We also talk about impaired cognitive functioning as a symptom in
            MS. A patient-reported outcome study showed that cognitive
            self-rehabilitation conducted with computerized individual cognitive
            training programs clearly show encouraging spontaneous adherence in
            patients with MS.<sup>5</sup> So overall, patients who are engaged
            with their treatment plan often report higher satisfaction with
            care.<sup>3,4</sup>
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            Having patients who are happy with their care may also be considered
            a benefit for health care providers. Does empowering patients with
            MS provide other benefits to clinicians or to the overall health
            care system? How does empowering patients help a multidisciplinary
            health care?
          </p>
          <p><strong>Yousuf Ali</strong></p>
          <p>
            You know, Dr Freeman, empowered patients are known to seek control,
            coping skills, knowledge, and legitimacy in disease management.<sup
              >7</sup
            >
            There are so many benefits to empowering patients through education
            that it can not only positively impact communication and health
            literacy, but also provide confidence in their treatment
            decisions.<sup>3,4</sup> And, for example, we talked about fatigue
            and cognitive impairment as signs of progression in MS. When you
            educate patients on the importance of these types of symptoms and
            tracking their progression, it can help clinicians recognize
            progression earlier.<sup>5,8</sup> So, an empowered patient is aware
            of how their disease is progressing and this can allow them to
            discuss changing treatment strategies to help slow the progression.
          </p>
          <p>
            You talk about multidisciplinary teams, they can definitely support
            MS self-management with lifestyle approaches including diet, quality
            of life, and targeting unmet needs in patients with MS. An empowered
            and evidence-based continuity of care with a multidisciplinary
            health care team can definitely augment lifestyle changes that are
            more likely to be adopted and sustained in the long term by the
            patients.<sup>4,7</sup>
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            So, Dr Ali, we've talked a lot about patient empowerment and some of
            the benefits, but what are some of the potential risks that are
            associated with using this as a strategy? As we engage with
            patients, what things do we as clinicians need to really be aware of
            as we work with patients and their families?
          </p>
          <p><strong>Yousuf Ali</strong></p>
          <p>
            We all know there is an enormous amount of available information on
            the internet, and many patients are regularly using these resources
            to learn about their MS. It's very, very critical that the
            credibility of the information they are using is vetted to the
            physician and the physicians are ensuring that they're asking these
            patients questions about the resources they're using, and also
            recommending reliable websites where the patient can go and learn
            more about their disease.<sup>3,5</sup>
          </p>
          <p>
            The other thing I want to mention is the importance of clarifying
            the medical terminology, which is often used to describe disease
            progression, treatment, indications, and side effects. Oftentimes
            they might be unclear to patients, therefore physicians should
            listen to the patient's interpretation of the information and also
            take time to clarify some of these misconceptions.<sup>3–5</sup>
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            Dr Ali, thank you for your time today. This was an interesting
            discussion with respect to empowering patients, and I just want to
            give you a chance to discuss with us any final thoughts that you may
            have on the topic.
          </p>
          <p><strong>Yousuf Ali</strong></p>
          <p>
            Dr Freeman, thank you so much for this very lively discussion on how
            MS care is evolving and the fact that it is increasingly becoming
            patient-centered. Patient participation in care decisions may
            improve disease management and also improve patient-clinician
            relationships.<sup>2,4,5</sup>
          </p>
          <p>
            The MS landscape is evolving at a phenomenal rate, and as we
            continue to improve our understanding of this disease, it will be
            important to maintain this very patient-centered approach to care
            that helps patients become more comfortable with their disease,
            become equally educated stakeholders with the decisions around their
            disease, around managing their disease, and fostering active
            participation in MS self-management.<sup>2</sup>
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            Thank you for listening to this podcast on empowering patients with
            multiple sclerosis. I hope you'll explore the other resources
            available on our website, MSHCPeducation.com.
          </p>
          <p class="date-job-code">
            <span class="date">2/23</span> <span class="job-code">248047</span>
          </p>
        </template>
      </PodcastPage>

      <RefsModal v-if="$store.state.showModal === 'references'">
        <template #refs-list>
          <ol class="refs-list">
            <li>
              Pekonen A, Eloranta S, Stolt M, Virolainen P, Leino-Kilpi H.
              Measuring patient empowerment – a systematic review.
              <em> Patient Educ Couns</em>. 2020;103(4):777-787.
              doi:10.1016/j.pec.2019.10.019
            </li>
            <li>
              Yeandle D, Rieckmann P, Giovannoni G, Alexandri N, Langdon D.
              Patient power revolution in multiple sclerosis: navigating the new
              frontier. <em>Neurol Ther</em>. 2018;7(2):179-187.
              doi:10.1007/s40120-018-0118-8
            </li>
            <li>
              Rieckmann P, Centonze D, Elovaara I, et al. Unmet needs, burden of
              treatment, and patient engagement in multiple sclerosis: a
              combined perspective from the MS in the 21st Century Steering
              Group. <em>Mult Scler Relat Disord</em>. 2018;19:153-160.
              doi:10.1016/j.msard.2017.11.013
            </li>
            <li>
              Rieckmann P, Boyko A, Centonze D, et al. Achieving patient
              engagement in multiple sclerosis: a perspective from the multiple
              sclerosis in the 21st Century Steering Group.
              <em> Mult Scler Relat Disord </em>. 2015;4(3):202-218.
              doi:10.1016/j.msard.2015.02.005
            </li>
            <li>
              Lejbkowicz I, Caspi O, Miller A. Participatory medicine and
              patient empowerment towards personalized healthcare in multiple
              sclerosis. <em>Expert Rev Neurother</em>. 2012;12(3):343-352.
              doi:10.1586/ern.11.161
            </li>
            <li>
              Casanova B, Quintanilla-Bordás C, Gascón F. Escalation vs. early
              intense therapy in multiple sclerosis. <em>J Pers Med</em>.
              2022;12(119):1-14. doi:10.3390/jpm12010119
            </li>
            <li>
              Probst Y, Guan V, Van Der Walt A, Rath LM, Bonney A, Kent J.
              Patient self-management and empowerment for multiple sclerosis:
              the implications of dietary lifestyle management for primary care.
              <em>Aust J Gen Pract</em>. 2022;51(4):209-212.
              doi:10.31128/ajgp-09-21-6179
            </li>
            <li>
              Giovannoni G, Butzkueven H, Dhib-Jalbut S, et al. Brain health:
              time matters in multiple sclerosis.
              <em>Mult Scler Relat Disord</em>. 2016;9:S5-S48.
              doi:10.1016/j.msard.2016.07.003
            </li>
            <li>
              Gil-González I, Martín-Rodríguez A, Conrad R, Pérez-San-Gregorio
              MÁ. Quality of life in adults with multiple sclerosis: a
              systematic review. <em>BMJ Open</em>. 2020;10(11):e041249.
              doi:10.1136/bmjopen-2020-041249
            </li>
          </ol>
        </template>
      </RefsModal>
    </div>
  </div>
</template>

<script>
import PodcastPage from "@/components/PodcastPage";
import RefsModal from "@/components/RefsModal";

export default {
  name: "NeurologistsPerspectivePodcast",
  components: {
    PodcastPage,
    RefsModal,
  },
  data() {
    return {
      id: this.$router.currentRoute.params.id,
      audio: {
        id: "management-audio2",
        title:
          "Empowering Patients With MS: The Neurologist’s Perspective Podcast",
        src: "assets/audio/empowering_patients_with_MS.mp3",
        analyticsLabel:
          "Empowering Patients With MS: The Neurologist Perspective",
        desc: "Dr Jason Freeman, neurologist (Medical Director, Novartis), and Dr Yousuf Ali (Senior Medical Director, Novartis) engage in a conversation about the benefits of patient engagement and empowerment in the management of patients with MS.",
      },
    };
  },
  methods: {
    showPdf: function (data) {
      this.$router.push({ name: data.src.split(".")[0] });

      this.$gtag.event("click", {
        event_category: "view_pdf",
        event_label: `${data.analyticsLabel}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.podcast-page {
  color: $black;
  background: rgb(255, 255, 255);
  position: relative;
}
.mesh {
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    pointer-events: none;
    background-image: url("~@/assets/img/video-bg.png");
    top: 0;
    right: 0;
  }
}

.tactic-btn {
  border: 1px solid $white;
  background: none;
  color: $white;
  padding: rem(10px) rem(25px);
  text-align: left;
  position: relative;
  width: 100%;
  min-height: rem(60px);
  display: block;
  margin-bottom: rem(20px);

  &:last-child {
    margin-bottom: 0px;
  }
}

.tactic-btn-label {
  float: left;
  font-size: rem(18px);
  font-weight: $font-weight-bold;
  width: calc(100% - 50px);
  position: absolute;
  top: 50%;
  left: rem(20px);
  transform: translate(0%, -50%);
}

.tactic-btn-icon {
  float: left;
  width: rem(20px);
  margin-left: rem(30px);
  position: absolute;
  top: 50%;
  right: rem(20px);
  transform: translate(0%, -50%);

  img {
    width: 100%;
  }
  &.launch-icon {
    width: rem(27px);
  }
}

:deep(.audio-title) {
  display: none;
  display: none !important;
}
</style>
