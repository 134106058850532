<template>
  <div id="hcp-selection" class="hcp-selection">
    <div class="selection-container">
      <p>
        To help us better understand your clinical needs, please consider
        telling us about your clinical training here:
      </p>
      <div class="select-form">
        <select v-model="selected" class="hcp-select" id="hcp-type" aria-label="Select HCP Type">
          <option disabled value="">HCP Type (Optional)</option>
          <option>MS Specialist</option>
          <option>Neurologist</option>
          <option>Nurse Practitioner</option>
          <option>Physician Assistant</option>
          <option>Primary Care Physician</option>
          <option>Allied Health Professional</option>
          <option>Other</option>
          <option>Prefer not to answer</option>
        </select>
        <button
          @click="submitHCP()"
          :class="[
            submitted === false ? 'not-submitted' : 'submitted',
            selected !== '' ? 'active' : '',
          ]"
          :disabled="selected === ''"
        >
          <span v-if="!submitted">Submit</span>
          <span v-if="submitted">
            Submitted
            <img src="@/assets/img/icon-checkmark.svg" alt="" />
          </span>
        </button>
      </div>
    </div>
    <p v-if="submitted" class="thank-msg">
      Thanks for sharing. Your answer will help inform future updates to the
      site.
    </p>
  </div>
</template>

<script>
export default {
  name: "HCPSelection",
  data() {
    return {
      selected: "",
      submitted: false,
    };
  },
  methods: {
    submitHCP: function () {
      if (this.selected !== "") {
        this.$gtag.event("click", {
          event_category: "health care type",
          event_label: this.selected,
        });
        this.submitted = true;
        setTimeout(() => {
          this.submitted = false;
          this.selected = "";
        }, 4000);
        console.log(this.selected);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hcp-selection {
  font-size: rem(18px);
  font-weight: $font-weight-bold;

  display: flex;
  flex-direction: column;

  @include breakpoint(xs) {
    font-size: rem(16px);
    line-height: rem(22px);
  }

  @include breakpoint(md) {
    font-size: rem(16px);
    line-height: rem(22px);
  }

  .selection-container {
    display: flex;
    justify-content: center;

    @include breakpoint(xs) {
      flex-direction: column;
    }

    p {
      flex: 1;
      @include breakpoint(xs) {
        margin-bottom: 10px;
      }
    }
  }

  .select-form {
    flex: 1;
    display: flex;
    .hcp-select {
      flex: 2;
      background-color: transparent;
      border: 1px solid $black;
      border-radius: 0;
      padding: 10px 20px;
      margin-right: 10px;
      width: 59%;
      position: relative;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url("~@/assets/img/select-down-arrow.svg") right center
        no-repeat;
      background-size: 5%;
      background-position: 95% 50%;

      @include breakpoint(md) {
        font-size: rem(14px);
        line-height: rem(18px);
      }
    }
    select::-ms-expand {
      display: none;
      background-size: 5%;
      // margin-right: 25px;
    }
  }
  .thank-msg {
    display: block;
    margin-top: 20px;
    text-align: center;
  }
  option {
    width: 59%;
  }

  button {
    flex: 1;
    background-color: transparent;
    border: 1px solid $grey-dark;
    border-radius: 0;
    padding: 10px 0;
    width: 35%;

    @include breakpoint(xs) {
      height: 41px;
    }
    &.active {
      border: 1px solid $black;
      color: $black;
      &:hover {
        background-color: $black;
        color: $white;
      }
    }
    &.submitted {
      background-color: $black;
      color: $white;
      img {
        margin-left: rem(5px);
        width: rem(15px);
      }
    }
  }
}

@media (max-width: 1024px) {
  .hcp-selection {
    width: 100%;
    .hcp-select {
      width: 100%;
      margin-bottom: rem(10px);
    }
    button {
      width: 100%;
    }
  }
}
</style>
