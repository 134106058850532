<template>
  <InnerContent>
    <header>
      <div class="title-page-row">
        <div class="title-page">
          <h1><slot name="header">Tactic title</slot></h1>
        </div>
      </div>
      <div class="back-button-row">
        <slot name="back-button">
          <ButtonReturn :to="backNav" colour="black" />
        </slot>
      </div>
    </header>

    <div class="pdf-container">
      <div class="buttons">
        <div class="download-button">
          <a
            @click="
              $gtag.event('click', {
                event_category: 'download_pdf',
                event_label: `${pdfName}`,
              })
            "
            :href="`/pdf/${pdfName}.pdf`"
            class="download-btn"
            download
            >{{ buttonTxt }}</a
          >
        </div>
        <div class="controls">
          <span v-if="showAllPages">
            <!-- {{ pageCount }} page(s) -->
          </span>
          <span v-else>
            <button :disabled="page <= 1" @click="page--">❮</button>
            Page {{ page }} of {{ pageCount }}
            <button :disabled="page >= pageCount" @click="page++">❯</button>
          </span>
        </div>
      </div>

      <vue-pdf-embed
        ref="pdfRef"
        :source="`/pdf/${pdfName}.pdf`"
        :page="page"
        @rendered="handleDocumentRender"
      />
    </div>
  </InnerContent>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  name: "PdfViewer",
  components: {
    VuePdfEmbed,
  },
  data() {
    return {
      source: "./pdf/immunoglobulin.pdf",
      isLoading: true,

      // PAGINATED OPTION
      // page: 1,
      // showAllPages: false,

      // FULL PAGE OPTION
      page: null,
      showAllPages: true,

      pageCount: 1,
      pdfSource: "./pdf/immunoglobulin.pdf",
    };
  },
  props: {
    pdfName: {
      type: String,
      default: "",
    },
    backNav: {
      type: String,
      default: null,
    },
    buttonTxt: {
      type: String,
      default: "Download PDF",
    },
  },
  watch: {
    showAllPages() {
      this.page = this.showAllPages ? null : 1;
    },
  },
  methods: {
    handleDocumentRender() {
      this.isLoading = false;
      this.pageCount = this.$refs.pdfRef.pageCount;
    },
  },
};
</script>

<style lang="scss" scoped>
.pdf-container {
  width: 100%;
  height: auto;
  margin: 0 auto;
  text-align: center;
  position: relative;
}
header {
  // margin: 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include breakpoint(xs) {
    align-items: flex-end;
    flex-direction: column-reverse;
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid $grey-dark;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: $grey-light;
  @include breakpoint(xs) {
    flex-direction: column;
  }
}

.controls {
  font-weight: $font-weight-bold;

  button {
    background-color: #fff;
    padding: 10px 15px;
    margin: 0 15px;
    &:last-child {
      margin-right: 0;
    }

    @include breakpoint(xs) {
      &:first-child {
        margin-left: 0;
      }
    }
    border: 1px solid #000;
  }
}

.download-btn {
  border: 1px solid $black;
  background: #fff url("~@/assets/img/icon-download-black.svg") no-repeat 95%
    50%;
  color: $black;
  padding: rem(10px);
  padding-right: 50px;
  text-align: left;
  font-weight: $font-weight-bold;
  position: relative;
  min-width: 300px;
  height: 68px;
  text-decoration: none;
  display: flex;
  align-items: center;

  @include breakpoint(xs) {
    min-height: 52px;
    height: auto;
    margin-bottom: 10px;
  }
}
</style>
