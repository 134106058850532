<template>
  <div :id="`${id}`" class="podcast-page mesh">
    <div class="inner-wrapper">
      <PodcastPage>
        <template #header>{{ audio.title }}</template>
        <template #podcast-asset><img src="~@/assets/img/maximizing-adherence-podcast.png" alt="podcast-icon"/></template>
        <template #podcast-copy>{{ audio.desc }}</template>
        <template #podcast-content>
          <AudioPlayer :data="audio" :key="audio.id" />
        </template>

        <template #transcript>
          <p><strong>Jason Freeman</strong></p>
          <p>
            This podcast is sponsored by Novartis Pharmaceuticals. The
            information herein is provided for disease educational purposes
            only, and is not intended to be, nor does it imply, medical or
            diagnostic advice.
          </p>
          <p>
            Hello, and welcome to our podcast. My name is Dr Jason Freeman, a
            neurologist and medical director with Novartis. Joining us today is
            Dr Stephen Yeung, an MS specialist and also a medical director with
            Novartis Pharmaceuticals. For today's topic, we're going to discuss
            adherence to therapy in multiple sclerosis.
          </p>
          <p>
            Many patients with relapsing-remitting MS, especially in the early
            stages of the disease, may have either near-complete or almost full
            recovery from these relapses.<sup>1</sup> In clinic, we see patients
            who can go months or even years without a relapse occurring or
            seeing new evidence of disease on their repeat MRIs. So, this
            pattern of disease activity, where there is seemingly little or no
            clear clinical evidence of disease progression, can make it
            difficult for health care providers to begin discussions with
            patients about both medication initiation, but also the importance
            of adherence to the therapies that have been selected.
          </p>
          <p>
            So, Dr Yeung, what is the current thought on treatment initiation to
            address disease progression in patients with MS where there's little
            disability or impact on functioning?
          </p>
          <p><strong>Stephen Yeung</strong></p>
          <p>
            So, thanks, Dr Freeman. I think that before I answer that, the first
            thing to keep in mind is that when you're talking about multiple
            sclerosis, you know, there's the obvious symptoms that are either
            easy to observe and measure, like mobility, gait issues, but there
            still remains some of these more difficult, challenging symptoms and
            problems that are hard to even recognize, let alone evaluate, like
            fatigue or cognitive changes.<sup>2,3</sup> So, some of these
            changes that occur, patients don't notice or report it. So, it's
            present when a patient experiences their first relapse.
          </p>
          <p>
            So, you know, that being said, I think it's absolutely important to
            keep in mind that when you're talking about treatment discussion or
            treatment initiation, it's preventing or addressing this potential
            for building up disability or impacting your reserves that we're
            trying to preserve or help address by really addressing inflammatory
            disease from onset of your diagnoses.<sup>1–3</sup>
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            Got it. So, it sounds like early neuroinflammation is occurring even
            at the time of diagnosis and that may lead to some of these more
            silent features like fatigue or cognitive changes. And so, even more
            important that, even though you may not feel bad in terms of
            weakness or difficulty walking, we still need to have a conversation
            about medications. Is that right?
          </p>
          <p><strong>Stephen Yeung</strong></p>
          <p>
            Absolutely. So, I think that it's, this is really the push in recent
            years to emphasize the importance of diagnosing early and treating
            early.
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            So, Dr Yeung, what are some of the potential barriers to treatment
            adherence in patients with MS?
          </p>
          <p><strong>Stephen Yeung</strong></p>
          <p>
            So, there's a great list of different reasons why treatment
            adherence remains a huge problem for MS patients. And it's
            interesting to note that adherence levels are often cited to be
            below 80%.<sup>4,5</sup> So, in my practical experience, I think
            what I see that certainly is something that I believe we can really
            change is a patient's understanding of what it is to have MS. So, I
            think when patients are not appropriately given the time to be
            educated or coached by their physician or provider what it means to
            have MS, what the disease is, the gravity or nature of the disease,
            what can potentially happen without treatment or appropriate
            treatment, and the repercussions or the seriousness of this, if
            that’s not established with a patient from the onset, then I think
            the patient doesn't really buy into this disease and kind of then
            make that effort from day 1 to adhere to the discussed or agreed
            upon treatment strategy.<sup>5–8</sup>
          </p>
          <p>
            Other than that, I think that obvious the typical socioeconomic
            challenges remains where patients have difficulty accessing medical
            facilities or opportunities.<sup>2</sup> And then certainly
            demographics, you know, the kind of cultural backgrounds of
            different patients require different kinds of methods of approaching
            how this disease or discussion is given.<sup>2,9</sup>
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>What were the factors that you saw most in your own clinic?</p>
          <p><strong>Stephen Yeung</strong></p>
          <p>
            I previously was practicing in Southern California, and Southern
            California is not lacking in MS treatment options, meaning
            practices. But I think that the common theme was really time able to
            be spent with that patient, establish a relationship of trust. And
            certainly, if there's no relationship between the patient and their
            treating physician, you really miss that opportunity to connect with
            your patient, because this is, as of now, still an incurable
            disease.
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            So, that nicely leads into our next question because it sounds like
            a lot of focus there on your relationship with your patients. So how
            can health care providers engage patients to ensure that they are
            more likely to adhere to their treatment plans?
          </p>
          <p><strong>Stephen Yeung</strong></p>
          <p>
            So, I think that once that relationship of trust is established, I
            think once a patient really understands what it means to have this
            diagnosis, the potential for what is at risk with poor adherence or
            nontreatment, then you really can start into getting into what we
            are, all are calling shared decision-making, patient shared
            decision-making. And I think that's, if you really take that concept
            of shared patient decision making at heart, what it really is, is
            involving discussing what different treatment options mean, well,
            what it means to the patient, how it will affect their lifestyle in
            the day-to-day. So, what it means to, for their disease, but also
            depending on route of administration.<sup>6,8–10</sup>
          </p>
          <p>
            So, I think that if a patient is explained all of this, then kind of
            they can process it and then choose a therapy that really, they
            think would fit their own lifestyle schedule, work schedule, and
            family life planning. A lot of decisions are often made with a
            superficial understanding of what each treatment means, whether it
            be a pill, or an IV, or an injection. But I think it's not fully
            elucidated to the patient that the processes surrounding that, or
            what potential side effects or complications may entail from each
            one.
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            So, are there ways that health care providers can really assess
            treatment adherence in patients with MS? What are the questions we
            need to ask, or the key things we need to be thinking about as we
            try and understand whether patients are taking their medications or
            not?
          </p>
          <p><strong>Stephen Yeung</strong></p>
          <p>
            I think that's a great question. I think as good as the newer
            medications or recent developments and advancements that the field
            has been making for multiple sclerosis patients, there's still no
            cure. So, confirming treatment adherence is certainly an ongoing
            thing or activity that should be done in various ways at follow-up
            appointments.<sup>6,9</sup>
          </p>
          <p>
            So, you know, one way of doing that without just putting your
            patient point blank on the spot, just ask your patient. I think it's
            patients, if they have that trust in you, they will tell you what
            the issues are at hand, that are, they're being challenged with or
            kind of preventing them from remaining adherent.<sup>8,10</sup> I
            think it's important to have that open dialogue with your patient
            and understand what your patient is going through.
          </p>
          <p>
            Aside from that, I mean, obviously doing the routine imaging,
            follow-up, your physical exam remains certainly vastly important to
            looking for the more obvious evidence of physical progression. But
            recognizing everyone's preferences and lifestyles, it's nice to keep
            that at heart when you're asking your patients about difficulties
            with adherence.<sup>9</sup>
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            And finally, what are some of the potential benefits of patient
            adherence to other stakeholders within the multidisciplinary health
            care team or the health care system?
          </p>
          <p><strong>Stephen Yeung</strong></p>
          <p>
            So continued vigilance of patient adherence to prescribed therapy
            benefits the entire interdisciplinary approach that that patient,
            their health care team, and potentially friends and family and
            caregivers will have. I think when everybody is working together and
            you will see a greater degree of involvement by the patient, and
            then they start asking better questions, they get better interested
            in the disease. And then you see, ultimately, better adherence
            rates.<sup>7–9</sup>
          </p>
          <p><strong>Jason Freeman</strong></p>
          <p>
            Well, Stephen, Dr Yeung, thanks for your time today. We appreciate
            your wisdom and knowledge, especially with respect to MS care and
            how to relate to those patients out there with MS with ideas about
            adherence and early treatment initiation. I think you've provided
            some really helpful information.
          </p>
          <p>
            I want to take the time to say thank you everyone for listening to
            this podcast on improving adherence in patients with MS. I hope
            you'll take the time to explore the other resources on our website,
            MSHCPeducation.com, for health care providers who manage patients
            with MS.
          </p>

          <p class="date-job-code">
            <span class="date">2/23</span> <span class="job-code">254681</span>
          </p>
        </template>
      </PodcastPage>

      <RefsModal v-if="$store.state.showModal === 'references'">
        <template #refs-list>
          <ol class="refs-list">
            <li>
              Dobson R, Giovannoni G. Multiple sclerosis – a review.
              <em>Eur J Neurol</em>. 2019;26(1):27-40. doi:10.1111/ene.13819
            </li>
            <li>
              Higuera L, Carlin CS, Anderson S. Adherence to disease-modifying
              therapies for multiple sclerosis.
              <em>J Manag Care Spec Pharm</em>. 2016;22(12):1394-1401.
              doi:10.18553/jmcp.2016.22.12.1394
            </li>
            <li>
              Giovannoni G, Butzkueven H, Dhib-Jalbut S, et al. Brain health:
              time matters in multiple sclerosis.
              <em>Mult Scler Relat Disord</em>. 2016;9:S5-S48.
              doi:10.1016/j.msard.2016.07.003
            </li>
            <li>
              Setayeshgar S, Kingwell E, Zhu F, et al. Persistence and adherence
              to the new oral disease-modifying therapies for multiple
              sclerosis: a population-based study.
              <em>Mult Scler Relat Disord</em>. 2019;27:364-369.
              doi:10.1016/j.msard.2018.11.004
            </li>
            <li>
              Neter E, Glass-Marmor L, Wolkowitz A, Lavi I, Miller A. Beliefs
              about medication as predictors of medication adherence in a
              prospective cohort study among persons with multiple sclerosis.
              <em>BMC Neurol</em>. 2021;21(1):1-9.
              doi:10.1186/s12883-021-02149-0
            </li>
            <li>
              Tintoré M, Alexander M, Costello K, et al. The state of multiple
              sclerosis: current insight into the patient/health care provider
              relationship, treatment challenges, and satisfaction.
              <em>Patient Prefer Adherence</em>. 2017;11:33-45.
              doi:10.2147/PPA.S115090
            </li>
            <li>
              Rieckmann P, Boyko A, Centonze D, et al. Achieving patient
              engagement in multiple sclerosis: a perspective from the multiple
              sclerosis in the 21st Century Steering Group.
              <em>Mult Scler Relat Disord</em>. 2015;4(3):202-218.
              doi:10.1016/j.msard.2015.02.005
            </li>
            <li>
              Yeandle D, Rieckmann P, Giovannoni G, Alexandri N, Langdon D.
              Patient power revolution in multiple sclerosis: navigating the new
              frontier. <em>Neurol Ther</em>. 2018;7(2):179-187.
              doi:10.1007/s40120-018-0118-8
            </li>
            <li>
              Ben-Zacharia A, Adamson M, Boyd A, et al. Impact of shared
              decision making on disease-modifying drug adherence in multiple
              sclerosis. <em>Int J MS Care</em>. 2018;20(6):287-297.
              doi:10.7224/1537-2073.2017-070
            </li>
            <li>
              Costello K, Kennedy P, Scanzillo J. Recognizing nonadherence in
              patients with multiple sclerosis and maintaining treatment
              adherence in the long term. <em>Medscape J Med</em>.
              2008;10(9):225.
            </li>
          </ol>
        </template>
      </RefsModal>
    </div>
  </div>
</template>

<script>
import PodcastPage from "@/components/PodcastPage";
import RefsModal from "@/components/RefsModal";

export default {
  name: "MaximizingAdherencePodcast",
  components: {
    PodcastPage,
    RefsModal,
  },
  data() {
    return {
      id: this.$router.currentRoute.params.id,
      audio: {
        id: "management-audio3",
        title:
          "Maximizing Adherence to Treatment Plans: Balancing Safety, Efficacy, and Patient Priorities Podcast",
        src: "assets/audio/patient_adherence.mp3",
        analyticsLabel:
          "Maximizing Adherence to Treatment Plans: Balancing Safety, Efficacy, and Patient Priorities",
        desc: "Dr Jason Freeman, neurologist (Medical Director, Novartis), and Dr Stephen Yeung, MS specialist (Medical Director, Novartis), talk about the potential barriers to treatment adherence in patients with MS and the benefits of shared decision-making.",
      },
    };
  },
  methods: {
    showPdf: function (data) {
      this.$router.push({ name: data.src.split(".")[0] });

      this.$gtag.event("click", {
        event_category: "view_pdf",
        event_label: `${data.analyticsLabel}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.podcast-page {
  color: $black;
  background: rgb(255, 255, 255);
  position: relative;
}
.mesh {
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    pointer-events: none;
    background-image: url("~@/assets/img/video-bg.png");
    top: 0;
    right: 0;
  }
}

.tactic-btn {
  border: 1px solid $white;
  background: none;
  color: $white;
  padding: rem(10px) rem(25px);
  text-align: left;
  position: relative;
  width: 100%;
  min-height: rem(60px);
  display: block;
  margin-bottom: rem(20px);

  &:last-child {
    margin-bottom: 0px;
  }
}

.tactic-btn-label {
  float: left;
  font-size: rem(18px);
  font-weight: $font-weight-bold;
  width: calc(100% - 50px);
  position: absolute;
  top: 50%;
  left: rem(20px);
  transform: translate(0%, -50%);
}

.tactic-btn-icon {
  float: left;
  width: rem(20px);
  margin-left: rem(30px);
  position: absolute;
  top: 50%;
  right: rem(20px);
  transform: translate(0%, -50%);

  img {
    width: 100%;
  }
  &.launch-icon {
    width: rem(27px);
  }
}

:deep(.audio-title) {
  display: none;
  display: none !important;
}
</style>
