<template>
  <div class="searchInputComponent">
    <div class="inputContainer">
      <div class="inputForm">
        <label for="search">Search</label>
        <input type="text" id="search" name="search" ref="searchInput" v-model="searchText" />
      </div>
      <div class="searchSubmitIcon" @click="emitSubmitEvent">
        <img src="@/assets/img/172546_search_icon.png" alt="search" />
      </div>
    </div>
    <div v-if="hasDropDown" class="dropDownContainer">
      <div class="searchedContainer" v-if="searchedContent.length > 0">
        <ul class="searchList">
          <ul class="searchList">
            <li
              v-for="(search, index) in searchedContent"
              :key="index"
              @click="toggleSearchResults"
            >
              <a :href="search.route">
                <div class="router">
                  <div class="title">{{ search.title }}</div>
                  <div class="url">
                    https://www.mshcpeducation.com{{ search.link }}
                  </div>
                  <div
                    class="content"
                    v-html="findAndReplace(search.summary)"
                  ></div>
                </div>
              </a>
            </li>
          </ul>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import dataset from "@/data/search-data.json";
export default {
  name: "SearchInputMobile",
  emits: ["onSubmit", "toggleSearch"],
  props: {
    hasDropDown: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  mounted() {
    this.$refs.searchInput.focus();
  },
  data() {
    return {
      searchText: "",
      showSearchedDataList: false,
    };
  },
  methods: {
    emitSubmitEvent() {
      this.$store.searchedTerm = this.searchText;
      this.searchText = "";
      this.$emit("onSubmit");
      console.log(this.$store.searchedTerm);
    },
    findAndReplace(data) {
      const _temp = data;
      const regexSearchText = new RegExp(`${this.searchText}`, "gim");
      const matchedSearchText = _temp.match(regexSearchText);

      for (let i = 0; i <= matchedSearchText.length; i++) {
        return _temp.replace(regexSearchText, `<strong>$&</strong>`);
      }
    },
    toggleSearchResults() {
      this.$emit("toggleSearch");
    },
  },
  computed: {
    searchedContent() {
      let searchedContent = dataset.filter((item) => {
        if (this.searchText != "") {
          return String(item.summary)
            .toLocaleLowerCase()
            .includes(String(this.searchText).toLocaleLowerCase());
        }
      });
      return searchedContent;
    },
  },
};
</script>

<style lang="scss" scoped>
.searchInputComponent {
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  .no-results {
    padding: 20px 0;
  }

  .dropDownContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    max-width: 1024px;
  }

  .searchedContainer {
    position: absolute;
    // top: 295px;
    width: calc(100% - 38px);
    height: 100vh;
    // max-height: calc(100vh - 420px);
    background-color: #fff;
    overflow: auto;
    border: 0.0625rem solid rgba(157, 157, 156, 0.25);

    .searchList {
      margin: 0;
      padding: 0;

      .router {
        cursor: pointer;
      }
    }

    .searchList > li {
      list-style: none;
      width: 100%;
      padding: 25px;
      border-bottom: 0.0625rem solid #9d9d9c;

      .title {
        font-size: 2rem;
        line-height: 2rem;
        margin: 5px 0;
        color: #000;
        font-weight: bold;
      }

      .content {
        font-size: 1rem;
        line-height: 1.25rem;
        margin: 5px 0;
      }
    }

    .searchList .url {
      margin-bottom: 15px;
    }

    .searchList .url > a {
      font-size: 0.85rem;
    }
  }

  .inputContainer {
    display: flex;
    position: relative;
    align-items: center;
    background-color: $white;
    border: 1px solid #000;
    width: 100%;

    label[for="search"] {
      visibility: hidden;
      height: 0;
      display: block;
    }
    .inputForm {
      flex: 5;
    }

    .searchSubmitIcon {
      flex: 1;
      width: 50px;
      height: 50px;
      position: relative;
      cursor: pointer;
      background-color: $white;
      img {
        width: 50%;
        height: auto;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    input {
      width: 100%;
      height: 3.125rem;
      border: none;
      font-size: 1rem;
      padding: 0 4.375rem 0 1.25rem;
      color: #221f1f;
      background-color: $white;
      box-sizing: border-box;

      &:focus {
        border: none;
        outline: none;
      }
    }
  }

  .highlight {
    font-weight: bold;
  }
}
</style>
