<template>
  <div id="home">
    <div class="inner-wrapper">
      <main>
        <div class="header-container">
          <header class="inner-content">
            <div class="intro">
              <div class="intro-copy">
                <h1 class="app-title">Mechanism of Disease of&nbsp;MS</h1>
                <p>
                  <strong>Mechanism of Disease of MS</strong> addresses how MS
                  affects the brain, from early neuroinflammation through to
                  progressive neurodegeneration, in patients with RRMS.
                </p>
              </div>
              <div class="intro-img">
                <img src="@/assets/img/mod-header.png" alt="3D graphics of a collection of immune cells" />
              </div>
            </div>
          </header>
        </div>
        <div class="tactic-cards-container cf">
          <!-- MS MOD -->
          <article class="background-color-white" id="bTCell">
            <div class="tactic-card inner-content">
              <div class="card-image-container">
                <figure class="card-image">
                  <img src="~@/assets/img/mod-b-t-cell.png" alt="Animation still of autoreactive B cell coming out of a blood vessel in the brain." />
                </figure>
              </div>
              <div class="card-info">
                <div class="card-copy">
                  <h2 class="card-sub-title">B- and T-Cell Activity</h2>
                  <p class="card-desc">
                    Understand how autoreactive B- and T-cell activity drives
                    early neuroinflammation and axonal damage in MS.
                  </p>
                </div>
                <ul class="external-links">
                  <li>
                    <router-link to="/mechanism-of-disease/video">
                      <img
                        src="@/assets/img/icon-video-black.svg"
                        alt="Video"
                      />
                      Mechanism of Disease in MS (2:10)
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      to="/mechanism-of-disease/b-t-cell-pathophysiology/video"
                    >
                      <img
                        src="@/assets/img/icon-video-black.svg"
                        alt="Video"
                      />
                      B- and T-Cell Pathophysiology in MS (2:57)
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </article>
          <article class="background-color-grey" id="neuro">
            <div class="tactic-card inner-content row-reverse">
              <div class="card-image-container">
                <figure class="card-image">
                  <img src="~@/assets/img/mod-neuroinflammation.png" alt="A stylized illustration of neurons connecting" />
                </figure>
              </div>
              <div class="card-info">
                <div class="card-copy">
                  <h2 class="card-sub-title">
                    Neuroinflammation and Neurodegeneration
                  </h2>
                  <p class="card-desc">
                    Learn how reparative processes that attempt to compensate
                    for early inflammatory activity in MS may be overwhelmed by
                    irreversible neuroaxonal degeneration and demyelination.
                  </p>
                </div>
                <ul class="external-links">
                  <li>
                    <router-link
                      to="/mechanism-of-disease/neuroinflammation-neurodegeneration/video"
                    >
                      <img
                        src="@/assets/img/icon-video-black.svg"
                        alt="Video"
                      />
                      From Neuroinflammation to Neurodegeneration in MS (6:51)
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </article>
          <article class="background-color-white" id="immunoglobulins">
            <div class="tactic-card inner-content">
              <div class="card-image-container">
                <figure class="card-image">
                  <img src="~@/assets/img/mod-immunoglobulins.png" alt="3D graphics of immunoglobulins IgA, IgM, IgG, IgE, and IgD." />
                </figure>
              </div>
              <div class="card-info">
                <div class="card-copy">
                  <h2 class="card-sub-title">Immunoglobulins</h2>
                  <p class="card-desc">
                    Improve your understanding of the immune system and
                    immunoglobulin activity in patients with MS.
                  </p>
                </div>
                <ul class="external-links">
                  <li>
                    <router-link
                      to="/mechanism-of-disease/immunoglobulins-and-ms/video"
                    >
                      <img
                        src="@/assets/img/icon-video-black.svg"
                        alt="Video"
                      />
                      Immunoglobulins and MS (2:36)
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      to="/mechanism-of-disease/neuroimmunology-considerations-in-ms/video"
                    >
                      <img
                        src="@/assets/img/icon-video-black.svg"
                        alt="Video"
                      />
                      Neuroimmunology Considerations in MS (13:10)
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </article>
          <article class="resources">
            <div class="tactic-card inner-content">
              <div class="card-info">
                <div class="card-copy">
                  <h2>Resources</h2>
                  <p>
                    Explore additional
                    <strong>Mechanism of Disease</strong> resources.
                  </p>
                </div>

                <a
                  href="/additional-resources#mod"
                  @click="launchTactic(cards.resources)"
                  class="card-btn cf white"
                >
                  <div class="card-btn-content">
                    <span class="card-btn-label">Explore resources</span>
                    <span class="card-btn-icon">
                      <img src="@/assets/img/ButtonArrow.svg" alt="" />
                    </span>
                  </div>
                </a>
                <p class="acr">
                  MS, multiple sclerosis; RRMS, relapsing-remitting MS.
                </p>
              </div>
            </div>
          </article>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeNeuropathology",
  inject: ["highlightTabSelected"],
  data() {
    return {
      scrolled: false,
      pdfs: {
        neuro: [
          {
            src: "neuroinflammation.pdf",
            analyticsLabel: "Home: Neuroinflammation",
          },
          {
            src: "pathogenic-b-t-cells.pdf",
            analyticsLabel: "Home: Pathogenic B Cells and T Cells",
          },
        ],
      },
      cards: {
        neuropathology: {
          title: "B and T cells",
          route: "ms-mod",
          urlDev: "/mechanism-of-disease/video",
          urlProd: "/mechanism-of-disease/video",
          analyticsLabel: "Home: B and T cells",
        },
        resources: {
          title: "Additional Resources",
          route: "additional-resources",
          urlDev: "/additional-resources",
          urlProd: "/additional-resources",
          analyticsLabel: "Additional Resources",
        },
      },
      home: {
        diagnosingms: {
          analyticsLabel: "Home: Diagnosing MS",
        },
      },
      cardScroll: "",
    };
  },

  methods: {
    launchTactic: function (tactic) {
      this.$gtag.event("click", {
        event_category: "launch_page",
        event_label: tactic.analyticsLabel,
      });

      let url =
        process.env.NODE_ENV === "development" ? tactic.urlDev : tactic.urlProd;

      if (tactic.route) {
        this.$router.push({ name: tactic.route });
      } else {
        window.open(url, "_self");
      }
    },
    downloadPdf: function (tactic) {
      this.$gtag.event("click", {
        event_category: "download_pdf",
        event_label: tactic.analyticsLabel,
      });
    },
    showPdf: function (data) {
      this.$router.push({ name: data.src.split(".")[0] });

      this.$gtag.event("click", {
        event_category: "view_pdf",
        event_label: `${data.analyticsLabel}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#home {
  width: 100%;
  position: relative;
  .inner-content {
    margin: 0 auto;
  }
  main {
    width: 100%;
  }
}
.header-container {
  background-color: $blue;
  background-image: url("~@/assets/img/mod-header-bg.png");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: cover;
  height: 545px;

  @include breakpoint(xs) {
    height: max-content;
  }

  .inner-content {
    display: flex;
    justify-content: center;
  }
}

header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  margin-bottom: 0;

  @include breakpoint(xs) {
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      #035da6 33%,
      #035da6 66%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .intro {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 545px;

    @include breakpoint(xs) {
      flex-direction: column;
      flex-direction: column-reverse;
      height: 100%;
    }

    .intro-copy {
      flex: 1;

      p {
        color: $white;
        font-size: rem(18px);
        line-height: rem(22px);

        @include breakpoint(xs) {
          font-size: rem(16px);
          line-height: rem(22px);
          margin-bottom: 20px;
        }
      }

      ul {
        padding-left: 15px;
        ::marker {
          font-size: 20px;
        }
        li {
          margin-bottom: 10px;
          list-style-type: square;

          a {
            font-weight: $font-weight-bold;
            color: $blue;
          }
        }
      }
    }

    .intro-img {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      @include breakpoint(xs) {
        order: 1;
      }

      img {
        height: 100%;
        @include breakpoint(xs) {
          height: auto;
          width: 100%;
        }
      }
    }
  }

  @include breakpoint(xs) {
    margin-bottom: 20px;
  }
  // @media (min-width: 1680px) {
  //   max-width: 1400px !important;
  // }
}

.app-title {
  font-size: rem(62px);
  line-height: rem(64px);
  font-weight: $font-weight-bold;
  color: $white;
  text-align: left;
  margin: 0 0 rem(20px) 0;

  @include breakpoint(xs) {
    font-size: rem(24px);
    line-height: rem(24px);
    margin: rem(20px) 0;
  }
}
.inner-wrapper {
  background-color: $white;
  position: relative;
}

.tactic-cards-container {
  margin: 0;
  width: 100%;
}

.tactic-card {
  display: flex;
  flex-direction: row;
  column-gap: 40px;
  width: 100%;
  color: $white;
  align-items: center;
  justify-content: center;
  padding: 50px 25px;

  @include breakpoint(xs) {
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

.card-image-container {
  margin: 0 auto;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex: 5;
}

.card-image {
  height: auto;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }
}

.card-copy {
  .card-sub-title {
    color: $blue-card1;
    font-size: rem(28px);
    font-weight: $font-weight-bold;
  }
}
.card-info {
  display: flex;
  flex: 7;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding-right: 40px;
  height: 60%;
  position: relative;
  a {
    color: $black;
    &:hover {
      text-decoration: none;
    }
  }
  @include breakpoint(xs) {
    margin: 0;
    padding: 20px;
  }
}
.card-desc {
  margin: rem(20px) 0;
  font-size: rem(18px);
  line-height: rem(24px);
  color: $black;

  @include breakpoint(xs) {
    font-size: rem(16px);
    line-height: rem(22px);
    margin: 16px 0;
  }
}

.card-btn {
  border: 1px solid $black;
  background: none;
  color: $black;
  padding: rem(10px);
  text-align: left;
  position: relative;
  display: inline;
  max-width: 450px;
  height: 52px;

  &.white {
    border: 1px solid $white;
    color: $white;
  }

  @include breakpoint(xs) {
    min-height: 52px;
    height: auto;
  }
}

.card-btn-content {
  min-height: 24px;

  @include breakpoint(xs) {
    min-height: 50px;
  }
}

.card-btn-label {
  float: left;
  font-size: rem(22px);
  font-weight: $font-weight-bold;
  // width: calc(100% - 50px);
  position: absolute;
  top: 50%;
  left: rem(10px);
  transform: translate(0%, -50%);

  @include breakpoint(md) {
    font-size: rem(20px);
    line-height: rem(22px);
  }

  @include breakpoint(xs) {
    font-size: rem(20px);
    line-height: rem(22px);
    margin-right: 20px;
  }
}

.card-btn-icon {
  float: left;
  width: rem(20px);
  margin-left: rem(10px);
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.external-links {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 10px;
  }

  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: rem(10px);
    font-size: rem(20px);
    line-height: rem(24px);
    min-height: 30px;
    text-align: left;
    cursor: pointer;
    color: $black;
    img {
      width: 22px;
      height: 27px;
      margin-right: 20px;
    }

    &:hover {
      text-decoration: none;
    }

    @include breakpoint(md) {
      font-size: rem(16px);
      line-height: rem(20px);
    }

    @include breakpoint(xs) {
      font-size: rem(14px);
      line-height: rem(18px);
      font-weight: $font-weight-med;
    }

    span {
      font-size: rem(14px);
      line-height: rem(16px);
      display: block;
      width: 100%;
      text-decoration: none;

      @include breakpoint(xs) {
        font-weight: $font-weight-reg;
      }
    }

    &.audio {
      display: flex;
      align-items: flex-start;
      text-decoration: none;
      cursor: auto;

      .audio-player {
        width: 382px;

        @include breakpoint(md) {
          width: 100%;
        }

        @include breakpoint(xs) {
          width: 100%;
        }
      }
    }
  }

  a {
    margin-bottom: 0;
  }
}

// specific bg style for each card
article {
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;

  @include breakpoint(xs) {
    margin: 40px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.resources {
  color: $white;
  background-color: $blue-card1;
  flex-direction: row;
  padding: 20px 0;
  h3 {
    font-size: rem(28px);

    @include breakpoint(xs) {
      font-size: rem(18px);
    }

    @include breakpoint(md) {
      font-size: rem(24px);
    }
  }

  .tactic-card {
    align-items: flex-start;
    min-height: auto;

    .card-info {
      text-align: left;
      p {
        font-size: rem(24px);
        line-height: rem(32px);

        @include breakpoint(xs) {
          font-size: rem(20px);
          line-height: rem(26px);
        }

        @include breakpoint(md) {
          font-size: rem(16px);
          line-height: rem(22px);
        }
      }

      .card-btn {
        margin-bottom: 18px;

        font-size: rem(22px);
        line-height: rem(26px);

        @include breakpoint(xs) {
          font-size: rem(20px);
          line-height: rem(24px);
          margin-top: 0;
        }

        @include breakpoint(sm) {
          font-size: rem(20px);
          line-height: rem(24px);
          margin-top: 0;
        }

        @include breakpoint(md) {
          font-size: rem(20px);
          line-height: rem(24px);
        }
      }
      p {
        margin: 20px 0;

        &.acr {
          @include breakpoint(xs) {
            margin-top: 20px;
          }
        }
      }
    }
  }
}

.row-reverse {
  flex-direction: row-reverse;
  @include breakpoint(xs) {
    flex-direction: column;
  }
}
.background-color-grey {
  background-color: $grey;
}
.background-color-white {
  background-color: $white;
}
</style>
